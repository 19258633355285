import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useMemo, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import useAsync from "../../../customHooks/useAsync";
import useWindowSize from "../../../customHooks/useWindowSize";
import type { RootState } from "../../../data/types";
import MeetService from "../../../services/meet";
import type { AthleteWithAllResults, AllResults } from "../../../types";
import { LiftSuccess } from "../../../types";
import type { ColumnDef } from "../../common/DisplayGrid";
import DisplayGrid from "../../common/DisplayGrid";

type ShowAllValueProps<T> = {
  result: AllResults;
  index: number;
  field: "BENCHs" | "SQUATs" | "DEADLIFTs";
  colDef: ColumnDef<T>;
};

function ShowAllValue<T>({
  result,
  index,
  field,
  colDef,
}: ShowAllValueProps<T>) {
  if (
    result[field][index]?.liftSuccess === LiftSuccess.FALSE ||
    result[field][index]?.liftSuccess === LiftSuccess.TRUE
  ) {
    return (
      <span
        className={`cellValue adorment ${colDef.isNumber ? "number" : ""}`}
        style={{ width: `${colDef.innerCellWidth}px` }}
      >
        {result[field][index].liftSuccess === LiftSuccess.TRUE ? (
          <CheckCircleOutlineIcon sx={{ color: "green" }} />
        ) : (
          <CancelOutlinedIcon sx={{ color: "red" }} />
        )}
        <span className="innerCellValue">{result[field][index].weight}</span>
      </span>
    );
  }
  return (
    <span
      className={`cellValue adorment ${colDef.isNumber ? "number" : ""}`}
      style={{ width: `${colDef.innerCellWidth}px` }}
    >
      <RemoveCircleOutlineOutlinedIcon sx={{ color: "#4ea0cf" }} />
      <span className="innerCellValue">
        {result[field][index]?.weight ? result[field][index]?.weight : "N/A"}
      </span>
    </span>
  );
}

export default function WeightClass() {
  const { weightClassId } = useParams();
  const [showAll, setShowAll] = useState(false);
  const [polls, setPolls] = useState(0);
  const meet = useSelector((state) => {
    return (state as RootState).meet.activeMeet;
  });

  const { data, isInitial } = useAsync(() => {
    if (!meet) {
      return null;
    }
    return MeetService.getResultsForWeightClass(meet.id, weightClassId);
  }, [weightClassId, polls]);

  useEffect(() => {
    const interval = setInterval(() => {
      setPolls((p) => p + 1);
    }, 5000);
    return () => clearInterval(interval);
  }, [polls]);

  const weightClass = useMemo(() => {
    if (!meet) {
      return null;
    }
    return meet.weightClasses.find((wc) => wc.id === weightClassId);
  }, [meet, weightClassId]);

  function handleClickShowAll() {
    setShowAll(!showAll);
  }

  const windowSize = useWindowSize();

  const windowWidth = windowSize.width || 1000;

  const columns: ColumnDef<AthleteWithAllResults>[] = useMemo(
    () => [
      {
        isNumber: true,
        width: windowWidth > 680 ? 55 : windowWidth / 25,
        label: windowWidth > 680 ? "Place" : "",
        renderer: (d, rowIndex) => {
          return rowIndex + 1;
        },
      },
      {
        width: windowWidth > 680 ? 185 : windowWidth / 5,
        label: "Name",
        renderer: (d) => {
          if (windowWidth > 680) {
            return `${d.lastName}, ${d.firstName}`;
          }
          return `${d.lastName}, ${d.firstName[0]}.`;
        },
      },
      {
        width: windowWidth > 680 ? undefined : windowWidth / 10,
        label: windowWidth > 680 ? "School" : "Schl",
        dataId: "schoolAbrev",
      },
      {
        isNumber: true,
        label: windowWidth > 680 ? "Squat" : "Sqt",
        dataId: "results.SQUAT",
        showAllRenderer: (row, index, colDef) => {
          return (
            <ShowAllValue
              result={row.results}
              index={index}
              field="SQUATs"
              colDef={colDef}
            />
          );
        },
      },
      {
        isNumber: true,
        label: windowWidth > 680 ? "Bench" : "Bch",
        dataId: "results.BENCH",
        showAllRenderer: (row, index, colDef) => {
          return (
            <ShowAllValue
              result={row.results}
              index={index}
              field="BENCHs"
              colDef={colDef}
            />
          );
        },
      },
      {
        isNumber: true,
        label: windowWidth > 680 ? "Deadlift" : "DL",
        dataId: "results.DEADLIFT",
        showAllRenderer: (row, index, colDef) => {
          return (
            <ShowAllValue
              result={row.results}
              index={index}
              field="DEADLIFTs"
              colDef={colDef}
            />
          );
        },
      },
      {
        isNumber: true,
        label: windowWidth > 680 ? "Total" : "Ttl",
        dataId: "results.total",
      },
      {
        isNumber: true,
        label: windowWidth > 680 ? "Coefficient" : "Coef",
        renderer: (d) => {
          return d.results.coefficient.toFixed(3);
        },
      },
    ],
    [windowWidth]
  );

  if (isInitial || !data) {
    return <CircularProgress />;
  }

  data?.sort((a, b) => {
    if (a.results.total > b.results.total) {
      return -1;
    } else if (a.results.total < b.results.total) {
      return 1;
    }

    if (a.results.coefficient > b.results.coefficient) {
      return -1;
    } else if (a.results.coefficient < b.results.coefficient) {
      return 1;
    }

    // TODO: We need to handle if the COEF is that same.  In this case
    // we go by who got to the weight first.
    return 0;
  });

  const attemptsArray = Array(meet?.liftAttempts).fill(0);

  return (
    <>
      <Typography variant="h1">
        {weightClass?.weightClassName} Results
      </Typography>
      <Typography variant="h4">Total Athletes: {data?.length}</Typography>
      <span>
        <Button className="showAllButton" onClick={handleClickShowAll}>
          {showAll ? "Show Only Best Lifts" : "Show All Lifts"}
        </Button>
      </span>
      <DisplayGrid
        data={data}
        columns={columns}
        showAdditionalRowData={showAll}
        addtionalRow={(row: AthleteWithAllResults) => {
          return attemptsArray.map((v, attemptIndex) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={attemptIndex} className="row">
              {columns.map((col) => (
                <div
                  key={col.label}
                  style={{
                    flex: col.width ? `0 0 ${col.width}px` : undefined,
                  }}
                  className="cell"
                >
                  {col.showAllRenderer
                    ? col.showAllRenderer(row, attemptIndex, col)
                    : null}
                </div>
              ))}
            </div>
          ));
        }}
      />
    </>
  );
}
