import Service from ".";
import type {
  Athlete,
  MeetAthlete,
  MutateMeetAthlete,
  School,
  UUID,
} from "../types";
class CoachService extends Service {
  constructor() {
    super({ path: "/php/CoachMain.php" });
  }

  getTeams(): Promise<School[]> {
    return this.get({
      task: "getTeams",
    });
  }

  getTeamAthletes(teamId: UUID): Promise<Athlete[]> {
    return this.get({
      task: "getTeamAthletes",
      teamId: teamId,
    });
  }

  updateAthlete(athlete: Athlete): Promise<Athlete> {
    return this.post({
      task: "updateAthlete",
      athlete: athlete,
    });
  }

  addAthlete(athlete: Athlete): Promise<Athlete> {
    return this.post({
      task: "addAthlete",
      athlete: athlete,
    });
  }

  updateAthletesForMeet(
    meetId: UUID,
    athletes: MutateMeetAthlete[],
    removeAthleteIds: UUID[]
  ): Promise<MeetAthlete[]> {
    return this.post({
      task: "updateAthletesForMeet",
      meetId,
      athletes: athletes,
      removeAthleteIds: removeAthleteIds,
    });
  }

  updateAthleteForMeet(
    meetId: UUID,
    athlete: MutateMeetAthlete
  ): Promise<MeetAthlete[]> {
    return this.post({
      task: "updateAthleteForMeet",
      meetId,
      athlete,
    });
  }

  removeAthleteFromMeet(meetAthleteId: UUID): Promise<MeetAthlete[]> {
    return this.post({
      task: "removeAthleteFromMeet",
      meetAthleteId,
    });
  }
}

export default new CoachService();
