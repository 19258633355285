import { useEffect } from "react";

export default function useWakeLock() {
  useEffect(() => {
    // @ts-ignore
    let wakeLock = null;
    // @ts-ignore
    if (navigator?.wakeLock?.request) {
      // The wake lock sentinel.

      // Function that attempts to request a wake lock.
      const requestWakeLock = async () => {
        try {
          // @ts-ignore
          wakeLock = await navigator.wakeLock.request("screen");
          wakeLock.addEventListener("release", () => {
            console.log("Wake Lock was released");
          });
          console.log("Wake Lock is active");
        } catch (err) {
          console.error(`${err.name}, ${err.message}`);
        }
      };
      requestWakeLock();
    }
    return () => {
      // @ts-ignore
      if (wakeLock?.release) {
        // @ts-ignore
        wakeLock.release();
      }
    };
  }, []);
}
