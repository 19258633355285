import appReducer from "./app";
import meetReducer from "./meet";
import type { RootState } from "./types";
import { createStore } from "./utils";

/**
 * Redux data stores:
 *
 * app: Manages MQ socket connection (connects on Root initialization)
 * carepro: Used for CP profile pages
 * carepros: Used as a cache for CP hovercards
 * dashboard: Stores the module data for the CM dashboard
 * geo: Loads regions and service areas (on app load)
 * invoices: Used for invoices pages (incl. creating tickets from invoices)
 * messages: Used for the Comms UI
 * notifications: Contains the logged-in user's notifications
 * organization: Handles organization loading and updating
 * orientations: Handles CP recruiting orientations loading
 * owner: Used for owner profile pages
 * recipient: Used for recipient profile pages
 * recipients: Used as a cache for recipient hovercards
 * shiftBundleDetails: Used to display a single shift bundle
 * tickets: Handles filtering tickets (by inbox, &c.)
 * ticketDetail: Used to display a single ticket
 * upcomingVisits: Handles displaying and updating checkins page
 */

const store = createStore<RootState>({
  app: appReducer,
  meet: meetReducer,
});

export default store;
