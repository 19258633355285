import type { MeetState } from "./types";
import { MeetActionType } from "./types";
import { createReducer } from "../utils";

const INITIAL_STATE: MeetState = {
  activeMeet: null,
  initialized: false,
};

export default createReducer(INITIAL_STATE, {
  [MeetActionType.INITIALIZE](state, { error, payload }) {
    if (error) {
      return {
        ...state,
      };
    }
    return {
      ...state,
      activeMeet: payload.meet,
      meetPermissions: payload.meetPermissions,
      initialized: true,
    };
  },
  [MeetActionType.DEINITIALIZE](state, { error }) {
    if (error) {
      return {
        ...state,
      };
    }
    return {
      ...state,
      activeMeet: null,
      initialized: false,
    };
  },
  [MeetActionType.LOAD](state, { payload, error }) {
    if (error) {
      return {
        ...state,
        initialLoad: false,
        loadError: payload,
      };
    }
    return {
      ...state,
      activeMeet: payload.meet,
      initialLoad: false,
    };
  },
});
