/* eslint-disable @typescript-eslint/no-unused-vars */
import { Stack, Button, FormControlLabel, Switch } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import * as React from "react";
import { useSelector } from "react-redux";

import useAsync from "../../../customHooks/useAsync";
import type { RootState } from "../../../data/types";
import MeetService from "../../../services/meet";
import { UserTypes } from "../../../types";
import { User } from "../../../types";

export default function MeetUsers() {
  const user = useSelector((state) => {
    return (state as RootState).app.user;
  });
  const [showPasswords, setShowPasswords] = React.useState(false);
  const meet = useSelector((state) => {
    return (state as RootState).meet.activeMeet;
  });
  const {
    data: meetUsers,
    isInitial,
    reload,
  } = useAsync(() => {
    return MeetService.getMeetUsers(meet.id);
  }, [meet.id]);

  async function handleAddUser(userType: UserTypes) {
    MeetService.addMeetUser(meet.id, userType);
    await reload();
  }

  return (
    <Stack gap={1}>
      <FormControlLabel
        onChange={(e) => setShowPasswords(!showPasswords)}
        control={<Switch value={showPasswords} />}
        label="Show Passwords"
      />
      {user?.userType === "ADMIN" ? (
        <Stack direction="row" gap={2}>
          <Button onClick={() => handleAddUser(UserTypes.DIRECTOR)}>
            Add Director User
          </Button>
          <Button onClick={() => handleAddUser(UserTypes.ENTRY)}>
            Add Entry User
          </Button>
          <Button onClick={() => handleAddUser(UserTypes.MC)}>
            Add MC User
          </Button>
        </Stack>
      ) : null}
      <DataGrid
        loading={isInitial}
        autoHeight={true}
        rows={meetUsers || []}
        columns={[
          { field: "username", headerName: "Username", width: 200 },
          {
            field: "password",
            headerName: "Password",
            width: 200,
            valueGetter: (params) =>
              showPasswords ? params.value : "********",
          },
          {
            field: "permission",
            headerName: "Permission",
          },
        ]}
      />
    </Stack>
  );
}
