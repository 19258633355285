import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import MeetDateGate from "./MeetDateGate";
import NextLiftEntryGrid from "./NextLiftEntryGrid";
import useAsync from "../../../customHooks/useAsync";
import type { RootState } from "../../../data/types";
import MeetService from "../../../services/meet";
import CenteredCircularProgress from "../../common/CenteredCircularProgress";

export default function NextLiftEntry() {
  const { meetId } = useSelector((state) => {
    return {
      meetId: (state as RootState).meet.activeMeet.id,
    };
  });
  const [pollsAthletes, setPollsAthletes] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setPollsAthletes((p) => p + 1);
    }, 20000);
    return () => clearInterval(interval);
  }, [pollsAthletes]);

  const { data: athletes, isInitial: isInitialAthleteLoad } = useAsync(() => {
    return MeetService.getAllAthletesAndResults(meetId);
  }, [meetId, pollsAthletes]);

  return (
    <MeetDateGate>
      <Typography variant="h1">Next Lift Entry</Typography>
      {isInitialAthleteLoad ? (
        <CenteredCircularProgress />
      ) : (
        <NextLiftEntryGrid athletes={athletes} />
      )}
    </MeetDateGate>
  );
}
