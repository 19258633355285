import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import DisplayGrid from "./DisplayGrid";
import type { ColumnDef } from "./DisplayGrid";
import useWindowSize from "../../customHooks/useWindowSize";
import type { RootState } from "../../data/types";
import type {
  AthleteOrderWithResults,
  LiftingGroup,
  UUID,
  WeightClass,
} from "../../types";

const MOBILE_WIDTH = 680;
const TABLET_WIDTH = 1000;

type LiftOrderGridProps = {
  liftingGroup: LiftingGroup;
  athleteData: AthleteOrderWithResults[];
  weightClasses: WeightClass[];
  children: React.ReactNode;
  getAthleteLink?: (ath: AthleteOrderWithResults) => string;
};

export default function LiftOrderGrid({
  liftingGroup,
  athleteData,
  weightClasses,
  children,
  getAthleteLink,
}: LiftOrderGridProps) {
  const sortedAthleteData = useMemo(
    () => athleteData.sort((a, b) => a.order - b.order),
    [athleteData]
  );
  const activeMeet = useSelector((state) => {
    return (state as RootState).meet.activeMeet;
  });

  const windowSize = useWindowSize();

  const windowWidth = windowSize.width || 1000;

  const columns: ColumnDef<AthleteOrderWithResults>[] = React.useMemo(() => {
    return [
      ...(activeMeet?.enableFlights
        ? [
            {
              width: windowWidth > MOBILE_WIDTH ? 55 : windowWidth / 25,
              label: windowWidth > MOBILE_WIDTH ? "Flight" : "Flt",
              dataId: "flight",
            },
          ]
        : []),
      {
        width: windowWidth > MOBILE_WIDTH ? undefined : windowWidth / 5,
        label: "Name",
        renderer: (d) => {
          const name =
            windowWidth > MOBILE_WIDTH
              ? `${d.lastName}, ${d.firstName}`
              : `${d.lastName}, ${d.firstName[0]}.`;

          if (getAthleteLink) {
            return <Link to={getAthleteLink(d)}>{name}</Link>;
          }
          return name;
        },
      },
      {
        width: windowWidth > MOBILE_WIDTH ? 75 : windowWidth / 10,
        label: "ETA",
        dataId: "timeEstimate",
        renderer: (d, i) => {
          const timeEstimate = d.timeEstimate * 1000;
          const now = new Date().getTime();
          if (i === 0 && Math.abs(now - timeEstimate) < 10000) {
            return "Now";
          }
          return new Date(timeEstimate).toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          });
        },
      },
      {
        width: windowWidth > MOBILE_WIDTH ? 75 : windowWidth / 10,
        label: windowWidth > MOBILE_WIDTH ? "School" : "Schl",
        dataId: "schoolAbrev",
      },
      ...(windowWidth > TABLET_WIDTH
        ? [
            {
              width: windowWidth > MOBILE_WIDTH ? 110 : windowWidth / 7,
              label: windowWidth > MOBILE_WIDTH ? "Weight Class" : "Wght Cls",
              renderer: (d) =>
                weightClasses.find((wc) => wc.id === (d.weightClass as UUID))
                  .weightClassName,
            },
          ]
        : []),
      {
        isNumber: true,
        width: windowWidth > MOBILE_WIDTH ? 80 : windowWidth / 25,
        label: windowWidth > MOBILE_WIDTH ? "Attempts" : "#",
        dataId: "liftCount",
      },
      ...(["SQUAT", "BENCH"].includes(liftingGroup.activeEvent)
        ? [
            {
              isNumber: true,
              width: windowWidth > MOBILE_WIDTH ? 100 : windowWidth / 5,
              label: "Pin Height",
              dataId:
                liftingGroup?.activeEvent === "SQUAT"
                  ? "pinHeightSquat"
                  : "pinHeightBench",
            },
          ]
        : []),
      {
        isNumber: true,
        width: windowWidth > MOBILE_WIDTH ? 120 : windowWidth / 10,
        label: windowWidth > MOBILE_WIDTH ? "Next Weight" : "Nxt Wt",
        dataId: "nextWeight",
      },
    ];
  }, [
    activeMeet,
    windowWidth,
    liftingGroup.activeEvent,
    getAthleteLink,
    weightClasses,
  ]);

  return (
    <>
      {children}
      <DisplayGrid data={sortedAthleteData} columns={columns} />
    </>
  );
}
