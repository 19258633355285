/* eslint-disable @typescript-eslint/no-unused-vars */
import type { SelectChangeEvent } from "@mui/material";
import { Button } from "@mui/material";
import { FormControl, InputLabel } from "@mui/material";
import { Box, MenuItem, Select, Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import * as React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { AddAthleteDialog, EditAthleteDialog } from "./AddEditAthleteDialog";
import useAsync from "../../customHooks/useAsync";
import type { RootState } from "../../data/types";
import AdminService from "../../services/admin";
import CoachService from "../../services/coach";
import type { Athlete, School, UUID } from "../../types";
import CenteredCircularProgress from "../common/CenteredCircularProgress";

export default function TeamManagement() {
  const user = useSelector((state) => {
    return (state as RootState).app.user;
  });
  const { teamId } = useParams();
  const { data, isInitial, isLoading } = useAsync(async () => {
    const teams = await (user.userType === "ADMIN"
      ? AdminService.getTeams()
      : CoachService.getTeams());
    return teams.sort((a, b) => {
      return a.schoolName.localeCompare(b.schoolName);
    });
  }, []);

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const [selectedAthlete, setSelectedAthlete] = React.useState<Athlete>(null);

  const [selectedTeamId, setSelectedTeamId] = React.useState<UUID>(teamId);

  React.useEffect(() => {
    if (data?.length === 1) {
      setSelectedTeamId(data[0].schoolsId);
    }
  }, [data]);

  const selectedTeam = React.useMemo(() => {
    return data?.find(
      (team) => team.schoolsId === selectedTeamId || team.id === selectedTeamId
    );
  }, [data, selectedTeamId]);

  function handleSelectTeam(event: SelectChangeEvent<UUID>) {
    window.history.pushState(
      null,
      "",
      `/coach/teamManagement/${event.target.value}`
    );
    setSelectedTeamId(event.target.value);
  }

  if (isLoading) {
    return <CenteredCircularProgress />;
  }

  if (data?.length === 0 || !data) {
    return (
      <Box>
        <Typography variant="h3">No Teams For The Current Coach</Typography>
      </Box>
    );
  }

  return (
    <Stack flex={1} gap={3}>
      <Stack gap={3} direction="row" flexWrap="wrap">
        {data.length === 1 ? (
          <Typography variant="h3" sx={{ flex: "1 1 300px" }}>
            {data[0].schoolName}
          </Typography>
        ) : (
          <FormControl sx={{ flex: "1 1 300px" }}>
            <InputLabel>Team</InputLabel>

            <Select
              value={selectedTeamId}
              label="Team"
              onChange={handleSelectTeam}
            >
              {data.map((team) => {
                return (
                  <MenuItem
                    key={team.schoolsId || team.id}
                    value={team.schoolsId || team.id}
                  >
                    {team.schoolName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
        <Button
          onClick={() => {
            setSelectedAthlete(null);
            setIsDialogOpen(true);
          }}
          disabled={!selectedTeam}
          color="success"
          variant="contained"
        >
          Add Athlete
        </Button>
      </Stack>
      {selectedTeam ? (
        <TeamGrid
          team={selectedTeam}
          isDialogOpen={isDialogOpen}
          setIsDialogOpen={setIsDialogOpen}
          selectedAthlete={selectedAthlete}
          setSelectedAthlete={setSelectedAthlete}
        />
      ) : (
        <Typography variant="h6">Please select a team.</Typography>
      )}
    </Stack>
  );
}

type TeamGridProps = {
  team: School;
  isDialogOpen?: boolean;
  setIsDialogOpen: (isOpen: boolean) => void;
  selectedAthlete?: Athlete;
  setSelectedAthlete?: (athlete: Athlete) => void;
};

function TeamGrid({
  team,
  isDialogOpen,
  setIsDialogOpen,
  selectedAthlete,
  setSelectedAthlete,
}: TeamGridProps) {
  const { data, isInitial, isLoading, reload } = useAsync(() => {
    return CoachService.getTeamAthletes(team.schoolsId || team.id);
  }, [team.schoolsId, team.id]);

  const athletes = data || [];

  function handleClickEdit(athleteId: UUID) {
    const athlete = athletes.find((a) => {
      if (a.id === athleteId) {
        return true;
      }
      return false;
    });
    setSelectedAthlete(athlete);
    setIsDialogOpen(true);
  }

  return (
    <>
      <DataGrid
        rows={athletes || []}
        columns={[
          {
            field: "lastName",
            headerName: "Name",
            flex: 1,
            renderCell: (params) => {
              return `${params.row.lastName}, ${params.row.firstName}`;
            },
          },
          {
            field: "gender",
            headerName: "Gender",
          },
          {
            field: "grade",
            headerName: "Grade",
          },
          {
            field: "baseBodyWeight",
            headerName: "Base Weight",
          },
          {
            field: "baseStartDeadlift",
            headerName: "Best Total",
          },
          {
            field: "actions",
            headerName: "",
            width: 200,
            renderCell: (params) => {
              return (
                <Stack direction="row" gap={1}>
                  <Button
                    variant="contained"
                    // @ts-ignore
                    onClick={() => handleClickEdit(params.id)}
                  >
                    Edit
                  </Button>
                  <Button variant="contained" color="error">
                    Remove
                  </Button>
                </Stack>
              );
            },
          },
        ]}
        components={{
          NoRowsOverlay: () => {
            if (isLoading) {
              return <CenteredCircularProgress />;
            }
            return (
              <Stack height="100%" alignItems="center" justifyContent="center">
                No athletes for this school.
              </Stack>
            );
          },
        }}
      />
      <AddAthleteDialog
        onClose={() => setIsDialogOpen(false)}
        open={isDialogOpen && !selectedAthlete}
        onSuccess={async (athlete: Athlete) => {
          if (selectedAthlete) {
            await CoachService.updateAthlete(athlete);
          } else {
            await CoachService.addAthlete({
              ...athlete,
              school: team.schoolsId || team.id,
            });
          }
          await reload();
          setSelectedAthlete(null);
        }}
      />
      <EditAthleteDialog
        athlete={selectedAthlete}
        onClose={() => setIsDialogOpen(false)}
        open={isDialogOpen && !!selectedAthlete}
        onSuccess={async (athlete: Athlete) => {
          if (selectedAthlete) {
            await CoachService.updateAthlete(athlete);
          } else {
            await CoachService.addAthlete({
              ...athlete,
              school: team.schoolsId || team.id,
            });
          }
          await reload();
          setSelectedAthlete(null);
        }}
      />
    </>
  );
}
