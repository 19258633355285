import Collapse from "@mui/material/Collapse";
import React from "react";
import { useState } from "react";

import "./styles.scss";

function getDataWithDataId<T>(data: T, dataId: string) {
  if (dataId.indexOf(".") < 0) {
    return data[dataId];
  }

  return dataId.split(".").reduce((o, i) => o[i], data);
}

export type ColumnDef<T> = {
  isNumber?: boolean;
  width?: number;
  innerCellWidth?: number;
  label: string;
  dataId?: string;
  renderer?: (data: T, index: number) => React.ReactNode;
  showAllRenderer?: (
    data: T,
    index: number,
    colDef: ColumnDef<T>
  ) => React.ReactNode;
};

type DisplayGridProps<T> = {
  data: T[];
  columns: ColumnDef<T>[];
  fullHeight?: boolean;
  showAdditionalRowData?: boolean;
  addtionalRow?: (data: T) => React.ReactNode;
  emptyComponent?: React.ReactNode;
};

export default function DisplayGrid<T>({
  data,
  columns,
  fullHeight = true,
  showAdditionalRowData,
  addtionalRow,
  emptyComponent,
}: DisplayGridProps<T>) {
  const [scrollBarWidth, setScrollBarWidth] = useState(0);

  const innerBodyRef = (element) => {
    if (
      element?.scrollHeight &&
      element?.clientHeight &&
      element.scrollHeight > element.clientHeight
    ) {
      setScrollBarWidth(element.offsetWidth - element.clientWidth);
    } else {
      setScrollBarWidth(0);
    }
  };

  const mounted = React.useRef(false);
  React.useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <div className={`DisplayGrid ${fullHeight ? "fullHeight" : ""}`}>
      <div
        className="columnHeader"
        style={{ paddingRight: `${scrollBarWidth}px` }}
      >
        {columns.map((col, colIndex) => (
          <div
            key={col.label}
            style={{ flex: col.width ? `0 0 ${col.width}px` : undefined }}
            className="column"
          >
            <span className="colLabel">{col.label}</span>

            {colIndex + 1 < columns.length ? (
              <div className="vertBreak" />
            ) : null}
          </div>
        ))}
      </div>
      <div className="body">
        <div ref={innerBodyRef} className="innerBody">
          {data && data.length > 0
            ? data.map((row, rowIndex) => (
                <Row
                  // eslint-disable-next-line react/no-array-index-key
                  key={rowIndex}
                  parentMounted={mounted.current}
                  rowData={row}
                  rowIndex={rowIndex}
                  showAdditionalRowData={showAdditionalRowData}
                  columns={columns}
                  addtionalRow={addtionalRow}
                />
              ))
            : emptyComponent}
        </div>
      </div>
    </div>
  );
}

type RowProps<T> = {
  parentMounted: boolean;
  rowData: T;
  rowIndex: number;
  showAdditionalRowData: boolean;
  columns: ColumnDef<T>[];
  addtionalRow?: (data: T) => React.ReactNode;
};

function Row<T>({
  parentMounted,
  rowData,
  rowIndex,
  showAdditionalRowData,
  columns,
  addtionalRow,
}: RowProps<T>) {
  const mounted = React.useRef(true);
  const [showRow, setShowRow] = React.useState(false);
  React.useEffect(() => {
    setShowRow(true);
    return () => {
      setShowRow(false);
      mounted.current = false;
    };
  }, []);

  return (
    <Collapse in={showRow || !parentMounted}>
      <div className={`rowContainer ${rowIndex % 2 == 1 ? "odd" : ""}`}>
        <div
          className={`row ${
            showAdditionalRowData ? "showAdditionalRowData" : ""
          }`}
        >
          {columns.map((col) => (
            <div
              key={col.label}
              style={{
                flex: col.width ? `0 0 ${col.width}px` : undefined,
              }}
              className="cell"
            >
              <span
                className={`cellValue ${col.isNumber ? "number" : ""}`}
                style={{ width: `${col.innerCellWidth}px` }}
              >
                {col.renderer
                  ? col.renderer(rowData, rowIndex)
                  : getDataWithDataId<T>(rowData, col.dataId as string)}
              </span>
            </div>
          ))}
        </div>

        {addtionalRow ? (
          <Collapse in={showAdditionalRowData}>
            {addtionalRow(rowData)}
          </Collapse>
        ) : null}
      </div>
    </Collapse>
  );
}
