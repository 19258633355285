/* eslint-disable @typescript-eslint/no-unused-vars */
import { LoadingButton } from "@mui/lab";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import type { DataGridProps, GridCellParams } from "@mui/x-data-grid";
import { DataGrid } from "@mui/x-data-grid";
import * as React from "react";
import { useEffect, useState, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import store from "../../data";
import AppActions from "../../data/app/actions";
import type { RootState } from "../../data/types";
import MainService from "../../services/main";
import MeetService from "../../services/meet";
import type { User, Meet, UUID } from "../../types";
import { UserTypes } from "../../types";
import CenteredCircularProgress from "../common/CenteredCircularProgress";
import ConfirmationDialog from "../common/ConfirmationDialog";

type ManageMeet = Meet & {
  canManageRoster: boolean;
};

type MeetGridProps = Omit<DataGridProps, "columns" | "rows"> & {
  onlyUsersMeets?: boolean;
};

function canManageRoster(meet: Meet, user: User) {
  const foundMeetSchool = meet.meetSchools?.find((school) => {
    return !!user?.teams?.find((team) => team.schoolsId === school.school);
  });

  if (foundMeetSchool) {
    return true;
  }
  return false;
}

export default function MeetsGrid({
  onlyUsersMeets = false,
  ...props
}: MeetGridProps) {
  const [meetsData, setMeetsData] = useState<ManageMeet[]>([]);
  const [filteredMeetData, setFilteredMeetData] = useState<ManageMeet[]>([]);
  const [forceReload, setForceReload] = useState(false);
  const [selectedMeet, setSelectedMeet] = useState<Meet | null>(null);
  const [showConfirmJoin, setShowConfirmJoin] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filterNameValue, setFilterNameValue] = useState("");
  const [isLoading, setLoading] = useState(false);
  const user = useSelector((state) => {
    return (state as RootState).app.user;
  });

  const getMeets = useCallback(async () => {
    const meets = await MainService.getMeets(onlyUsersMeets);

    const meetsWithSchools = meets
      .map((meet) => {
        return {
          ...meet,
          canManageRoster: canManageRoster(meet, user) || onlyUsersMeets,
        };
      })
      .sort((a, b) => {
        return new Date(a.meetDate).getTime() - new Date(b.meetDate).getTime();
      });
    // @ts-ignore
    setMeetsData(meetsWithSchools);
    // @ts-ignore
    setFilteredMeetData(meetsWithSchools);

    setLoading(false);
  }, [onlyUsersMeets, user]);

  useEffect(() => {
    setLoading(true);
    getMeets();
    // const timer = setInterval(() => {
    //   getMeets();
    // }, 3000);

    // return () => clearTimeout(timer);
  }, [getMeets]);

  useEffect(() => {
    if (forceReload) {
      setLoading(true);
      getMeets();
      setForceReload(false);
    }
  }, [forceReload, getMeets]);

  const filterByName = useCallback(
    (name: string) => {
      name = name.toLowerCase();

      // const data = meetsData.filter((a) => {
      //   if (name === "") {
      //     return true;
      //   }
      //   const firstName = a.firstName.toLowerCase();
      //   const lastName = a.lastName.toLowerCase();

      //   if (firstName.includes(name) || lastName.includes(name)) {
      //     return true;
      //   }

      //   return false;
      // });
      // setFilteredMeetData(data);
    },
    [meetsData]
  );

  useEffect(() => {
    if (isLoading === false) {
      filterByName(filterNameValue);
    }
  }, [filterByName, filterNameValue, isLoading]);

  const allowManageRoster = React.useMemo(() => {
    return user?.userType === UserTypes.COACH;
  }, [user?.userType]);

  async function handleJoinMeet() {
    if (!selectedMeet) {
      return;
    }

    const schoolId = user?.teams?.length > 0 ? user.teams[0].schoolsId : null;
    if (!schoolId) {
      return;
    }
    try {
      await MeetService.addTeamToMeet(
        selectedMeet.meetsId || selectedMeet.id,
        schoolId
      );
      setForceReload(true);
    } catch (e) {
      console.log(e);
      await store.dispatch(
        AppActions.setSnackBar({
          text: `Failed to join the meet. Please try again.  If this problem persists contact a meet administrator.`,
          severity: "error",
          open: true,
        })
      );
    }
    setShowConfirmJoin(false);
  }

  const columns = useMemo(() => {
    return [
      {
        field: "meetName",
        headerName: "Name",
        flex: 1,
        renderCell: (params) => {
          return (
            <Link to={`/meet/${params.row.meetsId || params.row.id}`}>
              {params.row.meetName}
            </Link>
          );
        },
      },
      // {
      //   field: "hostUser",
      //   headerName: "Host",
      //   width: 150,
      //   valueGetter: (params) =>
      //     params.row.schoolAbrev ? params.row.schoolAbrev : params.row.schoolName,
      // },
      {
        field: "meetDate",
        headerName: "Date",
        sortable: true,
        width: 120,
        valueGetter: (params) => {
          const date = new Date(params.row.meetDate);
          return date.toLocaleDateString();
        },
      },
      ...(onlyUsersMeets
        ? [
            {
              field: "My Team",
              headerName: "My Team",
              sortable: true,
              width: 200,
              valueGetter: (params) =>
                params.row.schoolAbrev
                  ? params.row.schoolAbrev
                  : params.row.schoolName,
            },
            {
              field: "schoolAthleteCount",
              headerName: "My Team Count",
              sortable: true,
              width: 200,
              valueGetter: (params) =>
                `${params.row.schoolAthleteCount} / ${params.row.maxRegisteredAthletes}`,
            },
          ]
        : []),
      ...(allowManageRoster
        ? [
            {
              field: "action",
              headerName: "Actions",
              sortable: false,
              width: onlyUsersMeets ? 200 : 300,
              renderCell: (params) => {
                // TODO: Add a team selection.  For now just use the first team
                const schoolId =
                  params.row.schoolsId || user?.teams?.length > 0
                    ? user.teams[0].schoolsId
                    : null;

                return params.row.canManageRoster ? (
                  <Link to={`/meet-roster/${params.row.meetsId}/${schoolId}`}>
                    <Button variant="contained">Manage Roster</Button>
                  </Link>
                ) : user.userType === "COACH" ? (
                  <Button
                    variant="contained"
                    onClick={() => {
                      setSelectedMeet(params.row);
                      setShowConfirmJoin(true);
                    }}
                  >
                    Join Meet
                  </Button>
                ) : null;
              },
            },
          ]
        : []),
    ];
  }, [allowManageRoster, isLoading, onlyUsersMeets, user]);

  return (
    <>
      <ConfirmationDialog
        open={showConfirmJoin}
        title="Confirm join meet"
        message={`Are you sure you want your team to join the ${selectedMeet?.meetName} meet?`}
        onConfirm={handleJoinMeet}
        onClose={() => {
          setShowConfirmJoin(false);
        }}
      />
      <DataGrid
        {...props}
        // @ts-ignore
        rows={filteredMeetData}
        // hideFooterPagination={true}
        columns={columns}
        components={{
          NoRowsOverlay: () => {
            if (isLoading) {
              return <CenteredCircularProgress />;
            }
            return (
              <Stack height="100%" alignItems="center" justifyContent="center">
                No meets available.
              </Stack>
            );
          },
        }}
      />
    </>
  );
}
