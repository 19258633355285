/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useSelector } from "react-redux";

import store from "../../data";
import AppActions from "../../data/app/actions";
import MeetActions from "../../data/meet/actions";
import type { RootState } from "../../data/types";
import MeetService from "../../services/meet";
import ConfirmationDialog from "../common/ConfirmationDialog";

export default function Home() {
  const [showConfirmJoin, setShowConfirmJoin] = React.useState<boolean>(false);
  const [showConfirmWithdraw, setShowConfirmWithdraw] =
    React.useState<boolean>(false);
  const { meet, user } = useSelector((state) => {
    return {
      meet: (state as RootState).meet.activeMeet,
      user: (state as RootState).app.user,
    };
  });

  const foundMeetSchool = meet?.meetSchools?.find((school) => {
    return !!user?.teams?.find((team) => team.schoolsId === school.school);
  });

  async function handleJoinMeet() {
    const schoolId = user?.teams?.length > 0 ? user.teams[0].schoolsId : null;
    if (!schoolId) {
      return;
    }
    try {
      await MeetService.addTeamToMeet(meet.id, schoolId);
      await store.dispatch(MeetActions.load());
    } catch (e) {
      console.log(e);
      await store.dispatch(
        AppActions.setSnackBar({
          text: `Failed to join the meet. Please try again.  If this problem persists contact a meet administrator.`,
          severity: "error",
          open: true,
        })
      );
    }
    setShowConfirmJoin(false);
  }

  async function handleWithdrawFromMeet() {
    const schoolId = user?.teams?.length > 0 ? user.teams[0].schoolsId : null;
    if (!schoolId) {
      return;
    }
    try {
      await MeetService.removeTeamFromMeet(meet.id, schoolId);
      await store.dispatch(MeetActions.load());
    } catch (e) {
      console.log(e);
      await store.dispatch(
        AppActions.setSnackBar({
          text: `Failed to remove team from meet. Please try again.  If this problem persists contact a meet administrator.`,
          severity: "error",
          open: true,
        })
      );
    }
    setShowConfirmWithdraw(false);
  }

  return (
    <>
      <Typography variant="h1">{meet?.meetName}</Typography>
      {user?.userType === "COACH" && meet.hostUser.id != user.id ? (
        <Box mb={2}>
          {foundMeetSchool ? (
            <>
              <ConfirmationDialog
                open={showConfirmWithdraw}
                title="Confirm withdraw from meet"
                message={`Are you sure you want to withdraw your team from the ${meet?.meetName} meet?`}
                onConfirm={handleWithdrawFromMeet}
                onClose={() => {
                  setShowConfirmWithdraw(false);
                }}
              />
              <Button
                onClick={() => setShowConfirmWithdraw(true)}
                color="error"
                variant="contained"
              >
                Withdraw from Meet
              </Button>
            </>
          ) : (
            <>
              <ConfirmationDialog
                open={showConfirmJoin}
                title="Confirm join meet"
                message={`Are you sure you want your team to join the ${meet?.meetName} meet?`}
                onConfirm={handleJoinMeet}
                onClose={() => {
                  setShowConfirmJoin(false);
                }}
              />
              <Button
                onClick={() => setShowConfirmJoin(true)}
                variant="contained"
              >
                Join Meet
              </Button>
            </>
          )}
        </Box>
      ) : null}
      <Stack>
        <Typography variant="body1" gutterBottom={true}>
          <b>Host: </b> {meet?.hostUser.firstName} {meet?.hostUser.lastName}
        </Typography>
        <Typography variant="body1" gutterBottom={true}>
          <b>When: </b> {new Date(meet?.meetDate).toLocaleString()}
        </Typography>
        <Typography variant="body1" gutterBottom={true}>
          <b>Roster cutoff date/time: </b>{" "}
          {(meet?.rosterCutOffDate
            ? new Date(meet?.rosterCutOffDate)
            : new Date(meet?.meetDate)
          ).toLocaleString()}
        </Typography>
        <Typography variant="body1" gutterBottom={true}>
          <b>Flights: </b> {meet?.flightCount ? meet?.flightCount : "None"}
        </Typography>
        <Typography variant="body1" gutterBottom={true}>
          <b>Rules/Description: </b>
        </Typography>
        <Typography variant="body1" gutterBottom={true}>
          {/* eslint-disable-next-line react/no-danger, @typescript-eslint/naming-convention */}
          <div dangerouslySetInnerHTML={{ __html: meet?.rulesText }} />
        </Typography>
      </Stack>
    </>
  );
}
