import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";
import * as React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import type { RootState } from "../../data/types";
import McService from "../../services/mc";

export default function OtherFinalResults() {
  const [maleLifterData, setMaleLifterData] = useState([]);
  const [femaleLifterData, setFemaleLifterData] = useState([]);
  const [bestTotalData, setBestTotalData] = useState([]);

  const { meet } = useSelector((state) => {
    return {
      meet: (state as RootState).meet.activeMeet,
    };
  });

  async function getData() {
    const maleLifter = await McService.getBestMaleLifter(meet.id);
    // @ts-ignore
    setMaleLifterData(maleLifter);
    const femaleLifter = await McService.getBestFemaleLifter(meet.id);
    // @ts-ignore
    setFemaleLifterData(femaleLifter);
    const bestTotal = await McService.getBestTotalLifter(meet.id);
    // @ts-ignore
    setBestTotalData(bestTotal);
  }

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      field: "place",
      headerName: "Place",
      sortable: false,
      width: 90,
    },
    {
      field: "fullName",
      headerName: "Full name",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 160,
      valueGetter: (params) =>
        `${params.row.firstName || ""} ${params.row.lastName || ""}`,
    },
    {
      field: "schoolName",
      headerName: "School",
      sortable: false,
      width: 200,
    },
    {
      field: "SQUAT",
      headerName: "Squat",
      sortable: false,
      width: 90,
      valueGetter: (params) => {
        return params.row.results.SQUAT;
      },
    },
    {
      field: "BENCH",
      headerName: "Bench",
      sortable: false,
      width: 90,
      valueGetter: (params) => {
        return params.row.results.BENCH;
      },
    },
    {
      field: "DEADLIFT",
      headerName: "Deadlift",
      sortable: false,
      width: 90,
      valueGetter: (params) => {
        return params.row.results.DEADLIFT;
      },
    },
    {
      field: "coefficient",
      headerName: "Coefficient",
      sortable: false,
      width: 90,
      valueGetter: (params) => {
        return params.row.results.coefficient.toFixed(3);
      },
    },
    {
      field: "total",
      headerName: "Total",
      sortable: false,
      width: 115,
      valueGetter: (params) => {
        return params.row.results.total;
      },
    },
  ];

  return (
    <Box>
      <Typography variant="h3">Outstanding Male Lifter(coef)</Typography>
      <DataGrid
        sx={{ height: 315, bgcolor: "background.paper" }}
        // @ts-ignore
        rows={maleLifterData}
        disableColumnMenu={true}
        hideFooter={true}
        columns={columns}
        disableSelectionOnClick={true}
        components={{
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              No final results for this weight class.
            </Stack>
          ),
        }}
      />
      <Typography variant="h3">Outstanding Female Lifter(coef)</Typography>
      <DataGrid
        sx={{ height: 315, bgcolor: "background.paper" }}
        // @ts-ignore
        rows={femaleLifterData}
        disableColumnMenu={true}
        hideFooter={true}
        columns={columns}
        disableSelectionOnClick={true}
        components={{
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              No final results for this weight class.
            </Stack>
          ),
        }}
      />
      <Typography variant="h3">Best Total Lifter</Typography>
      <DataGrid
        sx={{ height: 315, bgcolor: "background.paper" }}
        // @ts-ignore
        rows={bestTotalData}
        disableColumnMenu={true}
        hideFooter={true}
        columns={columns}
        disableSelectionOnClick={true}
        components={{
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              No final results for this weight class.
            </Stack>
          ),
        }}
      />
    </Box>
  );
}
