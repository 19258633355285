import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import * as React from "react";
import { useSelector } from "react-redux";

import store from "../data";
import AppActions from "../data/app/actions";
import type { RootState } from "../data/types";

const Alert = React.forwardRef((props, ref) => {
  // @ts-ignore
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function MySnackBar() {
  const snackBar = useSelector((state) => {
    return (state as RootState).app.snackBar;
  });

  function handleClose() {
    store.dispatch(AppActions.setSnackBar({ ...snackBar, open: false }));
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={snackBar.open}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      {/* @ts-ignore */}
      <Alert
        onClose={handleClose}
        severity={snackBar.severity}
        sx={{ width: "100%" }}
      >
        {snackBar.text}
      </Alert>
    </Snackbar>
  );
}
