import CircularProgress from "@mui/material/CircularProgress";
import * as React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Outlet } from "react-router-dom";

import store from "../../data";
import MeetActions from "../../data/meet/actions";
import type { RootState } from "../../data/types";

export default function MeetApp() {
  const { meetId } = useParams();

  const initialized = useSelector((state) => {
    return (state as RootState).meet.initialized;
  });

  useEffect(() => {
    // Initialize the Redux store
    store.dispatch(MeetActions.initialize(meetId));

    return () => {
      store.dispatch(MeetActions.deinitialize());
    };
  }, [meetId]);

  if (!initialized) {
    return <CircularProgress />;
  }

  return <Outlet />;
}
