import type { AppState } from "./types";
import { AppActionType } from "./types";
import { createReducer } from "../utils";

const INITIAL_STATE: AppState = {
  user: null,
  initialized: false,
  // We should switch to the connecting state when reconnecting,
  // but our current UI treats connecting the same as connected,
  // it will be a deceptive experience to think you're connected
  // when the system goes offline.
  // TODO: Handle reconnecting
  permissionError: false,
  //   loadError: null,
  debug: false,
  snackBar: { text: "", severity: null, open: false },
};

export default createReducer(INITIAL_STATE, {
  [AppActionType.INITIALIZE](state, { error, payload }) {
    if (error) {
      return {
        ...state,
      };
    }
    return {
      ...state,
      user: payload.user,
      initialized: true,
    };
  },
  [AppActionType.SET_SNACKBAR](state, { error, payload }) {
    return {
      ...state,
      snackBar: payload.snackBar,
    };
  },
  [AppActionType.SNACK_CLOSE](state) {
    return {
      ...state,
    };
  },
  [AppActionType.LOAD](state, { payload, error }) {
    if (error) {
      return {
        ...state,
        initialLoad: false,
        loadError: payload,
      };
    }
    return {
      ...state,
      initialLoad: false,
    };
  },
  [AppActionType.PERMISSION_ERROR](state) {
    return {
      ...state,
      permissionError: true,
    };
  },
  [AppActionType.TOGGLE_DEBUG](state) {
    return {
      ...state,
      debug: !state?.debug,
    };
  },
});
