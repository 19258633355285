import type { LiftingGroupWithWeightClass, WeightClass } from "../types";

export function sortWeightClasses(a: WeightClass, b: WeightClass) {
  if (a.gender != b.gender) {
    if (a.gender === "FEMALE") {
      return -1;
    } else {
      return 1;
    }
  }
  return a.maxWeight - b.maxWeight;
}

const PLATFORM = "Platform ";

export function sortLiftingGroups(
  a: LiftingGroupWithWeightClass,
  b: LiftingGroupWithWeightClass
) {
  if (
    a.liftingGroupName.substring(0, PLATFORM.length) === PLATFORM &&
    b.liftingGroupName.substring(0, PLATFORM.length) === PLATFORM
  ) {
    const aNum = parseInt(
      a.liftingGroupName.substring(PLATFORM.length, a.liftingGroupName.length),
      10
    );
    const bNum = parseInt(
      b.liftingGroupName.substring(PLATFORM.length, b.liftingGroupName.length),
      10
    );
    if (aNum < bNum) {
      return -1;
    }
    if (aNum > bNum) {
      return 1;
    }
  }

  if (!a.weightClasses) {
    return -1;
  }
  if (!b.weightClasses) {
    return 1;
  }

  if (a.weightClasses.length === 0 && b.weightClasses.length === 0) {
    return 0;
  }
  if (a.weightClasses.length > 0 && b.weightClasses.length === 0) {
    return -1;
  }
  if (b.weightClasses.length > 0 && a.weightClasses.length === 0) {
    return 1;
  }

  if (a.weightClasses[0].maxWeight < b.weightClasses[0].maxWeight) {
    return -1;
  }
  if (a.weightClasses[0].maxWeight > b.weightClasses[0].maxWeight) {
    return 1;
  }
  if (a.liftingGroupName > b.liftingGroupName) {
    return 1;
  }
  return 0;
}
