/* eslint-disable @typescript-eslint/no-unused-vars */
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Stack,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import WeightClassMeetRosterGrid from "./WeightClassMeetRosterGrid";
import useAsync from "../../customHooks/useAsync";
import type { AsyncValue } from "../../customHooks/useAsync";
import type { RootState } from "../../data/types";
import MeetService from "../../services/meet";
import type { MeetSchool, School, UUID, WeightClass } from "../../types";
import { sortWeightClasses } from "../../utils/sortUtils";
import CenteredCircularProgress from "../common/CenteredCircularProgress";

export default function MeetRoster() {
  const user = useSelector((state) => {
    return (state as RootState).app.user;
  });
  const { meetId, schoolId } = useParams();

  const initData = useAsync(async () => {
    return {
      meetConfig: await MeetService.getMeetConfig(meetId),
      school: await MeetService.getMeetSchool(meetId, schoolId),
    };
  });

  const meetAthleteCountData = useAsync(() => {
    return MeetService.getWeightClassesAthleteCount(meetId, schoolId);
  });

  const weightClasses = React.useMemo(() => {
    if (initData.data?.meetConfig) {
      return initData.data.meetConfig.weightClasses.sort(sortWeightClasses);
    }
    return null;
  }, [initData]);

  if (meetAthleteCountData.isInitial || initData.isInitial) {
    return <CenteredCircularProgress />;
  }

  const { meetConfig, school } = initData.data;
  const meetAthleteCounts = meetAthleteCountData.data;

  if (!meetConfig) {
    return (
      <Typography variant="h1">
        Meet is not configured yet. Please check back later.
      </Typography>
    );
  }

  const totalAthletes = Object.values(meetAthleteCounts).reduce(
    (acc, curr) => acc + curr,
    0
  );

  const dateNow = new Date();
  const rosterCutOffDate = meetConfig.rosterCutOffDate
    ? new Date(meetConfig.rosterCutOffDate)
    : new Date(meetConfig.meetDate);

  const pastCutOffDate = dateNow > rosterCutOffDate;

  console.log(rosterCutOffDate, dateNow);

  return (
    <Stack m={3} gap={3}>
      <Typography variant="h1">Meet Roster - {meetConfig.meetName}</Typography>
      <Stack>
        <Typography variant="body1" gutterBottom={true}>
          <b>Meet Date/Time: </b>{" "}
          {new Date(meetConfig.meetDate).toLocaleString()}
        </Typography>
        <Typography variant="body1" gutterBottom={true}>
          <b>Roster Cutoff Date/Time: </b>{" "}
          {(meetConfig.rosterCutOffDate
            ? rosterCutOffDate
            : new Date(meetConfig.meetDate)
          ).toLocaleString()}
        </Typography>
        <Typography variant="body1">
          <b>Team Count: </b> {totalAthletes} / {school.maxRegisteredAthletes}
        </Typography>
      </Stack>
      {!pastCutOffDate || user?.userType === "ADMIN" ? (
        weightClasses.map((weightClass) => {
          return (
            <WeightClassAccordian
              key={weightClass.id}
              weightClass={weightClass}
              meetId={meetId}
              schoolId={schoolId}
              weightClasses={weightClasses}
              meetAthleteCounts={meetAthleteCounts}
              school={school}
              meetAthleteCountData={meetAthleteCountData}
            />
          );
        })
      ) : (
        <Box>
          <Typography>
            <b>Past roster cutoff date/time.</b>
          </Typography>{" "}
          If you need to make any changes to your roster please contact the meet
          director.{" "}
        </Box>
      )}
    </Stack>
  );
}

type WeightClassAccordianProps = {
  weightClass: WeightClass;
  meetId: UUID;
  schoolId: UUID;
  weightClasses: WeightClass[];
  meetAthleteCounts: Record<UUID, number>;
  school: MeetSchool;
  meetAthleteCountData: AsyncValue<Record<UUID, number>>;
};

function WeightClassAccordian({
  weightClass,
  meetId,
  schoolId,
  weightClasses,
  meetAthleteCounts,
  school,
  meetAthleteCountData,
}: WeightClassAccordianProps) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };
  return (
    <Accordion expanded={expanded} onChange={handleChange}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Stack direction="row" alignItems="center" gap={2}>
          <Typography>{weightClass.weightClassName}</Typography>
          <Chip label={meetAthleteCounts[weightClass.id]} />
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <WeightClassMeetRosterGrid
          isExpanded={expanded}
          weightClasses={weightClasses}
          meetId={meetId}
          schoolId={schoolId}
          weightClass={weightClass}
          topLevelReload={meetAthleteCountData.reload}
          athleteCount={meetAthleteCounts[weightClass.id]}
          maxAthletes={school.maxRegisteredAthletes}
        />
      </AccordionDetails>
    </Accordion>
  );
}
