import * as React from "react";

import MeetSetup from "./MeetSetup";

export default function CreateMeet() {
  let dateNow = new Date();
  dateNow.setMinutes(0);
  dateNow = new Date(
    dateNow.getTime() - dateNow.getTimezoneOffset() * 60000 + 86400000 * 7
  );
  const dateTimeMeetIsoString = dateNow.toISOString();

  const rosterCutOffDate = new Date(dateNow.getTime() - 60 * 60 * 1000 * 12);
  const rosterCutOffDateIsoString = rosterCutOffDate.toISOString();

  return (
    <MeetSetup
      createMeet={true}
      meetDefaults={{
        enableFlights: false,
        flightCount: 2,
        meetName: "",
        meetDate: dateTimeMeetIsoString.substring(
          0,
          dateTimeMeetIsoString.length - 8
        ),
        rosterCutOffDate: rosterCutOffDateIsoString.substring(
          0,
          rosterCutOffDateIsoString.length - 8
        ),
        homePageText: "",
        rulesText: "",
        defaultAthletesPerSchool: 30,
        scorersPerWeightClassPerSchool: 2,
        scorersPerSchool: 5,
        isMeetInviteOnly: false,
        maxAtheletesTotal: 300,
        liftAttempts: 3,
        units: "STANDARD",
      }}
    />
  );
}
