import type { AlertProps } from "@mui/material";

import type { User } from "../../types";
import type { DeriveActions } from "../utils";

/**
 * The status of the WebSocket connection.
 */

export type SnackBarParams = {
  text: string;
  severity: AlertProps["severity"];
  open: boolean;
};

export type AppState = {
  user?: User;
  initialized?: boolean;
  permissionError?: boolean;
  debug?: boolean;
  snackBar?: SnackBarParams;
};

export enum AppActionType {
  INITIALIZE = "app/INITIALIZE",
  SET_SNACKBAR = "app/SET_SNACKBAR",
  SNACK_CLOSE = "app/SNACK_CLOSE",
  LOAD = "app/LOAD",
  RELOAD_STAFFING_REGIONS = "app/RELOAD_STAFFING_REGIONS",
  RELOAD_PRICING_REGIONS = "app/RELOAD_PRICING_REGIONS",
  PERMISSION_ERROR = "app/PERMISSION_ERROR",
  TOGGLE_DEBUG = "app/TOGGLE_DEBUG",
}

export type AppAction = DeriveActions<{
  [AppActionType.INITIALIZE]: Object;
  [AppActionType.SET_SNACKBAR]: { snackBar: SnackBarParams };
  [AppActionType.SNACK_CLOSE]: void;
  [AppActionType.LOAD]: {};
  [AppActionType.PERMISSION_ERROR]: void;
  [AppActionType.TOGGLE_DEBUG]: void;
}>;
