/* eslint-disable @typescript-eslint/naming-convention */
import ExpandCircleDownSharpIcon from "@mui/icons-material/ExpandCircleDownSharp";
import { Box, Button, Collapse, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useLocalStorage from "use-local-storage";

import useAsync from "../../../customHooks/useAsync";
import useWakeLock from "../../../customHooks/useWakeLock";
import type { RootState } from "../../../data/types";
import MeetService from "../../../services/meet";
import type { AthleteOrderWithResults } from "../../../types";
import CenteredCircularProgress from "../../common/CenteredCircularProgress";
import LiftOrderGrid from "../../common/LiftOrderGrid";

function getPinHeight(athlete: AthleteOrderWithResults, event: string) {
  if (event === "DEADLIFT") {
    return null;
  }

  if (event === "SQUAT") {
    return athlete.pinHeightSquat || "NOT SET";
  }
  if (event === "BENCH") {
    return athlete.pinHeightBench || "NOT SET";
  }
  return null;
}
type LiftOrderProps = {
  isHeadJudge?: boolean;
};

export default function LiftOrder({ isHeadJudge }: LiftOrderProps) {
  useWakeLock();
  const [showDetails, setShowDetails] = useLocalStorage<boolean>(
    "showLiftOrderDetails",
    undefined
  );
  const [polls, setPolls] = useState(0);
  const { liftGroupId } = useParams();
  const { meetId, weightClasses } = useSelector((state) => {
    return {
      meetId: (state as RootState).meet.activeMeet.id,
      weightClasses: (state as RootState).meet.activeMeet.weightClasses,
    };
  });

  useEffect(() => {
    const interval = setInterval(
      () => {
        setPolls((p) => p + 1);
      },
      isHeadJudge ? 2000 : 5000
    );
    return () => clearInterval(interval);
  }, [isHeadJudge, polls]);

  const { data, isInitial } = useAsync(async () => {
    if (!meetId) {
      return null;
    }
    return {
      athletes: await MeetService.getOrderForLiftingGroup(meetId, liftGroupId),
      liftingGroup: await MeetService.getLiftingGroup(liftGroupId),
    };
  }, [liftGroupId, polls]);

  if (isInitial || !data) {
    return <CenteredCircularProgress />;
  }

  const { athletes, liftingGroup } = data;
  if (athletes.length === 0) {
    return <div>No Athletes remaining</div>;
  }

  const firstAthlete = athletes[0];

  const pinHeight = getPinHeight(firstAthlete, liftingGroup.activeEvent);

  const canShowDetails =
    (isHeadJudge && showDetails === undefined) || showDetails;

  return (
    <LiftOrderGrid
      liftingGroup={liftingGroup}
      athleteData={athletes}
      weightClasses={weightClasses}
    >
      <Box>
        {isHeadJudge ? (
          <>
            <Typography fontSize="5rem" variant="h1">
              {firstAthlete.lastName},
            </Typography>
            <Typography fontSize="5rem" variant="h1">
              {firstAthlete.firstName}
            </Typography>
            <Typography fontSize="5rem" variant="h1">
              {firstAthlete.nextWeight}lbs
            </Typography>
          </>
        ) : (
          <Typography variant="h1">
            {`${firstAthlete.lastName}, ${firstAthlete.firstName} - ${firstAthlete.nextWeight}lbs`}
          </Typography>
        )}
        <Stack direction="row" gap={2} alignItems="center" mb={1}>
          {pinHeight ? (
            <Typography variant="h4">{`Pin Height: ${pinHeight}`}</Typography>
          ) : null}

          <Button
            onClick={() => {
              setShowDetails(!canShowDetails);
            }}
            startIcon={
              <ExpandCircleDownSharpIcon
                sx={{ transform: `scaleY(${canShowDetails ? -1 : 1})` }}
              />
            }
          >
            {canShowDetails ? "Hide Details" : "Show Details"}
          </Button>
        </Stack>

        {/* TODO: Show plate breakdown. */}
        <Collapse in={canShowDetails}>
          <Barbell weight={firstAthlete.nextWeight} />
        </Collapse>
        <Typography variant="h3">
          {`${liftingGroup.liftingGroupName} - Lift Order - ${liftingGroup.activeEvent}`}
        </Typography>
        <Typography variant="body1">
          {`Total Athletes: ${athletes.length}`}
        </Typography>
      </Box>
    </LiftOrderGrid>
  );
}

type BarbellProps = {
  weight: number;
};

function Barbell({ weight }: BarbellProps) {
  const plates = getPlatesForWeight(weight);

  const reaminingBarWidth = plates.length > 6 ? 30 : 30 * (7 - plates.length);
  return (
    <Stack direction="row" alignItems="center">
      <Box sx={{ backgroundColor: "grey", width: 40, height: 15 }} />
      <Box sx={{ backgroundColor: "grey", width: 20, height: 30 }} />
      <Stack gap="1px" direction="row" alignItems="center">
        {plates.map((p, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Plate key={i} weight={p} />
        ))}
      </Stack>
      <Box
        sx={{ backgroundColor: "grey", width: reaminingBarWidth, height: 15 }}
      />
    </Stack>
  );
}

type PlateWeight = 45 | 35 | 25 | 10 | 5 | 2.5;

type PlateHeight = {
  [key in PlateWeight]: number;
};

const plateHeights: PlateHeight = {
  45: 150,
  35: 120,
  25: 100,
  10: 80,
  5: 50,
  2.5: 30,
};

type PlateProps = { weight: PlateWeight };

function Plate({ weight }: PlateProps) {
  return (
    <Stack
      justifyContent="center"
      sx={{
        backgroundColor: "black",
        width: 30,
        height: plateHeights[weight],
        borderRadius: 1,
      }}
    >
      <Typography color="white" variant="h6" textAlign="center">
        {weight}
      </Typography>
    </Stack>
  );
}

function getPlatesForWeight(targetWeight: number): PlateWeight[] {
  if (targetWeight < 45) {
    return [];
  }

  // The weight of the barbell itself
  const barbellWeight = 45;
  let remainingWeight = targetWeight - barbellWeight;

  const platesAvailable: PlateWeight[] = [45, 35, 25, 10, 5, 2.5];
  const platesNeeded: PlateWeight[] = [];

  // Calculate plates needed for one side of the barbell
  remainingWeight /= 2;

  for (const plate of platesAvailable) {
    while (remainingWeight >= plate) {
      platesNeeded.push(plate);
      remainingWeight -= plate;
    }
  }

  return platesNeeded;
}
