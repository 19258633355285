export type UUID = string;

export const LIFTS = ["SQUAT", "BENCH", "DEADLIFT"];
export type Event = "SQUAT" | "BENCH" | "DEADLIFT";

type WeightClass = {
  id: UUID;
  weightClassName: string;
  maxWeight: number;
  gender: string;
  liftingGroup: UUID | LiftingGroup;
  weightClassOrder: number;
};

type LiftingGroup = {
  id: UUID;
  liftingGroupName: string;
  liftingGroupOrder: number;
  activeEvent: Event;
  liftingDay: Date;
  athletesPerFlight: number;
};

export type LiftingGroupWithOrder = LiftingGroup & {
  order: {
    meet_athleteId: UUID;
    flight: number;
    firstName: string;
    lastName: string;
    fullName: string;
    schoolName: string;
    liftCount: number;
    nextWeight: number;
  }[];
};

export type LiftingGroupWithWeightClass = LiftingGroup & {
  weightClasses: WeightClass[];
};

type School = {
  id: UUID;
  schoolName: string;
  mascot: string;
  schoolAbrev: string;
  class: number;
  schoolsId?: UUID;
};

export type MeetSchool = School & {
  maxRegisteredAthletes: number;
  school?: UUID;
};

type Athlete = {
  id: UUID;
  firstName: string;
  lastName: string;
  gender: string;
  grade: number;
  baseBodyWeight: number;
  baseStartSquat: number;
  baseStartBench: number;
  baseStartDeadlift: number;
  school: UUID | School;
};

export type Result = {
  id: UUID;
  meet: UUID;
  athlete: UUID;
  event: string;
  weight: number;
  liftSuccess: LiftSuccess;
  entryTime: Date;
  completionTime: Date;
};

export type MeetAthlete = Athlete & {
  meet_athleteId: UUID;
  bodyWeight: number;
  startSquat: number;
  startBench: number;
  startDeadlift: number;
  pinHeightSquat: number;
  pinHeightBench: number;
  flight: number;
  weightClass: UUID | WeightClass;
  results: Result[];
  liftingGroup?: UUID | LiftingGroup;
};

export type MutateMeetAthlete = {
  id: UUID;
  weightClass?: UUID;
  bodyWeight: number;
  startSquat: number;
  startBench: number;
  startDeadlift: number;
  pinHeightSquat: number;
  pinHeightBench: number;
};

export enum LiftSuccess {
  TRUE = "TRUE",
  FALSE = "FALSE",
  NA = "N/A",
}

type FinalResults = MeetAthlete &
  School & {
    SQUAT: number;
    BENCH: number;
    DEADLIFT: number;
    coefficient: number;
    total: number;
    place: number;
  };

export type AllResults = {
  SQUATs: Result[];
  BENCHs: Result[];
  DEADLIFTs: Result[];
  SQUAT: number;
  BENCH: number;
  DEADLIFT: number;
  coefficient: number;
  total: number;
  place: number;
};

export type AthleteWithAllResults = MeetAthlete &
  School & {
    results: AllResults;
  };

export type AthleteOrderWithResults = MeetAthlete &
  School & {
    order: number;
    nextWeight: number;
    liftCount: number;
    results: Result[];
    timeEstimate: number;
  };

export type AthleteOrderWithResultsAndLiftingGroup = AthleteOrderWithResults & {
  liftingGroup: LiftingGroup;
};

export type MeetAthleteWithResults = MeetAthlete &
  School & {
    nextWeight: number;
    liftCount: number;
    results: Result[];
    liftingGroup: LiftingGroup;
  };

export enum UserTypes {
  ADMIN = "ADMIN",
  COACH = "COACH",
  ENTRY = "ENTRY",
  MC = "MC",
  DIRECTOR = "DIRECTOR",
}

export type UserPermission = UserTypes;

export type User = {
  id: UUID;
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  userType: UserTypes;
  teams?: School[];
};

export type Meet = {
  id: UUID;
  meetName: string;
  hostUser: UUID;
  meetDate: Date;
  rosterCutOffDate: Date;
  homePageText: string;
  rulesText: string;
  defaultAthletesPerSchool: number;
  scorersPerWeightClassPerSchool: number;
  scorersPerSchool: number;
  isMeetInviteOnly: boolean;
  maxAtheletesTotal: number;
  liftAttempts: number;
  meetSchools?: MeetSchool[];
  units: string;
  enableFlights: boolean;
  flightCount: number;
  meetsId?: UUID;
};

export type MeetConfig = Omit<Meet, "hostUser"> & {
  hostUser: User;
  liftingGroup: LiftingGroup[];
  weightClasses: WeightClass[];
};

export type MeetPermission = {
  username: string;
  password: string;
  permission: string;
};

export type { WeightClass, LiftingGroup, School, Athlete, FinalResults };
