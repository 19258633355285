// import "whatwg-fetch";
// import { getAuthToken } from "./auth";

/**
 * This is a header employed by Flask-WTF to prevent CSRF attacks.
 * (We do not bother passing it down via `_js_config` since we never change it)
 * It's left as a constant here to clarify its link to the Flask-WTF setting
 * https://flask-wtf.readthedocs.io/en/stable/csrf.html#javascript-requests
 */
// const WTF_CSRF_HEADER = "X-CSRFToken";

type SuccessResponse<ResponseDataType> = {
  success: true;
  data: ResponseDataType;
};

type ErrorResponse = {
  success: false;
  error: string;
};

type JSONResponse<ResponseDataType> =
  | SuccessResponse<ResponseDataType>
  | ErrorResponse;

/**
 * Default options to use for all requests
 */
const DEFAULT_OPTIONS = {
  // Do not allow cross-origin requests
  // mode: "same-origin",
  // // Sends cookies on same-origin
  // credentials: "same-origin",
} as const;

type RequestOptions = {
  // If true, pass along the authtoken with this request
  isAuthenticated?: boolean;
  // If true, use the CSRF token for an unauthenticated request
  includeCsrf?: boolean;
};

/**
 * Thrown when the `fetch` command fails, which means there was some
 * issue making a connection.
 */
class NetworkError extends Error {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(...params: any[]) {
    super(...params);
    this.name = "NetworkError";
  }
}

/**
 * Thrown when the server returns a 4xx or 5xx error.
 */
class HTTPError extends Error {
  status: number;

  constructor(status: number, statusText: string) {
    super(`${status}: ${statusText}`);
    this.name = "HTTPError";
    this.status = status;
  }
}

/**
 * Thrown when the server responds with an error in the response.
 */
class ResponseError extends Error {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(...params: any[]) {
    super(...params);
    this.name = "ResponseError";
  }
}

async function makeRequest<RequestDataType, ResponseDataType>(
  method: string,
  url: string,
  data?: RequestDataType,
  options?: RequestOptions
) {
  let response = null;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const headers = {
    ["Content-Type"]: "application/json",
  };

  //   if (options?.isAuthenticated) {
  //     const token = getAuthToken();
  //     headers["Authorization"] = `Token ${token}`;
  //   }

  //   if (options?.includeCsrf) {
  //     // Refer to the comment for `WTF_CSRF_HEADER` above
  //     headers[WTF_CSRF_HEADER] = Config.get("csrf");
  //   }

  // Attempt to make the request
  try {
    response = await fetch(url, {
      ...DEFAULT_OPTIONS,
      method,
      // headers,
      body: data ? JSON.stringify(data) : null,
      credentials: "include",
    });
  } catch (e) {
    throw new NetworkError();
  }

  // Check the HTTP status
  if (response.status != 200) {
    throw new HTTPError(response.status, response.statusText);
  }
  const jsonResponse: JSONResponse<ResponseDataType> = await response.json();

  // Process the standard XHR response
  if (jsonResponse.success === false) {
    throw new ResponseError(jsonResponse.error || "Unknown error");
  }
  return jsonResponse.data;
}

const Requests = {
  /**
   * Makes a GET request
   */
  get<ResponseDataType>(
    url: string,
    options?: RequestOptions
  ): Promise<ResponseDataType> {
    return makeRequest<void, ResponseDataType>("GET", url, undefined, options);
  },

  /**
   * Makes a POST request
   */
  post<RequestDataType, ResponseDataType>(
    url: string,
    data?: RequestDataType,
    options?: RequestOptions
  ): Promise<ResponseDataType> {
    return makeRequest<RequestDataType, ResponseDataType>(
      "POST",
      url,
      data,
      options
    );
  },
};

export {
  NetworkError,
  HTTPError,
  ResponseError,
  // Export for testing
  makeRequest,
};
export default Requests;
