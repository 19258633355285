import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import type { DialogProps } from "@mui/material/Dialog";
import React from "react";

type ConfirmationDialogProps = DialogProps & {
  title?: string;
  message: string;
  onConfirm: () => void | Promise<void>;
  onClose: () => void;
};
export default function ConfirmationDialog({
  title = "Are you sure?",
  message,
  onConfirm,
  onClose,
  ...props
}: ConfirmationDialogProps) {
  const [isLoadingButton, setLoadingButton] = React.useState(false);
  async function handleConfirm() {
    const result = onConfirm();

    if (result instanceof Promise) {
      setLoadingButton(true);
      await result;
      setLoadingButton(false);
    }
  }

  return (
    <Dialog onClose={onClose} scroll="paper" {...props}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers={true}>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          No
        </Button>
        <LoadingButton
          loading={isLoadingButton}
          onClick={handleConfirm}
          variant="contained"
        >
          Yes
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
