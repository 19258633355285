/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Action as ReduxAction, Store } from "redux";
import type { Reducer } from "redux";
import { applyMiddleware, createStore as createReduxStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import type { ThunkMiddleware, ThunkDispatch, ThunkAction } from "redux-thunk";
import thunk from "redux-thunk";

/**
 * FSA compliant action that we use as our base action type.
 */
export interface Action<ActionType = any, Payload = any>
  extends ReduxAction<ActionType> {
  payload?: Payload;
  error?: boolean;
  meta?: Record<string, any>;
}

export interface ErrorAction<ActionType = any> extends Action<ActionType> {
  error: true;
}

/**
 * Creates a mapping of action type to Action.
 *
 * Usage:
 *
 *     type ActionPayloads = ActionPayloadsInternal<{
 *         [ActionType.LOAD]: {
 *             entity: Entity;
 *         };
 *         [ActionType.CREATE]: {
 *             name: string;
 *         };
 *     }>;
 *
 * is equivalent to
 *
 *     type ActionPayloads = {
 *         [ActionType.LOAD]: {
 *             type: ActionType.LOAD,
 *             payload: {
 *                 entity: Entity;
 *             };
 *         };
 *         [ActionType.CREATE]: {
 *             type: ActionType.CREATE,
 *             payload: {
 *                 name: string;
 *             };
 *         };
 *     };
 */
type ActionPayloadsInternal<PayloadTypes> = {
  [ActionType in keyof PayloadTypes]: Action<
    ActionType,
    PayloadTypes[ActionType]
  >;
};

/**
 * Creates an Action type that strongly types the payload based
 * on the `type` field.
 *
 * Usage:
 *
 *     type Action = DeriveActions<{
 *         [ActionType.LOAD]: {
 *             entity: Entity;
 *         };
 *         [ActionType.CREATE]: {
 *             name: string;
 *         };
 *     }>;
 *
 * is equivalent to
 *
 *     type Action = {
 *         type: ActionType.LOAD,
 *         payload: {
 *             entity: Entity;
 *         };
 *     } | {
 *         type: ActionType.CREATE,
 *         payload: {
 *             name: string;
 *         };
 *     }};
 */
export type DeriveActions<PayloadTypes> =
  ActionPayloadsInternal<PayloadTypes>[keyof ActionPayloadsInternal<PayloadTypes>];

/**
 * Map of action types to reducers
 * @template S Shape of the Redux store
 * @template A Actions dispatched to the store
 */
type ReducersMap<S, A extends Action = any> = { [key: string]: Reducer<S, A> };

/**
 * Map of slice to its corresponding slice reducer
 */
type ReducersTree<S, A extends Action = any> = { $?: Reducer<S, A> } & {
  [K in keyof S]?: Reducer<S[K], A> | ReducersTree<S[K], A>;
};

/**
 * Apply a reducer to a slice of a Redux store for the given action.
 * @param reducer Reducer spec
 * @param state Current Redux state
 * @param action Dispatched Redux action
 * @return New Redux state
 */
function reduce<State>(
  reducer: Reducer<State> | ReducersTree<State>,
  state: State,
  action: Action
): State {
  if (typeof reducer == "function") {
    return reducer(state, action);
  }

  let newState = {} as State;
  if (reducer["$"]) {
    newState = reduce(reducer["$"], state, action);
  }

  const childState = {} as State;
  for (const key in reducer) {
    if (key == "$") {
      continue;
    }
    childState[key] = reduce(
      reducer[key],
      state ? state[key] : undefined,
      action
    );
  }

  // ImmerJS autofreezes the objects returned by the `produce` method
  // in development to avoid accidental mutations outside the `producer`.
  // This means that trying to assign to the keys in the `rootState`
  // is impossible. Since Immer disables this behavior in production for
  // performance reasons, we can also implement a performance optimization
  // here that avoids copying the entire root state. This is a safe operation
  // because the state tree for this node is created within this function
  // so we don't have to worry about it being mutated before we return.
  if (process.env.NODE_ENV === "development") {
    return Object.freeze({
      ...newState,
      ...childState,
    });
  } else {
    return Object.assign(newState, childState);
  }
}

/**
 * Creates a Redux store, hooking up our default set of enhancers and middlewars.
 * @param reducers A mapping of keys in the Redux state to the
 *     corresponding reducer for that particular slice of the Redux state.
 * @return Redux store
 */
export function createStore<State>(reducers: ReducersTree<State>): Store<
  State,
  any
> & {
  dispatch: ThunkDispatch<State, void, Action>;
} {
  function combinedReducer(state: State, action: Action) {
    return reduce(reducers, state, action);
  }

  let enhancer = applyMiddleware(thunk as ThunkMiddleware<State, Action, void>);

  // Disable the Redux DevTools in production (and testing).
  // This should technically already be the case, but the debugger
  // is currently activated in production so I don't trust that
  // their implementation is working correctly.
  if (process.env.NODE_ENV === "development") {
    enhancer = composeWithDevTools(enhancer);
  }

  return createReduxStore(combinedReducer, enhancer);
}

/**
 * Creates a Redux reducer. Uses an abbreviated syntax that maps action type
 * to a function that handles the action type.
 * @template S Shape of the Redux store
 * @template A Actions dispatched to the store
 * @param initialState The initial value of the Redux state.
 * @param reducers Map of action type to function.
 * @return Redux reducer
 */
export function createReducer<S, A extends Action = any>(
  initialState: S,
  reducers: ReducersMap<S, A>
): Reducer<S, A> {
  return (state = initialState, action): S => {
    if (reducers[action.type]) {
      return reducers[action.type](state, action);
    }
    return state;
  };
}

/**
 * Creates a set of action types, where an action type is the an action
 * prefixed with a namespace. The weird generics used below allows us to
 * generate an object has the values of the `actions` array as keys.
 * @template T Valid action types
 * @template U Map of action types to generated string
 * @param namespace Prefix for the action
 * @param actions Set of action types to generate
 * @return Action type enum to human readable string
 */
export function createActionTypes<T extends string, U = { [K in T]: string }>(
  namespace: string,
  actions: T[]
): U {
  const actionTypes = {} as U;
  for (const action of actions) {
    // @ts-ignore Not sure why T is not a valid index into the output
    // mapping since the type declaration uses `K in T`. We'll just
    // ignore it for now since this is a pretty safe operation.
    actionTypes[action] = `${namespace}/${action}`;
  }
  return actionTypes;
}

/**
 * Redux action creator that returns either an Action object
 * or Redux thunk async action.
 * @template S - The shape of the overall store that is
 *    returned when `getState` is called in an async action
 * @template A - The valid actions that can be dispatched
 *    by the Redux store
 */
type ActionCreator<S, A extends Action> = (
  ...args: any[]
) => A | ThunkAction<any | Promise<any>, S, void, A>;

/**
 * Creates a typed action creator generator. This should be used within
 * each app's Redux store to create a wrapper method that generates
 * action creator maps (a bundled object where the values are action creators).
 *
 * IMPLEMENTATION NOTE: This method solves a problem where TypeScript does
 * not support partial inference on its types. That means when using generics,
 * either all generic values are inferred or none are. In our definitions for
 * our action creators, we want to preserve the type of the original definition
 * we pass in, overriding the type of the returned value (which is either
 * an `Action` or `ThunkAction`) which by default is `any`. This allows us to
 * enforce that the action returned by our action creator is valid. In the async
 * case, it also guarantees the `dispatch` and `getState` methods passed to our
 * `ThunkAction` have the correct types.
 *
 * Thus, we have two user defined generics (the actions and the shape of the store)
 * and one inferred generic (the action creator). The workaround to this problem
 * is to use a function with user defined generics to generate a function that
 * can be used with inferred generics.
 *
 * Usage:
 *
 *     const createActions = typedActionCreators<Action, RootState>;
 *
 *     const Actions = createActions({
 *         ... Action creators for your stores ...
 *     });
 *
 * @template S - The shape of the overall store that is
 *     returned when `getState` is called in an async action
 * @template A - The valid actions that can be dispatched
 *     by the Redux store
 */
export function typedActionCreators<S, A extends Action>() {
  /**
   * @template M Action creators - A mapping of method names to action creators.
   */
  return function <M extends { [K in keyof M]: ActionCreator<S, A> }>(
    creatorsMap: M
  ): M {
    return creatorsMap;
  };
}

export type { Reducer };
export {
  // Exported for testing
  reduce as _reduce,
};
