import * as React from "react";
import { useSelector } from "react-redux";

import MeetSetup from "./MeetSetup";
import useAsync from "../../../customHooks/useAsync";
import type { RootState } from "../../../data/types";
import MeetService from "../../../services/meet";
import CenteredCircularProgress from "../../common/CenteredCircularProgress";

export default function EditMeet() {
  const meet = useSelector((state) => {
    return (state as RootState).meet.activeMeet;
  });

  let dateOfMeet = new Date(meet.meetDate);
  dateOfMeet = new Date(
    dateOfMeet.getTime() - dateOfMeet.getTimezoneOffset() * 60000
  );
  const dateTimeMeetIsoString = dateOfMeet.toISOString();

  let rosterCutOffDate = meet.rosterCutOffDate
    ? new Date(meet.rosterCutOffDate)
    : new Date(meet.meetDate);
  rosterCutOffDate = new Date(
    rosterCutOffDate.getTime() - rosterCutOffDate.getTimezoneOffset() * 60000
  );
  const rosterCutOffDateIsoString = rosterCutOffDate.toISOString();

  const { data, isLoading } = useAsync(() => {
    return MeetService.getWeightClassesAthleteCount(meet.id);
  }, []);

  if (isLoading || !data) {
    return <CenteredCircularProgress />;
  }

  return (
    <MeetSetup
      meetDefaults={{
        ...meet,
        hostUser: meet.hostUser.id,
        meetDate: dateTimeMeetIsoString.substring(
          0,
          dateTimeMeetIsoString.length - 8
        ),
        rosterCutOffDate: rosterCutOffDateIsoString.substring(
          0,
          rosterCutOffDateIsoString.length - 8
        ),
      }}
      weightClassesCounts={data}
    />
  );
}
