/* eslint-disable @typescript-eslint/no-unused-vars */
import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import type { RootState } from "../../../data/types";
// import AthletesManager from "../AthletesManager";
import LinkTab from "../../common/LinkTab";
import LinkTabs from "../../common/LinkTabs";

export default function AdminConsole() {
  const activeMeet = useSelector((state) => {
    return (state as RootState).meet.activeMeet;
  });
  const user = useSelector((state) => {
    return (state as RootState).app.user;
  });
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <LinkTabs>
          <LinkTab
            label="Meet Setup"
            href={`/meet/${activeMeet.id}/admin/setup`}
          />
          <LinkTab
            label="Meet Management"
            href={`/meet/${activeMeet.id}/admin/management`}
          />
          <LinkTab
            label="All Athletes"
            href={`/meet/${activeMeet.id}/admin/athletes`}
          />
          <LinkTab
            label="Meet Users"
            href={`/meet/${activeMeet.id}/admin/users`}
          />
          {user?.userType === "ADMIN" ? (
            <LinkTab
              label="Meet Teams"
              href={`/meet/${activeMeet.id}/admin/teams`}
            />
          ) : null}
        </LinkTabs>
      </Box>
      <Stack flex={1} sx={{ p: 3 }}>
        <Outlet />
      </Stack>
    </Box>
  );
}
