import type { MeetConfig, UserPermission } from "../../types";
import type { DeriveActions } from "../utils";

export type MeetState = {
  activeMeet?: MeetConfig;
  meetPermissions?: UserPermission[];
  initialized?: boolean;
};

export enum MeetActionType {
  INITIALIZE = "meet/INITIALIZE",
  DEINITIALIZE = "meet/DEINITIALIZE",
  LOAD = "meet/LOAD",
}

export type MeetAction = DeriveActions<{
  [MeetActionType.INITIALIZE]: Object;
  [MeetActionType.DEINITIALIZE]: {};
  [MeetActionType.LOAD]: {};
}>;
