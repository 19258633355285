/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";

import type { LiftingGroupForm, WeightClassForm } from "./types";
import { Athlete } from "../../../types";

type WeightClassFormWithLiftingGroup = WeightClassForm & {
  liftingGroup: string;
};

type AddEditWeightClassDialogProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: (
    weightClass: WeightClassForm,
    liftingGroup: LiftingGroupForm
  ) => void;
  weightClass: WeightClassForm;
  liftingGroup?: LiftingGroupForm;
  liftingGroups: LiftingGroupForm[];
};
export function AddEditWeightClassDialog({
  open,
  onClose,
  onSubmit,
  weightClass,
  liftingGroup,
  liftingGroups,
}: AddEditWeightClassDialogProps) {
  const isSubmitting = React.useRef(false);
  function handleFormSubmit(data: WeightClassFormWithLiftingGroup) {
    const lg = liftingGroups.find(
      (group) => group.liftingGroupName === data.liftingGroup
    );

    isSubmitting.current = true;
    onSubmit(data, lg);
    isSubmitting.current = false;
  }

  const weightClasses = React.useMemo(() => {
    // Get all the weightclass from all the lifting groups.
    return liftingGroups.reduce(
      (acc, group) => [
        ...acc,
        ...group.weightClasses.filter(
          (wc) => wc.weightClassName !== weightClass?.weightClassName
        ),
      ],
      [] as WeightClassForm[]
    );
  }, [weightClass, liftingGroups]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
  } = useForm<WeightClassFormWithLiftingGroup>({
    defaultValues: React.useMemo(() => {
      return {
        ...weightClass,
        liftingGroup:
          liftingGroup?.liftingGroupName ||
          (liftingGroups.length > 0 ? liftingGroups[0].liftingGroupName : ""),
      };
    }, [weightClass, liftingGroup, liftingGroups]),
  });

  const resetDefaults = React.useCallback(() => {
    reset({
      ...weightClass,
      liftingGroup:
        liftingGroup?.liftingGroupName || liftingGroups[0].liftingGroupName,
    });
  }, [reset, weightClass, liftingGroup, liftingGroups]);

  React.useEffect(() => {
    if (open && !isSubmitting.current) {
      resetDefaults();
    }
  }, [open, resetDefaults]);

  function handleInternalSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    handleSubmit(handleFormSubmit)(e);
    e.stopPropagation();
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <form onSubmit={handleInternalSubmit}>
        <DialogTitle>{weightClass ? "Edit" : "Add"} Weight Class</DialogTitle>

        <DialogContent
          dividers={true}
          sx={{ display: "flex", flexDirection: "column", gap: 2 }}
        >
          <TextField
            {...register("weightClassName", {
              required: true,
              validate: (value) => {
                const isValid = !weightClasses.some(
                  (wc) => wc.weightClassName === value
                );

                if (!isValid) {
                  return "Weight class name must be unique.";
                }
                return isValid;
              },
            })}
            error={!!errors.weightClassName}
            label="Name"
            required={true}
            fullWidth={true}
            helperText={
              errors.weightClassName ? errors.weightClassName?.message : ""
            }
          />
          <TextField
            {...register("maxWeight", { required: true })}
            error={!!errors.maxWeight}
            label="Max Weight"
            required={true}
            type="number"
            inputProps={{ inputMode: "decimal", step: 0.1 }}
          />

          <Controller
            name="gender"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth={true}
                select={true}
                label="Gender"
                error={!!errors.gender}
                required={true}
                helperText={errors.gender ? errors.gender?.message : ""}
              >
                <MenuItem value="MALE">Male</MenuItem>
                <MenuItem value="FEMALE">Female</MenuItem>
              </TextField>
            )}
          />
          <Controller
            name="liftingGroup"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth={true}
                select={true}
                label="Platform"
                error={!!errors.liftingGroup}
                required={true}
                helperText={
                  errors.liftingGroup ? errors.liftingGroup?.message : ""
                }
              >
                {liftingGroups.map((group) => (
                  <MenuItem
                    key={group.liftingGroupName}
                    value={group.liftingGroupName}
                  >
                    {group.liftingGroupName}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">Save</Button>
          <Button onClick={onClose}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
