import Typography from "@mui/material/Typography";
import * as React from "react";
import { useSelector } from "react-redux";

import type { RootState } from "../../data/types";

export default function Rules() {
  const activeMeet = useSelector((state) => {
    return (state as RootState).meet.activeMeet;
  });

  const meet = activeMeet;

  return (
    <>
      <Typography variant="h1">{meet?.meetName} Rules</Typography>
      <Typography variant="body1" gutterBottom={true}>
        {meet?.rulesText}
      </Typography>
    </>
  );
}
