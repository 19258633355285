import type { SnackBarParams } from "./types";
import { AppActionType } from "./types";
import AuthService from "../../services/auth";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import CoachService from "../../services/coach";
import type { User } from "../../types";
import { createActions } from "../types";

const AppActions = createActions({
  initialize() {
    return async (dispatch) => {
      const user = await AuthService.getUser();
      if (user?.userType === "COACH") {
        user.teams = await CoachService.getTeams();
      }

      dispatch({
        type: AppActionType.INITIALIZE,
        payload: { user },
      });
    };
  },

  setUser(user: User) {
    return async (dispatch) => {
      if (user?.userType === "COACH") {
        user.teams = await CoachService.getTeams();
      }
      await dispatch({
        type: AppActionType.INITIALIZE,
        payload: { user },
      });
    };
  },

  setSnackBar(snackBar: SnackBarParams) {
    return async (dispatch) => {
      await dispatch({
        type: AppActionType.SET_SNACKBAR,
        payload: { snackBar },
      });
    };
  },

  reloadStaffingRegions() {
    return async (dispatch, getState) => {};
  },

  reloadPricingRegions() {
    return async (dispatch, getState) => {};
  },

  toggleDebug() {
    return {
      type: AppActionType.TOGGLE_DEBUG,
    };
  },
});

export default AppActions;
