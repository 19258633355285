import type { TabsProps } from "@mui/material/Tabs";
import Tabs from "@mui/material/Tabs";
import type { ReactElement } from "react";
import { useMemo } from "react";
import React from "react";
import { useLocation } from "react-router-dom";

import type { LinkTabProps } from "./LinkTab";

export type LinkTabsProps = Omit<TabsProps, "children"> & {
  children: React.ReactElement<LinkTabProps>[];
};

/**
 * A wrapper for the `Tabs` component that allows for each tab
 * to take in a href that allows for navigating using react router.
 */
export default function LinkTabs(props: LinkTabsProps): ReactElement {
  const { children } = props;
  const location = useLocation();
  const selectedTabIndex = useMemo(() => {
    let tabIndex = 0;
    const longestMatch = "";
    children.map((child, i) => {
      const refLength = child?.props.href.length;
      if (child?.props.href === location.pathname.slice(0, refLength)) {
        if (longestMatch.length < refLength) {
          tabIndex = i;
        }
      }
    });

    return tabIndex;
  }, [children, location.pathname]);
  return <Tabs value={selectedTabIndex}>{children}</Tabs>;
}
