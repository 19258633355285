import { LoadingButton } from "@mui/lab";
import { Button, TextField, Collapse, Box, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { parseInt } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import store from "../../../data";
import AppActions from "../../../data/app/actions";
import type { RootState } from "../../../data/types";
import EntryService from "../../../services/entry";
import MeetService from "../../../services/meet";
import { LiftSuccess } from "../../../types";
import type { MeetAthleteWithResults, Result } from "../../../types";
import ConfirmationDialog from "../../common/ConfirmationDialog";
import DisplayGrid from "../../common/DisplayGrid";
import { getStartLiftFieldForActiveEvent } from "../../common/utils";

type LiftEntryAthleteProps = {
  athlete: MeetAthleteWithResults;
};

export default function LiftEntryAthleteGrid({
  athlete,
}: LiftEntryAthleteProps) {
  const [results, setResults] = useState<Partial<Result>[]>(athlete.results);
  useEffect(() => {
    if (athlete.results.length === 0) {
      const startField = getStartLiftFieldForActiveEvent(
        athlete.liftingGroup.activeEvent
      );
      setResults([
        {
          weight: athlete[startField],
        },
      ]);
    } else {
      setResults(athlete.results);
    }
  }, [athlete, athlete.results]);

  const { meetId, liftAttempts } = useSelector((state) => {
    return {
      meetId: (state as RootState).meet.activeMeet.id,
      liftAttempts: (state as RootState).meet.activeMeet.liftAttempts,
    };
  });

  async function onSubmitAddLift(nextWeight: number) {
    const newResultId = await EntryService.addResultWeight(
      athlete.meet_athleteId,
      nextWeight,
      athlete.liftingGroup.activeEvent
    );

    setResults([...results, { id: newResultId, weight: nextWeight }]);
  }

  const showAddLift = useMemo(() => {
    if (results.length === liftAttempts) {
      return false;
    }
    if (results.length === 0) {
      return true;
    }
    const lastResult = results[results.length - 1];
    if (
      lastResult.liftSuccess === LiftSuccess.TRUE ||
      lastResult.liftSuccess === LiftSuccess.FALSE
    ) {
      return true;
    }
    return false;
  }, [results, liftAttempts]);

  function handleDeleted(result: Partial<Result>) {
    const res = results.filter((r) => r.id !== result.id);

    if (res.length === 0) {
      const startField = getStartLiftFieldForActiveEvent(
        athlete.liftingGroup.activeEvent
      );
      res.push({
        weight: athlete[startField],
      });
    }
    setResults(res);
  }

  async function handleLiftSuccess(
    result: Partial<Result>,
    liftSuccess: LiftSuccess
  ) {
    let updatedId = result.id;
    if (result.id) {
      await EntryService.setLiftSuccess(result.id, liftSuccess);
    } else {
      updatedId = await EntryService.addResultWeight(
        athlete.meet_athleteId,
        result.weight,
        athlete.liftingGroup.activeEvent,
        liftSuccess
      );
    }

    setResults([
      ...results.map((r) => {
        if (r.id === result.id) {
          return { ...r, id: updatedId, liftSuccess };
        }
        return r;
      }),
    ]);
  }

  const navigate = useNavigate();

  async function handleGoToNextAthlete() {
    const nextAthlete = await MeetService.getNextAthleteInGroup(
      meetId,
      athlete.liftingGroup.id
    );

    if (!nextAthlete) {
      navigate(`/meet/${meetId}/liftEntryGroup/${athlete.liftingGroup.id}`);
      return;
    }
    if (nextAthlete.meet_athleteId === athlete.meet_athleteId) {
      await store.dispatch(
        AppActions.setSnackBar({
          text: `The current athlete (${athlete.lastName}, ${athlete.firstName}) is the next athlete in the lifting group.`,
          severity: "info",
          open: true,
        })
      );
      return;
    }
    navigate(`/meet/${meetId}/liftEntryAthlete/${nextAthlete.meet_athleteId}`);
  }

  return (
    <>
      <DisplayGrid
        data={results}
        fullHeight={false}
        columns={[
          {
            label: "Attempt",
            isNumber: true,
            width: 100,
            renderer: (r, index) => index + 1,
          },
          {
            label: "Weight",
            isNumber: true,
            renderer: (r) => {
              return r.weight;
            },
          },
          {
            label: "Lift Result",
            renderer: (r) => {
              if (!r.liftSuccess) {
                return (
                  <Stack direction="row">
                    <Button
                      onClick={() => {
                        handleLiftSuccess(r, LiftSuccess.TRUE);
                      }}
                      variant="outlined"
                      color="success"
                    >
                      Success
                    </Button>
                    <Button
                      onClick={() => {
                        handleLiftSuccess(r, LiftSuccess.FALSE);
                      }}
                      variant="outlined"
                      color="error"
                    >
                      Failed
                    </Button>
                  </Stack>
                );
              }
              return r.liftSuccess === "TRUE" ? (
                <Typography variant="body1" color="success.main">
                  SUCCESS
                </Typography>
              ) : (
                <Typography variant="body1" color="error.main">
                  FAIL
                </Typography>
              );
            },
          },
          {
            label: "Delete Entry",
            renderer: (r) => {
              if (results.length <= 1 && !r.id) {
                return null;
              }
              return (
                <DeleteButton
                  athlete={athlete}
                  result={r}
                  onDeleted={handleDeleted}
                />
              );
            },
          },
        ]}
      />
      <Stack direction="row" justifyContent="space-between" sx={{ mt: 2 }}>
        <Box>
          <Collapse in={showAddLift} mountOnEnter={true} unmountOnExit={true}>
            <AddLift
              athlete={athlete}
              results={results}
              onSubmitAddLift={onSubmitAddLift}
            />
          </Collapse>
        </Box>
        <Button onClick={handleGoToNextAthlete}>
          Go To Next Athlete In Group
        </Button>
      </Stack>
    </>
  );
}

type DeleteButtonProps = {
  athlete: MeetAthleteWithResults;
  result: Partial<Result>;
  onDeleted: (result: Partial<Result>) => void;
};

function DeleteButton({ athlete, result, onDeleted }: DeleteButtonProps) {
  const [isLoading, setLoading] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);

  async function handleConfirm() {
    setDialogOpen(false);
    setLoading(true);
    try {
      await EntryService.deleteResult(result.id);

      onDeleted(result);
    } catch (e) {
      await store.dispatch(
        AppActions.setSnackBar({
          text: `Failed delete result for ${athlete.firstName} ${athlete.lastName}. Please try again.  If this problem persists contact a meet administrator.`,
          severity: "error",
          open: true,
        })
      );
    }

    setLoading(false);
  }
  return (
    <>
      <LoadingButton
        loading={isLoading}
        onClick={() => setDialogOpen(true)}
        variant="outlined"
        color="error"
      >
        Delete
      </LoadingButton>
      <ConfirmationDialog
        title="Confirm delete result"
        message={`Are you sure you want to delete this result for ${athlete.firstName} ${athlete.lastName}?`}
        open={isDialogOpen}
        onConfirm={handleConfirm}
        onClose={() => {
          setDialogOpen(false);
        }}
      />
    </>
  );
}

type AddLiftProps = {
  athlete: MeetAthleteWithResults;
  results: Partial<Result>[];
  onSubmitAddLift: (nextWeight: number) => void;
};

function AddLift({ athlete, results, onSubmitAddLift }: AddLiftProps) {
  const [showInput, setShowInput] = useState(false);
  const [nextWeight, setNextWeight] = useState(0);
  async function handleSumit() {
    if (nextWeight % 5 !== 0) {
      await store.dispatch(
        AppActions.setSnackBar({
          text: `Weight must be a multiple of 5.`,
          severity: "error",
          open: true,
        })
      );
      return;
    }
    onSubmitAddLift(nextWeight);
    setShowInput(false);
  }

  function handleShowInput() {
    let nextW = 0;
    if (results.length > 0) {
      // Get the last entry in results
      const lastResult = results[results.length - 1];
      nextW = lastResult.weight;
    } else {
      const startField = getStartLiftFieldForActiveEvent(
        athlete.liftingGroup.activeEvent
      );
      // Use the startBench, startingSquat, or startingDeadlift
      // depending on the active event
      nextW = athlete[startField];
    }
    setNextWeight(nextW);
    setShowInput(true);
  }

  function handleKeyUp(event: React.KeyboardEvent) {
    if (event.key === "Enter") {
      handleSumit();
    }
  }

  return (
    <Box>
      <Collapse in={showInput} mountOnEnter={true} unmountOnExit={true}>
        <Stack direction="row" gap={1}>
          {/* TODO: Validate this */}
          <TextField
            type="number"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*", step: 5 }}
            onKeyUp={handleKeyUp}
            label="Weight"
            autoFocus={true}
            value={nextWeight}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setNextWeight(parseInt(event.target.value, 10))
            }
          />
          <Button variant="contained" onClick={handleSumit}>
            Submit
          </Button>
          <Button onClick={() => setShowInput(false)}>Cancel</Button>
        </Stack>
      </Collapse>
      <Collapse in={!showInput} mountOnEnter={true} unmountOnExit={true}>
        <Button onClick={handleShowInput}>
          Add {athlete.liftingGroup.activeEvent}
        </Button>
      </Collapse>
    </Box>
  );
}
