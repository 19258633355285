import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";
import * as React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import type { RootState } from "../../data/types";
import McService from "../../services/mc";
import type { WeightClass, FinalResults, UUID } from "../../types";
import { sortWeightClasses } from "../../utils/sortUtils";
type WeightClassResultsProps = {
  results: FinalResults;
};

function WeightClassResults({ results }: WeightClassResultsProps) {
  const columns = [
    {
      field: "place",
      headerName: "Place",
      sortable: false,
      width: 90,
    },
    {
      field: "fullName",
      headerName: "Full name",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 160,
      valueGetter: (params) =>
        `${params.row.firstName || ""} ${params.row.lastName || ""}`,
    },
    {
      field: "schoolName",
      headerName: "School",
      sortable: false,
      width: 200,
    },
    {
      field: "SQUAT",
      headerName: "Squat",
      sortable: false,
      width: 90,
      valueGetter: (params) => {
        return params.row.results.SQUAT;
      },
    },
    {
      field: "BENCH",
      headerName: "Bench",
      sortable: false,
      width: 90,
      valueGetter: (params) => {
        return params.row.results.BENCH;
      },
    },
    {
      field: "DEADLIFT",
      headerName: "Deadlift",
      sortable: false,
      width: 90,
      valueGetter: (params) => {
        return params.row.results.DEADLIFT;
      },
    },
    {
      field: "coefficient",
      headerName: "Coefficient",
      sortable: false,
      width: 90,
      valueGetter: (params) => {
        return params.row.results.coefficient.toFixed(3);
      },
    },
    {
      field: "total",
      headerName: "Total",
      sortable: false,
      width: 115,
      valueGetter: (params) => {
        return params.row.results.total;
      },
    },
  ];

  return (
    <DataGrid
      sx={{ height: 315, bgcolor: "background.paper" }}
      // @ts-ignore
      rows={results}
      disableColumnMenu={true}
      hideFooter={true}
      columns={columns}
      disableSelectionOnClick={true}
      components={{
        NoRowsOverlay: () => (
          <Stack height="100%" alignItems="center" justifyContent="center">
            No final results for this weight class.
          </Stack>
        ),
      }}
    />
  );
}

type WeightClassAccordianProps = {
  weightClass: WeightClass;
  onChange: (id: number) => React.SyntheticEvent<Element, Event>;
  expanded: UUID;
};

function WeightClassAccordian({
  weightClass,
  onChange,
  expanded,
}: WeightClassAccordianProps) {
  const { meet } = useSelector((state) => {
    return {
      meet: (state as RootState).meet.activeMeet,
    };
  });
  const [finalResultsData, setFinalResultsData] = useState([]);
  useEffect(() => {
    if (expanded === weightClass.id) {
      McService.getFinalResults(meet.id, weightClass.id).then((data) => {
        // @ts-ignore
        setFinalResultsData(data);
      });
    }
  }, [expanded, weightClass.id]);
  return (
    <Accordion
      expanded={expanded === weightClass.id}
      // @ts-ignore
      onChange={onChange(weightClass.id)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography sx={{ width: "100%", flexShrink: 0 }}>
          {weightClass.weightClassName}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {/* @ts-ignore */}
        <WeightClassResults results={finalResultsData} />
      </AccordionDetails>
    </Accordion>
  );
}

export default function FinalResultsAccordian() {
  const { meet } = useSelector((state) => {
    return {
      meet: (state as RootState).meet.activeMeet,
    };
  });
  const [weightClassData, setWeightClassData] = useState([]);

  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel: UUID) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  function getWeightClasses() {
    McService.getWeightClasses(meet.id).then((data) => {
      // @ts-ignore
      setWeightClassData(data.sort(sortWeightClasses));
    });
  }
  useEffect(() => {
    getWeightClasses();
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      {weightClassData.map((weightClass) => {
        return (
          <WeightClassAccordian
            key={weightClass.id}
            weightClass={weightClass}
            // @ts-ignore
            onChange={handleChange}
            expanded={expanded}
          />
        );
      })}
    </Box>
  );
}
