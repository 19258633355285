export function getStartLiftFieldForActiveEvent(activeEvent: string) {
  switch (activeEvent) {
    case "SQUAT":
      return "startSquat";
    case "BENCH":
      return "startBench";
    case "DEADLIFT":
      return "startDeadlift";
    default:
      return "";
  }
}

export function getBarWeightConfig() {
  return {
    validate: (v: number) => v % 5 === 0 || "Must be a multiple of 5",
  };
}
