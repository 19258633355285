import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import MeetDateGate from "./MeetDateGate";
import useAsync from "../../../customHooks/useAsync";
import type { RootState } from "../../../data/types";
import MeetService from "../../../services/meet";
import type { AthleteOrderWithResults } from "../../../types";
import CenteredCircularProgress from "../../common/CenteredCircularProgress";
import LiftOrderGrid from "../../common/LiftOrderGrid";

export default function LiftEntryGroup() {
  const [polls, setPolls] = useState(0);
  const { liftGroupId } = useParams();
  const { meetId, weightClasses } = useSelector((state) => {
    return {
      meetId: (state as RootState).meet.activeMeet.id,
      weightClasses: (state as RootState).meet.activeMeet.weightClasses,
    };
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setPolls((p) => p + 1);
    }, 5000);
    return () => clearInterval(interval);
  }, [polls]);

  const { data, isInitial } = useAsync(async () => {
    if (!meetId) {
      return null;
    }
    return {
      athletes: await MeetService.getOrderForLiftingGroup(meetId, liftGroupId),
      liftingGroup: await MeetService.getLiftingGroup(liftGroupId),
    };
  }, [liftGroupId, polls]);

  if (isInitial || !data) {
    return <CenteredCircularProgress />;
  }
  const { athletes, liftingGroup } = data;
  if (athletes.length === 0) {
    return (
      <MeetDateGate>
        <div>No Athletes remaining</div>
      </MeetDateGate>
    );
  }

  return (
    <MeetDateGate>
      <LiftOrderGrid
        liftingGroup={liftingGroup}
        athleteData={athletes}
        weightClasses={weightClasses}
        getAthleteLink={(ath: AthleteOrderWithResults) => {
          return `/meet/${meetId}/liftEntryAthlete/${ath.meet_athleteId}`;
        }}
      >
        <Typography variant="h1">{`${liftingGroup.activeEvent} - Lift Entry`}</Typography>
        <Typography variant="h3">{`${liftingGroup.liftingGroupName}`}</Typography>
        <Typography variant="body1">
          {`Total Athletes: ${athletes.length}`}
        </Typography>
      </LiftOrderGrid>
    </MeetDateGate>
  );
}
