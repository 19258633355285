/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Stack,
  Button,
  Tooltip,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import * as React from "react";
import { useSelector } from "react-redux";

import data from "../../../data";
import store from "../../../data";
import MeetActions from "../../../data/meet/actions";
import type { RootState } from "../../../data/types";
import MeetService from "../../../services/meet";
import type { LiftingGroupWithWeightClass } from "../../../types";
import { sortWeightClasses, sortLiftingGroups } from "../../../utils/sortUtils";
import CenteredCircularProgress from "../../common/CenteredCircularProgress";

import "./styles.scss";

export default function MeetManagement() {
  const activeMeet = useSelector((state) => {
    return (state as RootState).meet.activeMeet;
  });

  const liftingGroups = React.useMemo(() => {
    const lgs: LiftingGroupWithWeightClass[] = activeMeet.liftingGroup.map(
      (group) => {
        return {
          ...group,
          weightClasses: activeMeet.weightClasses.filter((weightClass) => {
            return weightClass.liftingGroup === group.id;
          }),
        };
      }
    );

    lgs.map((group) => {
      return group.weightClasses.sort(sortWeightClasses);
    });
    return lgs.sort(sortLiftingGroups);
  }, [activeMeet]);

  return (
    <DataGrid
      className="MeetManagementGrid"
      autoHeight={true}
      rows={liftingGroups || []}
      hideFooterSelectedRowCount={true}
      columns={[
        {
          field: "liftingGroupName",
          headerName: "Name",
        },
        {
          field: "weightClasses",
          headerName: "Weight Classes",
          cellClassName: "weightClassCell",
          flex: 1,
          renderCell: (params) => {
            const weightClassesStr = params.row.weightClasses
              .map((weightClass) => {
                return weightClass.weightClassName;
              })
              .join(", ");
            return (
              <Tooltip title={weightClassesStr}>
                <Stack>{weightClassesStr}</Stack>
              </Tooltip>
            );
          },
        },
        {
          field: "activeEvent",
          headerName: "Active Event",
          width: 300,
          renderCell: (params) => {
            return <ToggleEventButtons liftingGroup={params.row} />;
          },
        },
        // {
        //   field: "actions",
        //   headerName: "",
        //   width: 200,
        //   renderCell: (params) => {
        //     return (
        //       <Stack direction="row" gap={1}>
        //         <Button
        //           variant="contained"
        //           onClick={() => handleClickEdit(params.row)}
        //         >
        //           Edit
        //         </Button>
        //         <RemoveButton
        //           meetAthleteId={params.row.meet_athleteId}
        //           reload={reload}
        //           topLevelReload={topLevelReload}
        //         />
        //       </Stack>
        //     );
        //   },
        // },
      ]}
      //   components={{
      //     NoRowsOverlay: () => {
      //       if (isLoading) {
      //         return <CenteredCircularProgress />;
      //       }
      //       return (
      //         <Stack height="100%" alignItems="center" justifyContent="center">
      //           No athletes added for this weight class.
      //         </Stack>
      //       );
      //     },
      //   }}
    />
  );
}

type ToggleEventButtonsProps = {
  liftingGroup: LiftingGroupWithWeightClass;
};

function ToggleEventButtons({ liftingGroup }: ToggleEventButtonsProps) {
  const [isLoading, setIsLoading] = React.useState(false);
  async function handleChange(
    event: React.MouseEvent<HTMLElement>,
    newAlignment: Event
  ) {
    setIsLoading(true);
    await MeetService.setActiveEvent(liftingGroup.id, newAlignment);
    await store.dispatch(MeetActions.load());
    setIsLoading(false);
  }

  return isLoading ? (
    <CenteredCircularProgress />
  ) : (
    <ToggleButtonGroup
      color="primary"
      value={liftingGroup.activeEvent}
      exclusive={true}
      onChange={handleChange}
      aria-label="Platform"
    >
      <ToggleButton value="SQUAT">SQUAT</ToggleButton>
      <ToggleButton value="BENCH">BENCH</ToggleButton>
      <ToggleButton value="DEADLIFT">DEADLIFT</ToggleButton>
    </ToggleButtonGroup>
  );
}
