import Service from ".";
import type { CoachRegistrationForm } from "../components/Registration/CoachRegistration";
import type { User } from "../types";
class AuthService extends Service {
  constructor() {
    super({ path: "/Auth/AuthMain.php" });
  }

  login(email: string, password: string): Promise<User> {
    return this.post({
      task: "login",
      email,
      password,
    });
  }

  resetPassword(email: string, password: string, code: string): Promise<User> {
    return this.post({
      task: "resetPassword",
      email,
      password,
      code,
    });
  }

  forgotPassword(email: string): Promise<User> {
    return this.post({
      task: "forgotPassword",
      email,
    });
  }

  registerCoach(form: CoachRegistrationForm) {
    return this.post({
      task: "registerCoach",
      form,
    });
  }

  logout() {
    return this.post({
      task: "logout",
    });
  }

  getUser(): Promise<User> {
    return this.get({
      task: "getUser",
    });
  }
}

export default new AuthService();
