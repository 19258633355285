/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";

import type { LiftingGroupForm, WeightClassForm } from "./types";
import { Athlete } from "../../../types";

type AddEditPlatformDialogProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: (liftingGroup: LiftingGroupForm) => void;
  liftingGroup: LiftingGroupForm;
  liftingGroups: LiftingGroupForm[];
};
export function EditPlatformDialog({
  open,
  onClose,
  onSubmit,
  liftingGroup,
  liftingGroups,
}: AddEditPlatformDialogProps) {
  const isSubmitting = React.useRef(false);
  function handleFormSubmit(data: LiftingGroupForm) {
    isSubmitting.current = true;
    onSubmit(data);
    isSubmitting.current = false;
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
  } = useForm<LiftingGroupForm>({
    defaultValues: React.useMemo(() => {
      return liftingGroup;
    }, [liftingGroup]),
  });

  const resetDefaults = React.useCallback(() => {
    reset(liftingGroup);
  }, [reset, liftingGroup]);

  React.useEffect(() => {
    if (open && !isSubmitting.current) {
      resetDefaults();
    }
  }, [open, resetDefaults]);

  function handleInternalSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    handleSubmit(handleFormSubmit)(e);
    e.stopPropagation();
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <form onSubmit={handleInternalSubmit}>
        <DialogTitle>{liftingGroup ? "Edit" : "Add"} Platform</DialogTitle>

        <DialogContent
          dividers={true}
          sx={{ display: "flex", flexDirection: "column", gap: 2 }}
        >
          <TextField
            {...register("liftingGroupName", {
              required: true,
              validate: (value) => {
                const isValid = !liftingGroups.some(
                  (lg) => lg.liftingGroupName === value
                );

                if (!isValid) {
                  return "Platform names name must be unique.";
                }
                return isValid;
              },
            })}
            error={!!errors.liftingGroupName}
            label="Name"
            required={true}
            fullWidth={true}
            helperText={
              errors.liftingGroupName ? errors.liftingGroupName?.message : ""
            }
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">Save</Button>
          <Button onClick={onClose}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
