/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Collapse, Stack, TextField, Typography } from "@mui/material";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";

import store from "../../data";
import AppActions from "../../data/app/actions";
import AuthService from "../../services/auth";

export type CoachRegistrationForm = {
  firstName: string;
  lastName: string;
  schoolName: string;
  mascot: string;
  schoolAbrev: string;
  email: string;
  password: string;
};

export function CoachRegistration() {
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
    setError,
  } = useForm<CoachRegistrationForm>({
    defaultValues: {},
  });

  async function onSubmit(data: CoachRegistrationForm) {
    try {
      setIsLoading(true);
      await AuthService.registerCoach(data);

      setIsLoading(false);
      setIsSuccess(true);
    } catch (error) {
      if (error.message) {
        if (
          error.message ===
            "Password did not conform with policy: Password not long enough" ||
          error.message ===
            "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$"
        ) {
          setError("password", {
            type: "manual",
            message:
              "Password must be at least 5 characters long, and may only contain letters, numbers, and the following special characters: !@#$%^&*()_+",
          });
          return;
        }

        if (error.message === "Username/Email already taken.") {
          setError("email", {
            type: "manual",
            message: "Email already taken.",
          });
          return;
        }

        console.log(error.message);
      }
      await store.dispatch(
        AppActions.setSnackBar({
          text: "An error occurred while registering. Please try again. If the problem persists, please contact the site admin for help.",
          severity: "error",
          open: true,
        })
      );
    }
  }

  return (
    <Stack my={4} gap={2}>
      <Typography variant="h2">Coach Registration</Typography>
      <Collapse in={isSuccess}>
        <Typography variant="body1">
          Thank you for registering. You will receive a verification email
          shortly. Please check your email and follow the instructions to
          complete your registration.
        </Typography>
      </Collapse>
      <Collapse
        component="form"
        in={!isSuccess}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Stack gap={2}>
          <Typography variant="body1">
            At this point registration is only open for coaches
          </Typography>
          <TextField
            {...register("firstName", { required: true })}
            error={!!errors.firstName}
            required={true}
            label="First Name"
          />
          <TextField
            {...register("lastName", { required: true })}
            error={!!errors.lastName}
            required={true}
            label="Last Name"
          />

          <TextField
            autoComplete="email"
            {...register("email", { required: true })}
            error={!!errors.email}
            required={true}
            label="Email"
            helperText={errors.email?.message}
          />
          <TextField
            autoComplete="schoolName"
            {...register("schoolName", { required: true })}
            error={!!errors.schoolName}
            required={true}
            label="School Name"
          />
          <TextField
            autoComplete="mascot"
            {...register("mascot", { required: true })}
            error={!!errors.mascot}
            required={true}
            label="Mascot"
          />
          <TextField
            autoComplete="schoolAbrev"
            {...register("schoolAbrev", { required: true })}
            error={!!errors.schoolAbrev}
            required={true}
            label="School Abreviation"
          />
          <TextField
            autoComplete="new-password"
            type="password"
            {...register("password", { required: true })}
            error={!!errors.password}
            required={true}
            label="Password"
            helperText={errors.password?.message}
          />
          <Stack direction="row" justifyContent="flex-end" gap={2}>
            <Button variant="contained" type="submit">
              Submit
            </Button>
          </Stack>
        </Stack>
      </Collapse>
    </Stack>
  );
}
