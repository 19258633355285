import Requests from "../utils/requests";

type ServiceProps = {
  path: string;
};

class Service {
  path: string;

  constructor({ path }: ServiceProps) {
    let baseUrl = "https://lynxpowerlifting.com";
    if (process.env.REACT_APP_BASE_API_URL) {
      baseUrl = process.env.REACT_APP_BASE_API_URL;
    }
    this.path = baseUrl + path;
  }

  get<RequestDataType, ResponseDataType>(
    data?: RequestDataType
  ): Promise<ResponseDataType> {
    let queryString = "";
    if (typeof data === "object") {
      queryString = "?params=" + JSON.stringify(data);
    }
    return Requests.get(this.path + queryString);
  }

  post<RequestDataType, ResponseDataType>(
    data?: RequestDataType
  ): Promise<ResponseDataType> {
    return Requests.post(this.path, data);
  }
}

export default Service;
