import { Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

import type { RootState } from "../../../data/types";

type MeetDateGateProps = {
  children: React.ReactNode;
};

export default function MeetDateGate({ children }: MeetDateGateProps) {
  const { meet } = useSelector((state) => {
    return {
      meet: (state as RootState).meet,
    };
  });

  if (!meet.activeMeet) {
    return <div>No active meet</div>;
  }

  const meetDate = new Date(meet.activeMeet.meetDate);
  const dateNow = new Date();

  const beforeMeetThresholdDate = new Date(meetDate.getTime() - 3600000 * 2);

  const afterMeetThresholdDate = new Date(meetDate.getTime() + 86400000);

  if (dateNow < beforeMeetThresholdDate) {
    return (
      <>
        <Typography variant="h2">
          The meet is not open for entry at this time.
        </Typography>
        <Typography variant="h3">
          Please check back at {beforeMeetThresholdDate.toLocaleString()}
        </Typography>
        <Typography variant="body1">
          Please contact the site admin if you believe this is an error.
        </Typography>
      </>
    );
  }

  if (dateNow > afterMeetThresholdDate) {
    return (
      <>
        <Typography variant="h2">
          The meet has already been completed.
        </Typography>
        <Typography variant="body1">
          Please contact the site admin if you believe this is an error.
        </Typography>
      </>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
