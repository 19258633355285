import { Stack } from "@mui/material";
import Button from "@mui/material/Button";
import * as React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import type { RootState } from "../../data/types";
import { UserTypes } from "../../types";
import MeetsGrid from "../Meets/MeetsGrid";
export default function Meets() {
  const user = useSelector((state) => {
    return (state as RootState).app.user;
  });

  const onlyUsersMeets = user?.userType === UserTypes.COACH;

  return (
    <Stack height="100%" gap={3}>
      {user?.userType === UserTypes.COACH ||
      user?.userType === UserTypes.ADMIN ? (
        <Link to="/create-meet">
          <Button variant="contained">Create New Meet</Button>
        </Link>
      ) : null}
      <MeetsGrid sx={{ flex: 1 }} onlyUsersMeets={onlyUsersMeets} />
    </Stack>
  );
}
