/* eslint-disable @typescript-eslint/no-unused-vars */
import { Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import * as React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import useAsync from "../../../customHooks/useAsync";
import type { RootState } from "../../../data/types";
import MeetService from "../../../services/meet";

export default function MeetTeams() {
  const user = useSelector((state) => {
    return (state as RootState).app.user;
  });
  const meet = useSelector((state) => {
    return (state as RootState).meet.activeMeet;
  });
  const {
    data: meetUsers,
    isInitial,
    reload,
  } = useAsync(() => {
    return MeetService.getTeams(meet.id);
  }, [meet.id]);

  return (
    <Stack gap={1}>
      <DataGrid
        loading={isInitial}
        autoHeight={true}
        rows={meetUsers || []}
        columns={[
          { field: "schoolName", headerName: "School", width: 200 },
          {
            field: "teamRoster",
            headerName: "Team Roster",
            width: 200,
            renderCell: (params) => (
              <Link to={`/coach/teamManagement/${params.row.schoolsId}`}>
                Team Roster
              </Link>
            ),
          },
          {
            field: "meetRoster",
            headerName: "Meet Roster",
            width: 200,
            renderCell: (params) => (
              <Link to={`/meet-roster/${meet.id}/${params.row.schoolsId}`}>
                Meet Roster
              </Link>
            ),
          },
        ]}
      />
    </Stack>
  );
}
