/* eslint-disable @typescript-eslint/no-unused-vars */
import { Stack, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";
import * as React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import useAsync from "../../customHooks/useAsync";
import type { RootState } from "../../data/types";
import MeetService from "../../services/meet";

export default function AllAthletes() {
  const [filterNameValue, setFilterNameValue] = React.useState("");
  const activeMeet = useSelector((state) => {
    return (state as RootState).meet.activeMeet;
  });

  const { data, isInitial } = useAsync(async () => {
    if (!activeMeet) {
      return null;
    }
    const aths = await MeetService.getAllAthletes(activeMeet.id);
    return aths.sort((a, b) => {
      if (a.lastName < b.lastName) {
        return -1;
      }
      if (a.lastName > b.lastName) {
        return 1;
      }

      if (a.firstName < b.firstName) {
        return -1;
      }
      if (a.firstName > b.firstName) {
        return 1;
      }
      return 0;
    });
  }, [activeMeet.id]);

  function onFilterChange(e: React.ChangeEvent<HTMLInputElement>) {
    setFilterNameValue(e.target.value);
  }

  const filteredAthleteData = (data || []).filter((a) => {
    const lowerFilter = filterNameValue.toLowerCase();
    if (lowerFilter === "") {
      return true;
    }
    const firstName = a.firstName.toLowerCase();
    const lastName = a.lastName.toLowerCase();

    if (firstName.includes(lowerFilter) || lastName.includes(lowerFilter)) {
      return true;
    }

    return false;
  });

  const columns = [
    {
      field: "lastName",
      headerName: "Name",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <Link
          to={`/meet/${activeMeet.id}/athlete/${params.row.meet_athleteId}`}
        >{`${params.row.lastName}, ${params.row.firstName}`}</Link>
      ),
    },
    {
      field: "school",
      headerName: "School",
      minWidth: 80,
      valueGetter: (params) =>
        params.row.schoolAbrev ? params.row.schoolAbrev : params.row.schoolName,
    },
    {
      field: "liftingGroup",
      headerName: "Platform",
      minWidth: 120,
      valueGetter: (params) => params.row?.liftingGroup?.liftingGroupName,
    },
    {
      field: "startSquat",
      headerName: "1st Squat",
    },
    {
      field: "startBench",
      headerName: "1st Bench",
    },
    {
      field: "startDeadlift",
      headerName: "1st Deadlift",
    },
    {
      field: "pinHeightSquat",
      headerName: "Squat Pin Height",
    },
    {
      field: "pinHeightBench",
      headerName: "Bench Pin Height",
    },
  ];

  return (
    <Stack gap={1} flex={1} mb={2}>
      <Typography variant="h1">All Athletes</Typography>
      <TextField
        margin="dense"
        name="filterName"
        label="Search by Name"
        type="text"
        value={filterNameValue}
        onChange={onFilterChange}
      />
      <DataGrid
        sx={{
          bgcolor: "background.paper",
        }}
        rows={filteredAthleteData}
        columns={columns}
        components={{
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              No athletes available.
            </Stack>
          ),
        }}
      />
    </Stack>
  );
}
