/* eslint-disable @typescript-eslint/no-unused-vars */
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Collapse,
  MenuItem,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import useAsync from "../../customHooks/useAsync";
import type { RootState } from "../../data/types";
import MeetService from "../../services/meet";
import type { Event, MeetAthlete, MeetConfig, Result, UUID } from "../../types";
import { LIFTS, LiftSuccess } from "../../types";
import CenteredCircularProgress from "../common/CenteredCircularProgress";
import DisplayGrid from "../common/DisplayGrid";
import { getBarWeightConfig } from "../common/utils";

function displayDateOrTime(dateStr?: string | Date) {
  const date = dateStr ? new Date(dateStr) : new Date();
  const currentTimeStamp = new Date().getTime();

  if (date.getTime() > currentTimeStamp + 1000 * 60 * 60 * 24) {
    return date.toLocaleString();
  }

  return date.toLocaleTimeString();
}

function generateFakeUUID() {
  return Math.random().toString(36).substring(2) + Date.now().toString(36);
}

export default function EditAthletePage() {
  const { meetAthleteId } = useParams();
  const { data: athlete, isInitial } = useAsync(() => {
    if (!meetAthleteId) {
      return null;
    }
    return MeetService.getMeetAthlete(meetAthleteId, false);
  }, [meetAthleteId]);

  if (isInitial || !athlete) {
    return <CenteredCircularProgress />;
  }

  return <EditAthletePageForm athlete={athlete} />;
}

type EditableResult = Result & {
  isEditing: boolean;
  isNew?: boolean;
};

type EditAthletePageFormProps = {
  athlete: MeetAthlete;
};

function EditAthletePageForm({ athlete }: EditAthletePageFormProps) {
  const activeMeet = useSelector((state) => {
    return (state as RootState).meet.activeMeet;
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
  } = useForm<MeetAthlete>({
    defaultValues: React.useMemo(() => athlete, [athlete]),
  });

  const navigate = useNavigate();

  console.log(athlete);

  async function onSubmit(data: MeetAthlete) {
    setIsLoading(true);

    console.log(addedResults);

    await MeetService.updateAthleteAndResults(
      {
        id: data.meet_athleteId,
        bodyWeight: data.bodyWeight,
        weightClass: data.weightClass as UUID,
        startSquat: data.startSquat,
        startBench: data.startBench,
        startDeadlift: data.startDeadlift,
        pinHeightSquat: data.pinHeightSquat,
        pinHeightBench: data.pinHeightBench,
      },
      editResults,
      deleteResults,
      addedResults
    );

    setIsLoading(false);

    navigate(`/meet/${activeMeet.id}/admin/athletes`);
  }

  const [editResults, setResults] = React.useState(
    athlete.results.map((r) => {
      return { ...r, isEditing: false };
    })
  );

  const [deleteResults, setDeleteResults] = React.useState<EditableResult[]>(
    []
  );
  const [addedResults, setAddedResults] = React.useState<EditableResult[]>([]);

  function handleAddResult(event: Event) {
    setAddedResults([
      // @ts-ignore
      ...addedResults,
      // @ts-ignore
      {
        id: generateFakeUUID(),
        athlete: athlete.id,
        meet: activeMeet.id,
        event: event,
        weight: 0,
        liftSuccess: LiftSuccess.NA,
        isEditing: true,
        isNew: true,
      },
    ]);
  }

  function handleDeleteResult(result: EditableResult) {
    setResults(editResults.filter((r) => r.id !== result.id));
    setAddedResults(addedResults.filter((r) => r.id !== result.id));
    if (!result.isNew) {
      setDeleteResults([...deleteResults, result]);
    }
  }

  function handleEnableEdit(result: Result) {
    setResults(
      editResults.map((r) => {
        if (r.id === result.id) {
          return { ...r, isEditing: true };
        }
        return r;
      })
    );
    setAddedResults(
      addedResults.map((r) => {
        if (r.id === result.id) {
          return { ...r, isEditing: true };
        }
        return r;
      })
    );
  }

  function handleChangeLiftSuccess(
    result: EditableResult,
    liftSuccess: LiftSuccess
  ) {
    setResults(
      editResults.map((r) => {
        if (r.id === result.id) {
          return { ...r, liftSuccess };
        }
        return r;
      })
    );
    setAddedResults(
      addedResults.map((r) => {
        if (r.id === result.id) {
          return { ...r, liftSuccess };
        }
        return r;
      })
    );
  }

  function handleChangeWeight(result: EditableResult, weight: number) {
    setResults(
      editResults.map((r) => {
        if (r.id === result.id) {
          return { ...r, weight };
        }
        return r;
      })
    );

    setAddedResults(
      addedResults.map((r) => {
        if (r.id === result.id) {
          return { ...r, weight };
        }
        return r;
      })
    );
  }

  const allResults = React.useMemo(() => {
    return [...editResults, ...addedResults];
  }, [editResults, addedResults]);

  console.log("allResults", allResults);

  return (
    <Stack>
      <Typography variant="h1">{`Edit ${athlete.lastName}, ${athlete.firstName}`}</Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="column" gap={3}>
          <TextField
            {...register("bodyWeight", { required: true })}
            error={!!errors.baseBodyWeight}
            label="Body Weight"
            required={true}
            type="number"
            inputProps={{ inputMode: "decimal" }}
          />
          <Controller
            name="weightClass"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth={true}
                select={true}
                label="Weight Class"
                error={!!errors.gender}
                required={true}
                helperText={errors.gender ? errors.gender?.message : ""}
              >
                {activeMeet.weightClasses.map((weightClass) => (
                  <MenuItem key={weightClass.id} value={weightClass.id}>
                    {weightClass.weightClassName}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <TextField
            {...register("startSquat", {
              required: true,
              ...getBarWeightConfig(),
            })}
            error={!!errors.startSquat}
            helperText={errors.startSquat ? errors.startSquat?.message : ""}
            label="1st Squat"
            required={true}
            type="number"
            fullWidth={true}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*", step: 5 }}
          />
          <TextField
            {...register("startBench", {
              required: true,
              ...getBarWeightConfig(),
            })}
            error={!!errors.startBench}
            helperText={errors.startBench ? errors.startBench?.message : ""}
            label="1st Bench"
            required={true}
            type="number"
            fullWidth={true}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*", step: 5 }}
          />
          <TextField
            {...register("startDeadlift", {
              required: true,
              ...getBarWeightConfig(),
            })}
            error={!!errors.startDeadlift}
            helperText={
              errors.startDeadlift ? errors.startDeadlift?.message : ""
            }
            label="1st Deadlift"
            required={true}
            type="number"
            fullWidth={true}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*", step: 5 }}
          />
          <TextField
            {...register("pinHeightSquat", { required: true })}
            error={!!errors.pinHeightSquat}
            label="Squat Pin Height"
            required={true}
            type="number"
            fullWidth={true}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          />
          <TextField
            {...register("pinHeightBench", { required: true })}
            error={!!errors.pinHeightBench}
            label="Bench Pin Height"
            required={true}
            type="number"
            fullWidth={true}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          />
          <Stack gap={2}>
            {LIFTS.map((lift) => {
              return (
                <ResultsGrid
                  key={lift}
                  activeMeet={activeMeet}
                  onEnableEdit={handleEnableEdit}
                  onDeleteResult={handleDeleteResult}
                  onChangeLiftSuccess={handleChangeLiftSuccess}
                  onChangeWeight={handleChangeWeight}
                  onClickAddResult={handleAddResult}
                  event={lift as Event}
                  results={allResults.filter((r) => r.event === lift)}
                />
              );
            })}
          </Stack>
          <Stack direction="row">
            <Link to={`/meet/${activeMeet.id}/admin/athletes`}>
              <Button variant="outlined">Cancel</Button>
            </Link>
            <LoadingButton
              variant="contained"
              loading={isLoading}
              type="submit"
            >
              Submit
            </LoadingButton>
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
}

type ResultsGridProps = {
  activeMeet: MeetConfig;
  event: Event;
  results: EditableResult[];
  onEnableEdit: (result: Result) => void;
  onDeleteResult: (result: Result) => void;
  onChangeLiftSuccess: (result: Result, liftSuccess: LiftSuccess) => void;
  onChangeWeight: (result: Result, weight: number) => void;
  onClickAddResult: (event: Event) => void;
};

function ResultsGrid({
  activeMeet,
  event,
  results,
  onEnableEdit,
  onDeleteResult,
  onChangeLiftSuccess,
  onChangeWeight,
  onClickAddResult,
}: ResultsGridProps) {
  return (
    <Stack>
      <Typography variant="h4">{event}</Typography>
      <DisplayGrid
        data={results}
        fullHeight={false}
        emptyComponent={
          <Typography variant="subtitle2" color="grey" px={2}>
            No results added for this event.
          </Typography>
        }
        columns={[
          {
            label: "Attempt",
            isNumber: true,
            width: 100,
            renderer: (r, index) => index + 1,
          },
          {
            label: "Weight",
            isNumber: true,
            renderer: (r) => {
              if (r.isEditing) {
                return (
                  <TextField
                    type="number"
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      step: 5,
                    }}
                    autoFocus={true}
                    value={r.weight}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      onChangeWeight(r, parseInt(e.target.value, 10))
                    }
                  />
                );
              }
              return r.weight;
            },
          },
          {
            label: "Lift Result",
            renderer: (r) => {
              if (r.isEditing) {
                return (
                  <ToggleButtonGroup
                    color="primary"
                    value={r.liftSuccess}
                    exclusive={true}
                    onChange={(e, newLiftSuccess) =>
                      onChangeLiftSuccess(r, newLiftSuccess)
                    }
                    aria-label="Platform"
                  >
                    <ToggleButton color="success" value="TRUE">
                      Success
                    </ToggleButton>
                    <ToggleButton color="error" value="FALSE">
                      Failed
                    </ToggleButton>
                    <ToggleButton value={null}>Clear</ToggleButton>
                  </ToggleButtonGroup>
                );
              }

              if (r.liftSuccess === "TRUE") {
                return (
                  <Typography variant="body1" color="success.main">
                    SUCCESS
                  </Typography>
                );
              }

              if (r.liftSuccess === "FALSE") {
                return (
                  <Typography variant="body1" color="error.main">
                    FAILED
                  </Typography>
                );
              }

              return (
                <Typography variant="body1" color="primary.main">
                  N/A
                </Typography>
              );
            },
          },
          {
            label: "Entry Time",
            isNumber: true,
            width: 100,
            renderer: (r, index) => displayDateOrTime(r.entryTime),
          },
          {
            label: "Completion Time",
            isNumber: true,
            width: 140,
            renderer: (r, index) => displayDateOrTime(r.completionTime),
          },
          {
            label: "Actions",
            renderer: (r) => {
              return (
                <>
                  {!r.isEditing ? (
                    <Button variant="contained" onClick={() => onEnableEdit(r)}>
                      Edit
                    </Button>
                  ) : null}

                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => onDeleteResult(r)}
                  >
                    Delete
                  </Button>
                </>
              );
            },
          },
        ]}
      />
      <Collapse
        in={results.length < activeMeet.liftAttempts}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <Button
          variant="contained"
          onClick={() => {
            onClickAddResult(event);
          }}
        >
          Add {event}
        </Button>
      </Collapse>
    </Stack>
  );
}
