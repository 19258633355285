/* eslint-disable @typescript-eslint/no-unused-vars */
import { Pending } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import { Button, Stack, Typography } from "@mui/material";
import * as React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import useAsync from "../../customHooks/useAsync";
import type { RootState } from "../../data/types";
import MeetService from "../../services/meet";
import type { LiftingGroup, Result } from "../../types";
import { type School, type WeightClass, type Event } from "../../types";
import CenteredCircularProgress from "../common/CenteredCircularProgress";
import DisplayGrid from "../common/DisplayGrid";

export default function MeeteAthletePage() {
  const theme = useTheme();
  const { meetAthleteId } = useParams();
  const activeMeet = useSelector((state) => {
    return (state as RootState).meet.activeMeet;
  });

  const { data, isInitial } = useAsync(() => {
    if (!activeMeet) {
      return null;
    }
    return MeetService.getAthlete(activeMeet.id, meetAthleteId);
  }, [activeMeet.id]);

  if (isInitial) {
    return <CenteredCircularProgress />;
  }

  return (
    <Stack gap={2} my={2}>
      <Typography variant="h1">{`${data.firstName} ${data.lastName}`}</Typography>
      <Stack direction="column" gap={1}>
        <Typography variant="h3" color={theme.palette.text.secondary}>
          {(data.school as School)?.schoolName}
        </Typography>
        <Typography variant="h3" color={theme.palette.text.secondary}>
          Weight Class:{" "}
          <Link
            to={`/meet/${activeMeet.id}/results/weightClass/${
              (data.weightClass as WeightClass).id
            }`}
          >
            {(data.weightClass as WeightClass).weightClassName}
          </Link>
        </Typography>
        <Typography variant="h3" color={theme.palette.text.secondary}>
          Platform:{" "}
          <Link
            to={`/meet/${activeMeet.id}/liftOrder/${
              (data.liftingGroup as LiftingGroup).id
            }`}
          >
            {(data.liftingGroup as LiftingGroup).liftingGroupName}
          </Link>
        </Typography>
      </Stack>
      <Stack gap={3}>
        <AthleteResultsTable results={data.results} eventType="SQUAT" />
        <AthleteResultsTable results={data.results} eventType="BENCH" />
        <AthleteResultsTable results={data.results} eventType="DEADLIFT" />
      </Stack>
    </Stack>
  );
}

type AthleteResultsTableProps = {
  results: Result[];
  eventType: Event;
};

function AthleteResultsTable({ results, eventType }: AthleteResultsTableProps) {
  const filteredResults = results
    .filter((r) => r.event === eventType)
    .sort((a, b) => {
      return new Date(a.entryTime).getTime() - new Date(b.entryTime).getTime();
    });

  // Add a number of pending rows to make there a total of 3 rows
  for (let i = 0; filteredResults.length < 3; i++) {
    filteredResults.push({
      id: `pending-${i}`,
      meet: "pending",
      athlete: "pending",
      liftSuccess: null,
      weight: 0,
      event: "",
      entryTime: new Date(),
      completionTime: new Date(),
    });
  }

  return (
    <Stack>
      <Typography variant="h4">{eventType}</Typography>
      <DisplayGrid
        data={filteredResults}
        fullHeight={false}
        columns={[
          {
            label: "Attempt",
            isNumber: true,
            width: 100,
            renderer: (r, index) => index + 1,
          },
          {
            label: "Weight",
            isNumber: true,
            renderer: (r) => {
              if (!r.liftSuccess) {
                return "Pending...";
              }
              return r.weight;
            },
          },
          {
            label: "Lift Result",
            renderer: (r) => {
              if (!r.liftSuccess) {
                return "N/A";
              }
              return r.liftSuccess === "TRUE" ? (
                <Typography color="success.main">SUCCESS</Typography>
              ) : (
                <Typography color="error.main">FAIL</Typography>
              );
            },
          },
        ]}
      />
    </Stack>
  );
}
