import type { TabProps } from "@mui/material";
import { Tab } from "@mui/material";
import React from "react";
import type { ReactElement } from "react";
import { Link } from "react-router-dom";
// import { useLocation } from "react-router-dom";

export type LinkTabProps = TabProps & { href: string };

export default function LinkTab(props: LinkTabProps): ReactElement {
  return (
    <Link to={props.href}>
      <Tab component="span" {...props} />
    </Link>
  );
}
