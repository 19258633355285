import Typography from "@mui/material/Typography";
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import LiftEntryAthleteGrid from "./LiftEntryAthleteGrid";
import MeetDateGate from "./MeetDateGate";
import useAsync from "../../../customHooks/useAsync";
import type { RootState } from "../../../data/types";
import MeetService from "../../../services/meet";
import AthleteMeetHeader from "../../common/AthleteMeetHeader";
import CenteredCircularProgress from "../../common/CenteredCircularProgress";

export default function LiftEntryAthlete() {
  const { meetAthleteId } = useParams();
  const { weightClasses } = useSelector((state) => {
    return {
      weightClasses: (state as RootState).meet.activeMeet.weightClasses,
    };
  });

  const { data: athlete, isInitial } = useAsync(() => {
    if (!meetAthleteId) {
      return null;
    }
    return MeetService.getMeetAthlete(meetAthleteId, true);
  }, [meetAthleteId]);

  if (isInitial || !athlete) {
    return <CenteredCircularProgress />;
  }

  return (
    <MeetDateGate>
      <AthleteMeetHeader athlete={athlete} weightClasses={weightClasses} />
      <Typography variant="h4" sx={{ mt: 2 }}>
        {athlete.liftingGroup.activeEvent}
      </Typography>
      <LiftEntryAthleteGrid athlete={athlete} />
    </MeetDateGate>
  );
}
