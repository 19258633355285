/* eslint-disable @typescript-eslint/no-unused-vars */
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { DataGrid } from "@mui/x-data-grid";
import * as React from "react";
import { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import DeleteAthleteDialog from "./DeleteAthleteDialog";
import EditAthleteDialog from "./EditAthleteDialog";
import type { RootState } from "../../../data/types";
import MeetService from "../../../services/meet";
import type { Athlete, MeetAthlete } from "../../../types";

export default function AthletesGrid() {
  const [athleteData, setAthleteData] = useState<MeetAthlete[]>([]);
  const [filteredAthleteData, setFilteredAthleteData] = useState<MeetAthlete[]>(
    []
  );
  const [selectedAthlete, setSelectedAthlete] = useState<MeetAthlete>(null);
  const [isEditVisible, setEditVisible] = useState(false);
  const [isDeleteVisible, setDeleteVisible] = useState(false);
  const [filterNameValue, setFilterNameValue] = useState("");
  const [isLoading, setLoading] = useState(false);
  const activeMeet = useSelector((state) => {
    return (state as RootState).meet.activeMeet;
  });
  const getAthletes = useCallback(async () => {
    const athletes = await MeetService.getAllAthletesAndResults(activeMeet.id);
    const atheData = athletes.map((a) => ({ ...a, id: a.meet_athleteId }));
    setAthleteData(atheData);
    setFilteredAthleteData(atheData);

    setLoading(false);
  }, [activeMeet.id]);

  const filterByName = useCallback(
    (name: string) => {
      name = name.toLowerCase();

      const data = athleteData.filter((a) => {
        if (name === "") {
          return true;
        }
        const firstName = a.firstName.toLowerCase();
        const lastName = a.lastName.toLowerCase();

        if (firstName.includes(name) || lastName.includes(name)) {
          return true;
        }

        return false;
      });
      setFilteredAthleteData(data);
    },
    [athleteData]
  );

  useEffect(() => {
    setLoading(true);
    getAthletes();
    // const timer = setInterval(() => {
    //   getAthletes();
    // }, 3000);

    // return () => clearTimeout(timer);
  }, [getAthletes]);

  useEffect(() => {
    if (isLoading === false) {
      filterByName(filterNameValue);
    }
  }, [filterByName, filterNameValue, isLoading]);

  function onFilterChange(e: React.ChangeEvent<HTMLInputElement>) {
    setFilterNameValue(e.target.value);
    filterByName(e.target.value);
  }

  function deleteAthlete(athlete: Athlete) {
    const data = athleteData.filter((a) => {
      if (athlete.id === a.id) {
        return false;
      }

      return true;
    });

    const filteredData = filteredAthleteData.filter((a) => {
      if (athlete.id === a.id) {
        return false;
      }

      return true;
    });

    setAthleteData(data);
    setFilteredAthleteData(filteredData);
  }

  async function editAthlete(athlete: Athlete) {
    setLoading(true);
    await getAthletes();
  }

  const columns = [
    {
      field: "lastName",
      headerName: "Name",
      minWidth: 150,
      flex: 1,
      valueGetter: (params) =>
        `${params.row.lastName}, ${params.row.firstName}`,
    },
    {
      field: "gender",
      headerName: "Gender",
      width: 70,
    },
    {
      field: "school",
      headerName: "School",
      width: 80,
      valueGetter: (params) =>
        params.row.schoolAbrev ? params.row.schoolAbrev : params.row.schoolName,
    },
    {
      field: "weightClass",
      headerName: "Weight Class",
      width: 120,
      valueGetter: (params) => {
        const foundWc = activeMeet.weightClasses.find((wc) => {
          return params.row.weightClass === wc.id;
        });
        return foundWc?.weightClassName;
      },
    },
    {
      field: "bodyWeight",
      headerName: "Weight",
      width: 75,
    },
    {
      field: "startSquat",
      headerName: "1st Squat",
      sortable: true,
      width: 90,
    },
    {
      field: "startBench",
      headerName: "1st Bench",
      sortable: true,
      width: 90,
    },
    {
      field: "startDeadlift",
      headerName: "1st Deadlift",
      sortable: true,
      width: 115,
    },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      width: 100,
      renderCell: (params) => {
        const onClickEdit = (e) => {
          e.stopPropagation();

          const athlete = filteredAthleteData.find((a) => {
            if (a.id === params.id) {
              return true;
            }
            return false;
          });

          setSelectedAthlete(athlete);
          setEditVisible(true);
          return;
        };

        const onClickDelete = (e) => {
          e.stopPropagation();

          const athlete = filteredAthleteData.find((a) => {
            if (a.id === params.id) {
              return true;
            }
            return false;
          });

          setSelectedAthlete(athlete);
          setDeleteVisible(true);
          return;
        };

        return (
          <>
            <Link
              to={`/meet/${activeMeet.id}/editAthlete/${params.row.meet_athleteId}`}
            >
              <Button variant="contained">Edit</Button>
            </Link>
            {/* <Button variant="contained" color="error" onClick={onClickDelete}>
              Delete
            </Button> */}
          </>
        );
      },
    },
  ];
  return (
    <>
      <TextField
        margin="dense"
        name="filterName"
        label="Filter by Name"
        type="text"
        onChange={onFilterChange}
      />
      <DataGrid
        sx={{
          bgcolor: "background.paper",
        }}
        rows={filteredAthleteData}
        // hideFooterPagination={true}
        columns={columns}
        components={{
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              No athletes available.
            </Stack>
          ),
        }}
      />
      {/* <DeleteAthleteDialog
        onSuccess={deleteAthlete}
        open={isDeleteVisible}
        onClose={() => {
          setDeleteVisible(false);
        }}
        athlete={selectedAthlete}
      /> */}
      {isEditVisible && (
        <EditAthleteDialog
          onSuccess={editAthlete}
          open={isEditVisible}
          onClose={() => {
            setEditVisible(false);
          }}
          athlete={selectedAthlete}
        />
      )}
    </>
  );
}
