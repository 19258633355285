import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";

import store from "../../data";
import AppActions from "../../data/app/actions";
import type { RootState } from "../../data/types";
import AuthService from "../../services/auth";
import type { User } from "../../types";
import Login from "../Login";
import NavMenu from "../NavMenu";

type LoggedInContentProps = {
  user: User;
};

function LoggedInContent({ user }: LoggedInContentProps) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }
  function handleClose() {
    setAnchorEl(null);
  }

  async function handleLogOut() {
    await AuthService.logout();
    await store.dispatch(AppActions.setUser(null));
    handleClose();
  }

  return (
    <>
      <Button onClick={handleClick} color="inherit">
        User
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem>Logged in as {user.username}</MenuItem>
        {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem> */}
        <MenuItem onClick={handleLogOut}>Logout</MenuItem>
      </Menu>
    </>
  );
}

export default function TopBar() {
  const [isNavVisible, setNavVisible] = useState(false);
  const [isLoginVisible, setLoginVisible] = useState(false);

  const user = useSelector((state) => {
    return (state as RootState).app.user;
  });

  function LoginContent() {
    return (
      <>
        <Button
          onClick={() => {
            setLoginVisible(true);
          }}
          color="inherit"
        >
          Log In
        </Button>
        <Login
          open={isLoginVisible}
          onClose={() => {
            setLoginVisible(false);
          }}
        />
      </>
    );
  }

  return (
    <>
      <NavMenu
        openMenu={isNavVisible}
        onClose={() => {
          setNavVisible(false);
        }}
        onSubMenuBack={() => {
          setNavVisible(true);
        }}
      />
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => {
              setNavVisible(true);
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            LiftMeet
          </Typography>
          {user != null ? <LoggedInContent user={user} /> : <LoginContent />}
        </Toolbar>
      </AppBar>
    </>
  );
}
