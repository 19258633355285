/* eslint-disable @typescript-eslint/no-unused-vars */
import ArrowBack from "@mui/icons-material/ArrowBack";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Links } from "./Links";
import type { RootState } from "../../data/types";
import { UserTypes } from "../../types";
import type { LiftingGroupWithWeightClass, MeetConfig } from "../../types";
import "./styles.scss";
import { sortWeightClasses, sortLiftingGroups } from "../../utils/sortUtils";

type ResultsMenuProps = {
  activeMeet: MeetConfig;
  onClose: () => void;
};
function ResultsMenu({ activeMeet, onClose }: ResultsMenuProps) {
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  const weightClasses = activeMeet.weightClasses.sort(sortWeightClasses);
  return (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon />
        <ListItemText primary="Results" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit={true}>
        <List component="div" disablePadding={true}>
          {weightClasses.map((weightClass) => (
            <Link
              key={weightClass.id}
              to={`/meet/${activeMeet.id}/results/weightClass/${weightClass.id}`}
            >
              <ListItemButton onClick={onClose} sx={{ pl: 4 }}>
                <ListItemIcon />
                <ListItemText primary={weightClass.weightClassName} />
              </ListItemButton>
            </Link>
          ))}
        </List>
      </Collapse>
    </>
  );
}

type LiftOrderMenuProps = {
  liftingGroups: LiftingGroupWithWeightClass[];
  activeMeet: MeetConfig;
  onClose: () => void;
  isHeadJudge?: boolean;
};
function LiftOrderMenu({
  liftingGroups,
  activeMeet,
  onClose,
  isHeadJudge,
}: LiftOrderMenuProps) {
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon />
        <ListItemText primary={isHeadJudge ? "Head Judge" : "Lift Order"} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit={true}>
        <List component="div" disablePadding={true}>
          {liftingGroups.map((liftGroup) => (
            <Link
              key={liftGroup.id}
              to={`/meet/${activeMeet.id}/liftOrder/${liftGroup.id}${
                isHeadJudge ? "/headJudge" : ""
              }`}
            >
              <ListItemButton onClick={onClose} sx={{ pl: 4 }}>
                <ListItemIcon />
                <ListItemText primary={liftGroup.liftingGroupName} />
              </ListItemButton>
            </Link>
          ))}
        </List>
      </Collapse>
    </>
  );
}

type LiftEntryMenuProps = {
  liftingGroups: LiftingGroupWithWeightClass[];
  activeMeet: MeetConfig;
  onClose: () => void;
};
function LiftEntryMenu({
  liftingGroups,
  activeMeet,
  onClose,
}: LiftEntryMenuProps) {
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon />
        <ListItemText primary="Lift Entry" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit={true}>
        <Link to={`/meet/${activeMeet.id}/nextLiftEntry`}>
          <ListItemButton onClick={onClose} sx={{ pl: 4 }}>
            <ListItemIcon />
            <ListItemText primary="Next Lift Entry" />
          </ListItemButton>
        </Link>
        <List component="div" disablePadding={true}>
          {liftingGroups.map((liftGroup) => (
            <Link
              key={liftGroup.id}
              to={`/meet/${activeMeet.id}/liftEntryGroup/${liftGroup.id}`}
            >
              <ListItemButton onClick={onClose} sx={{ pl: 4 }}>
                <ListItemIcon />
                <ListItemText primary={liftGroup.liftingGroupName} />
              </ListItemButton>
            </Link>
          ))}
        </List>
      </Collapse>
    </>
  );
}

type NavMenuProps = {
  openMenu: boolean;
  onClose: () => void;
  onSubMenuBack: () => void;
};

export default function NavMenu({
  openMenu,
  onClose,
  onSubMenuBack,
}: NavMenuProps) {
  const user = useSelector((state) => {
    return (state as RootState).app.user;
  });

  const activeMeet = useSelector((state) => {
    return (state as RootState).meet.activeMeet;
  });
  const meetPermissions = useSelector((state) => {
    return (state as RootState).meet.meetPermissions;
  });

  const canMCMeet =
    user?.userType === "ADMIN" ||
    user?.id === activeMeet?.hostUser.id ||
    meetPermissions?.includes(UserTypes.DIRECTOR) ||
    meetPermissions?.includes(UserTypes.ADMIN) ||
    meetPermissions?.includes(UserTypes.MC);

  const canManageMeet =
    user?.userType === "ADMIN" ||
    user?.id === activeMeet?.hostUser.id ||
    meetPermissions?.includes(UserTypes.DIRECTOR) ||
    meetPermissions?.includes(UserTypes.ADMIN);

  const canEnterLifts =
    user?.userType === "ADMIN" ||
    user?.userType === "ENTRY" ||
    user?.id === activeMeet?.hostUser.id ||
    meetPermissions?.includes(UserTypes.DIRECTOR) ||
    meetPermissions?.includes(UserTypes.ADMIN);
  meetPermissions?.includes(UserTypes.ENTRY);

  const [meetMenuOpen, setMeetMenuOpen] = useState(false);

  const links = Links.filter(({ text, link, icon, permissions }) => {
    if (!permissions) {
      return true;
    }

    if (permissions.indexOf(user?.userType) > -1) {
      return true;
    }
    return false;
  });

  useEffect(() => {
    if (activeMeet && openMenu) {
      setMeetMenuOpen(true);
    }
  }, [activeMeet, openMenu]);

  // useEffect(()=> {
  //   if (meetMenuOpen) {
  //     onClose();
  //   }
  // }, [onClose, meetMenuOpen] );

  function handleMeetClick() {
    setMeetMenuOpen(true);
  }
  function handleMeetMenuClose() {
    setMeetMenuOpen(false);
    onClose();
  }
  function handleMeetBack() {
    setMeetMenuOpen(false);
    // onSubMenuBack();
  }

  const list = () => (
    <Box sx={{ width: 400 }} role="presentation">
      <List>
        {links.map(({ text, link, icon }) => (
          <Link to={link} key={text}>
            <ListItem button={true} onClick={onClose}>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          </Link>
        ))}
        {activeMeet ? (
          <>
            <Divider />
            <ListItemButton onClick={handleMeetClick}>
              <ListItemIcon />
              <ListItemText primary={activeMeet.meetName} />
            </ListItemButton>
          </>
        ) : null}
      </List>
    </Box>
  );

  const liftingGroups = React.useMemo(() => {
    const lgs: LiftingGroupWithWeightClass[] =
      activeMeet?.liftingGroup.map((group) => {
        return {
          ...group,
          weightClasses: activeMeet.weightClasses.filter((weightClass) => {
            return weightClass.liftingGroup === group.id;
          }),
        };
      }) || [];

    lgs.map((group) => {
      return group.weightClasses.sort(sortWeightClasses);
    });
    return lgs.sort(sortLiftingGroups);
  }, [activeMeet]);

  return (
    <>
      <Drawer
        className="NavMenu"
        variant="temporary"
        anchor="left"
        open={openMenu && !meetMenuOpen}
        onClose={onClose}
      >
        {list()}
      </Drawer>
      {activeMeet ? (
        <Drawer
          className="NavMenu"
          variant="temporary"
          anchor="left"
          open={meetMenuOpen}
          onClose={handleMeetMenuClose}
        >
          <Box sx={{ width: 400, maxWidth: "100vw" }} role="presentation">
            <List>
              <ListItemButton onClick={handleMeetBack}>
                <ListItemIcon>
                  <ArrowBack />
                </ListItemIcon>
                <ListItemText primary="LiftMeet Menu" />
              </ListItemButton>
              <Divider />
              {canManageMeet ? (
                <Link to={`/meet/${activeMeet.id}/admin/setup`}>
                  <ListItemButton onClick={handleMeetMenuClose}>
                    <ListItemIcon />
                    <ListItemText primary="Meet Admin" />
                  </ListItemButton>
                </Link>
              ) : null}
              {canMCMeet ? (
                <Link to={`/meet/${activeMeet.id}/mc`}>
                  <ListItemButton onClick={handleMeetMenuClose}>
                    <ListItemIcon />
                    <ListItemText primary="MC Console" />
                  </ListItemButton>
                </Link>
              ) : null}
              <Link to={`/meet/${activeMeet.id}/`}>
                <ListItemButton onClick={handleMeetMenuClose}>
                  <ListItemIcon />
                  <ListItemText primary="Meet Home" />
                </ListItemButton>
              </Link>
              <Link to={`/meet/${activeMeet.id}/allAthletes`}>
                <ListItemButton onClick={handleMeetMenuClose}>
                  <ListItemIcon />
                  <ListItemText primary="All Athletes" />
                </ListItemButton>
              </Link>
              {/* <Link to={`/meet/${activeMeet.id}/rules`}>
                <ListItemButton onClick={handleMeetMenuClose}>
                  <ListItemIcon />
                  <ListItemText primary="Meet Rules" />
                </ListItemButton>
              </Link> */}
              <ResultsMenu
                activeMeet={activeMeet}
                onClose={handleMeetMenuClose}
              />
              <LiftOrderMenu
                liftingGroups={liftingGroups}
                activeMeet={activeMeet}
                onClose={handleMeetMenuClose}
              />
              <LiftOrderMenu
                liftingGroups={liftingGroups}
                activeMeet={activeMeet}
                onClose={handleMeetMenuClose}
                isHeadJudge={true}
              />
              {canEnterLifts ? (
                <LiftEntryMenu
                  liftingGroups={liftingGroups}
                  activeMeet={activeMeet}
                  onClose={handleMeetMenuClose}
                />
              ) : null}
            </List>
          </Box>
        </Drawer>
      ) : null}
    </>
  );
}
