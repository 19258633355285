import { MeetActionType } from "./types";
import MeetService from "../../services/meet";
import { createActions } from "../types";

const MeetActions = createActions({
  initialize(meetId: string) {
    return async (dispatch, getState) => {
      const state = getState();
      const meet = await MeetService.getMeetConfig(meetId);

      const meetPermissions = state?.app?.user?.id
        ? await MeetService.getMeetPermissions(meetId, state.app.user.id)
        : null;

      dispatch({
        type: MeetActionType.INITIALIZE,
        payload: { meet, meetPermissions },
      });
    };
  },

  load() {
    return async (dispatch, getState) => {
      const state = getState();
      const meet = await MeetService.getMeetConfig(state.meet.activeMeet.id);

      dispatch({
        type: MeetActionType.LOAD,
        payload: { meet },
      });
    };
  },

  deinitialize() {
    return async (dispatch) => {
      await dispatch({
        type: MeetActionType.DEINITIALIZE,
      });
    };
  },

  // setUser(user: User) {
  //   return async (dispatch) => {
  //     dispatch({
  //       type: MeetActionType.INITIALIZE,
  //       payload: { user },
  //     });
  //   };
  // },
});

export default MeetActions;
