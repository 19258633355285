import Service from ".";
import type { LiftingGroupWithOrder, UUID } from "../types";
class McService extends Service {
  constructor() {
    super({ path: "/php/MCMain.php" });
  }

  getAllLiftOrder(meetId: UUID): Promise<LiftingGroupWithOrder[]> {
    return this.get({
      task: "getAllLiftOrder",
      meetId,
    });
  }

  getFinalResults(meetId: UUID, weightClassId: UUID) {
    return this.get({
      task: "getFinalResults",
      meetId,
      weightClassId: weightClassId,
    });
  }

  getWeightClasses(meetId: UUID) {
    return this.get({
      task: "getWeightClasses",
      meetId,
    });
  }

  getBestMaleLifter(meetId: UUID) {
    return this.get({
      task: "getBestMaleLifter",
      meetId,
    });
  }

  getBestFemaleLifter(meetId: UUID) {
    return this.get({
      task: "getBestFemaleLifter",
      meetId,
    });
  }

  getBestTotalLifter(meetId: UUID) {
    return this.get({
      task: "getBestTotalLifter",
      meetId,
    });
  }
}

export default new McService();
