/**
 * Put the data into the object array below
 * BOYS: GIRLS:
52.0kg: (114.6-lbs) 44.0kg (97-lbs)
56.0kg: (123.4-lbs) 48.0kg (105.8-lbs)
60.0kg (132.2-lbs) 52.0kg (114.6-lbs)
67.5kg (148.8-lbs) 56.0kg (123.4-lbs)
75.0kg (165.3-lbs) 60.0kg (132.2-lbs)
82.5kg (181.8-lbs) 67.5kg (148.8-lbs)
90.0kg (198.4-lbs) 75.0kg (165.3-lbs)
100.0kg (220.4-lbs) 82.5kg (181.8-lbs)
110.0kg (242.5-lbs) 90.0kg (198.4-lbs)
125.0kg (275.5-lbs) 100.0kg (220.4-lbs)
145.0kg (319.6-lbs) 100.01+kg (220.5+-lbs)
145.01+kg (319.7+-lbs) 
 */

import type { WeightClassForm } from "../components/Meets/MeetSetup/types";

export const SDHSPAWeightClasses: WeightClassForm[] = [
  {
    weightClassName: "Girls 1",
    maxWeight: 97,
    gender: "FEMALE",
    weightClassOrder: 0,
  },
  {
    weightClassName: "Girls 2",
    maxWeight: 105.8,
    gender: "FEMALE",
    weightClassOrder: 1,
  },
  {
    weightClassName: "Girls 3",
    maxWeight: 114.6,
    gender: "FEMALE",
    weightClassOrder: 2,
  },
  {
    weightClassName: "Girls 4",
    maxWeight: 123.4,
    gender: "FEMALE",
    weightClassOrder: 3,
  },
  {
    weightClassName: "Girls 5",
    maxWeight: 132.2,
    gender: "FEMALE",
    weightClassOrder: 4,
  },
  {
    weightClassName: "Girls 6",
    maxWeight: 148.8,
    gender: "FEMALE",
    weightClassOrder: 5,
  },
  {
    weightClassName: "Girls 7",
    maxWeight: 165.3,
    gender: "FEMALE",
    weightClassOrder: 6,
  },
  {
    weightClassName: "Girls 8",
    maxWeight: 181.8,
    gender: "FEMALE",
    weightClassOrder: 7,
  },
  {
    weightClassName: "Girls 9",
    maxWeight: 198.4,
    gender: "FEMALE",
    weightClassOrder: 8,
  },
  {
    weightClassName: "Girls 10",
    maxWeight: 220.4,
    gender: "FEMALE",
    weightClassOrder: 9,
  },
  {
    weightClassName: "Girls 11",
    maxWeight: 10000,
    gender: "FEMALE",
    weightClassOrder: 10,
  },
  {
    weightClassName: "114.6",
    maxWeight: 114.6,
    gender: "MALE",
    weightClassOrder: 11,
  },
  {
    weightClassName: "123.4",
    maxWeight: 123.4,
    gender: "MALE",
    weightClassOrder: 12,
  },
  {
    weightClassName: "132.2",
    maxWeight: 132.2,
    gender: "MALE",
    weightClassOrder: 13,
  },
  {
    weightClassName: "148.8",
    maxWeight: 148.8,
    gender: "MALE",
    weightClassOrder: 14,
  },
  {
    weightClassName: "165.3",
    maxWeight: 165.3,
    gender: "MALE",
    weightClassOrder: 15,
  },
  {
    weightClassName: "181.8",
    maxWeight: 181.8,
    gender: "MALE",
    weightClassOrder: 16,
  },
  {
    weightClassName: "198.4",
    maxWeight: 198.4,
    gender: "MALE",
    weightClassOrder: 17,
  },
  {
    weightClassName: "220.4",
    maxWeight: 220.4,
    gender: "MALE",
    weightClassOrder: 18,
  },
  {
    weightClassName: "242.5",
    maxWeight: 242.5,
    gender: "MALE",
    weightClassOrder: 19,
  },
  {
    weightClassName: "275.5",
    maxWeight: 275.5,
    gender: "MALE",
    weightClassOrder: 20,
  },
  {
    weightClassName: "308.6",
    maxWeight: 308.6,
    gender: "MALE",
    weightClassOrder: 21,
  },
  {
    weightClassName: "308.7+",
    maxWeight: 10000,
    gender: "MALE",
    weightClassOrder: 22,
  },
];
