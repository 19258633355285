import { Stack, Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { useMemo } from "react";

import type { MeetAthleteWithResults, WeightClass } from "../../types";
type AthleteMeetHeaderProps = {
  athlete: MeetAthleteWithResults;
  weightClasses: WeightClass[];
};

export default function AthleteMeetHeader({
  athlete,
  weightClasses,
}: AthleteMeetHeaderProps) {
  const fieldDef = useMemo(() => {
    if (!athlete) return null;
    return [
      {
        fields: [
          { label: "1st Squat", value: athlete.startSquat },
          { label: "1st Bench", value: athlete.startBench },
          { label: "1st Deadlift", value: athlete.startDeadlift },
        ],
      },
      {
        fields: [
          { label: "Grade", value: athlete.grade },
          {
            label: "Weight Class",
            value: weightClasses.find((wc) => athlete.weightClass == wc.id)
              ?.weightClassName,
          },
          {
            label: "Platform",
            value: athlete.liftingGroup.liftingGroupName,
          },
        ],
      },
    ];
  }, [athlete, weightClasses]);

  return (
    <>
      <Typography variant="h1">{`${athlete.lastName}, ${athlete.firstName}`}</Typography>
      <Stack
        direction="row"
        flexWrap="wrap"
        divider={<Divider orientation="vertical" flexItem={true} />}
        spacing={2}
      >
        <Typography variant="h5">{athlete.gender}</Typography>
        <Typography variant="h5">{athlete.bodyWeight} lbs</Typography>
        {/* TODO: figure out why schoolName isn't working. */}
        {/* <Typography variant="h5">{athlete.schoolName}</Typography> */}
      </Stack>
      <Stack direction="row" flexWrap="wrap" gap="4px 48px" sx={{ mt: 2 }}>
        {fieldDef?.map((fd, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Stack key={i} direction="column" gap={0.5} flex={1}>
            {fd.fields.map((f) => (
              <Stack
                key={f.label}
                direction="row"
                justifyContent="space-between"
                sx={{ minWidth: "200px", maxWidth: "300px" }}
              >
                <Typography variant="body1" color="text.secondary">
                  {f.label}:
                </Typography>
                <Typography variant="body1">{f.value}</Typography>
              </Stack>
            ))}
          </Stack>
        ))}
      </Stack>
    </>
  );
}
