import Service from ".";
import type {
  Meet,
  Athlete,
  School,
  WeightClass,
  AllResults,
  UUID,
} from "../types";

class MainService extends Service {
  constructor() {
    super({ path: "/php/Main.php" });
  }

  getAthlete(athleteId?: UUID): Promise<Athlete> {
    return this.get({
      task: "getAthlete",
      athleteId,
    });
  }

  getAllAthletes(meetId?: number, schoolId?: number): Promise<Athlete[]> {
    return this.get({
      task: "getAllAthletes",
      meetId,
      schoolId,
    });
  }

  getMeet(meetId: number): Promise<Meet> {
    return this.get({
      task: "getMeet",
      meetId,
    });
  }

  getMeets(onlyUsersMeets: boolean): Promise<Meet[]> {
    return this.get({
      task: "getMeets",
      onlyUsersMeets,
    });
  }

  getSchool(schoolId: UUID): Promise<School> {
    return this.get({
      task: "getSchool",
      schoolId,
    });
  }

  getAllSchools(): Promise<School[]> {
    return this.get({
      task: "getAllSchools",
    });
  }

  getAllWeightClasses(): Promise<WeightClass[]> {
    return this.get({
      task: "getAllWeightClasses",
    });
  }

  getResultsForAthlete(id: UUID): Promise<AllResults> {
    return this.get({
      task: "getResultsForAthlete",
      id,
    });
  }
}

export default new MainService();
