import type store from ".";
import type { AppAction, AppState } from "./app/types";
import type { MeetAction, MeetState } from "./meet/types";
import { typedActionCreators } from "./utils";

export type Action = AppAction | MeetAction;

export type RootState = {
  app: AppState;
  meet: MeetState;
};

export type AppDispatch = typeof store.dispatch;
export type AppGetState = typeof store.getState;

export const createActions = typedActionCreators<RootState, Action>();
