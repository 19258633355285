/* eslint-disable @typescript-eslint/no-unused-vars */
import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import { Routes, Route, Outlet } from "react-router-dom";

import Meets from "./Meets";
import TeamManagement from "./TeamManagement";
import LinkTab from "../common/LinkTab";
import LinkTabs from "../common/LinkTabs";

type CoachConsoleProps = {
  children?: React.ReactNode;
};
export default function CoachConsole({ children }: CoachConsoleProps) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      sx={{ width: "100%" }}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <LinkTabs>
          {/* <LinkTab label="Coach" href="/coach" /> */}
          <LinkTab label="Team Management" href="/coach/teamManagement" />
          <LinkTab label="Meets" href="/coach/meets" />
        </LinkTabs>
      </Box>
      <Stack flex={1} sx={{ p: 3 }}>
        <Outlet />
      </Stack>
    </Box>
  );
}
