import type { DependencyList } from "react";
import { useEffect, useMemo, useReducer, useRef, useCallback } from "react";

enum ActionType {
  LOAD,
  SUCCESS,
  ERROR,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncState<T = any> = {
  // The data returned by the async fetch
  data: T;
  // The error thrown by the async fetch
  error: Error;
  // Whether we are in the initial load. False after the first
  // successful load.
  isInitial: boolean;
  // Whether the async fetch is currently running
  isLoading: boolean;
};

export type AsyncValue<T> = AsyncState<T> & { reload: () => Promise<void> };

const INITIAL_STATE: AsyncState = {
  data: null,
  // @ts-ignore
  error: null,
  isInitial: true,
  isLoading: true,
};

/**
 * Manages the state of the async operation and holds
 * any responses or errors returned by the async request.
 */
// eslint-disable-next-line @typescript-eslint/typedef
function reducer(state, action) {
  switch (action.type) {
    case ActionType.LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case ActionType.SUCCESS:
      return {
        isInitial: false,
        isLoading: false,
        data: action.payload,
        error: null,
      };
    case ActionType.ERROR:
      return {
        isInitial: false,
        isLoading: false,
        data: null,
        error: action.payload,
      };
  }
}

/**
 * React hook to fetch some async data. Manages the state of the
 * request, automatically cleaning up stale requests.
 *
 * Usage:
 *
 *   // Using a promise-returning callback
 *   const {data: aliasForData, error, isLoading} = useAsync(() => {
 *       return someAsyncFetch(arg1, arg2);
 *   }, [arg1, arg2]);
 *
 *   // Using async/await
 *   const {data: aliasForData, error, isLoading} = useAsync(async () => {
 *       return await someAsyncFetch(arg1, arg2);
 *   }, [arg1, arg2]);
 *
 * @template T The return type of the async function.
 * @param fetcher  Async function to execute. The function will
 *     fire anytime the arguments change.
 * @param inputs  Variables that are used inside the async method.
 *     If any of these variables change, the async method will be
 *     re-executed. IMPORTANT: Include all variables that are referenced
 *     inside the async method. Otherwise, the async data might not
 *     accurately reflect the current state of the component.
 * @returns  State object containing the data, error, and progress
 *     of the async operation.
 */
function useAsync<T>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetcher: (...args: any[]) => T | Promise<T>,
  inputs: DependencyList = []
): AsyncValue<T> {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  const isMounted = useRef(true);
  const count = useRef(0);

  // This preserves the async function so that the effect only reruns if the
  // inputs(dependencies for this hook) change
  const runAsync = useCallback(
    async () => {
      // We increment a counter each time we make a request so that we
      // can safely ignore stale requests.
      const curCount = ++count.current;
      dispatch({ type: ActionType.LOAD });
      try {
        const data = await fetcher();
        if (isMounted.current && curCount === count.current) {
          dispatch({ type: ActionType.SUCCESS, payload: data });
        }
      } catch (error) {
        if (isMounted.current && curCount === count.current) {
          dispatch({
            type: ActionType.ERROR,
            payload: error,
            error: true,
          });
        }
        throw error;
      }
    },
    // We have to disable exhaustive deps because this is a custom hook that models
    // the inputs handling of the native hooks.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    inputs
  );

  // Runs the async function anytime the inputs change
  useEffect(() => {
    runAsync();
  }, [runAsync]);

  // Track the mounted state of the component so that if the component
  // is unmounted by the time the async operation completes, we just
  // ignore the result of the async operation.
  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  // Memoize the async value so that it's only recomputed if the state
  // changes (since that's the only mutable value contained within the value).
  return useMemo(
    () => ({
      ...state,
      // A function to manually trigger a reload of the async data.
      reload: runAsync,
    }),
    [runAsync, state]
  );
}

export default useAsync;
