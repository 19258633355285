/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import { useSelector } from "react-redux";

import useAsync from "../../customHooks/useAsync";
import type { RootState } from "../../data/types";
import AdminService from "../../services/admin";
import CenteredCircularProgress from "../common/CenteredCircularProgress";

export default function SiteAdminConsole() {
  const user = useSelector((state) => {
    return (state as RootState).app.user;
  });

  const { data, isInitial } = useAsync(() => {
    return AdminService.getCoaches();
  }, []);

  console.log(data, isInitial);

  if (isInitial) {
    return <CenteredCircularProgress />;
  }

  if (!user || user.userType !== "ADMIN") {
    return <div>Not logged in</div>;
  }

  return (
    <div>
      <h1>Site Admin Console</h1>
    </div>
  );
}
