import type { LoadingButtonProps } from "@mui/lab/LoadingButton";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, TextField, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import store from "../../../data";
import AppActions from "../../../data/app/actions";
import type { RootState } from "../../../data/types";
import EntryService from "../../../services/entry";
import type {
  AthleteOrderWithResultsAndLiftingGroup,
  UUID,
} from "../../../types";
import { LIFTS } from "../../../types";
import ConfirmationDialog from "../../common/ConfirmationDialog";
import DisplayGrid from "../../common/DisplayGrid";

type ScratchEventButtonProps = LoadingButtonProps & {
  athlete: AthleteOrderWithResultsAndLiftingGroup;
};

function ScratchEventButton({ athlete, ...props }: ScratchEventButtonProps) {
  const [isLoading, setLoading] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  async function handleConfirm() {
    setDialogOpen(false);
    setLoading(true);
    try {
      await EntryService.scratchEvent(athlete.meet_athleteId);

      await store.dispatch(
        AppActions.setSnackBar({
          text: `Successfully scratched event for ${athlete.firstName} ${athlete.lastName}.`,
          severity: "success",
          open: true,
        })
      );
    } catch (e) {
      await store.dispatch(
        AppActions.setSnackBar({
          text: `Failed scratch event for ${athlete.firstName} ${athlete.lastName}. Please try again.  If this problem persists contact a meet administrator.`,
          severity: "error",
          open: true,
        })
      );
    }

    setLoading(false);
  }
  return (
    <>
      <LoadingButton
        {...props}
        loading={isLoading}
        variant="outlined"
        onClick={() => {
          setDialogOpen(true);
        }}
      >
        Scratch Event
      </LoadingButton>
      <ConfirmationDialog
        title={`Scratch ${athlete.firstName} ${athlete.lastName} from ${athlete?.liftingGroup?.activeEvent}`}
        message={`Are you sure you want to scratch ${athlete.firstName} ${athlete.lastName} from ${athlete?.liftingGroup?.activeEvent}?`}
        open={isDialogOpen}
        onConfirm={handleConfirm}
        onClose={() => {
          setDialogOpen(false);
        }}
      />
    </>
  );
}

type ScratchMeetButtonProps = LoadingButtonProps & {
  athlete: AthleteOrderWithResultsAndLiftingGroup;
};

function ScratchMeetButton({ athlete, ...props }: ScratchMeetButtonProps) {
  const [isLoading, setLoading] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  async function handleConfirm() {
    setDialogOpen(false);
    setLoading(true);
    try {
      await EntryService.scratchMeet(athlete.meet_athleteId);

      await store.dispatch(
        AppActions.setSnackBar({
          text: `Successfully scratched meet for ${athlete.firstName} ${athlete.lastName}.`,
          severity: "success",
          open: true,
        })
      );
    } catch (e) {
      await store.dispatch(
        AppActions.setSnackBar({
          text: `Failed scratch meet for ${athlete.firstName} ${athlete.lastName}. Please try again.  If this problem persists contact a meet administrator.`,
          severity: "error",
          open: true,
        })
      );
    }

    setLoading(false);
  }
  return (
    <>
      <LoadingButton
        {...props}
        loading={isLoading}
        variant="outlined"
        onClick={() => {
          setDialogOpen(true);
        }}
      >
        Scratch Meet
      </LoadingButton>
      <ConfirmationDialog
        title={`Scratch ${athlete.firstName} ${athlete.lastName} from meet`}
        message={`Are you sure you want to scratch ${athlete.firstName} ${athlete.lastName} from all remaining events in this meet?`}
        open={isDialogOpen}
        onConfirm={handleConfirm}
        onClose={() => {
          setDialogOpen(false);
        }}
      />
    </>
  );
}

type SameWeightButtonProps = LoadingButtonProps & {
  athlete: AthleteOrderWithResultsAndLiftingGroup;
};

function SameWeightButton({ athlete, ...props }: SameWeightButtonProps) {
  const [isLoading, setLoading] = useState(false);
  return (
    <LoadingButton
      {...props}
      loading={isLoading}
      onClick={async () => {
        try {
          setLoading(true);
          await EntryService.setUseSameWeight(athlete.meet_athleteId);

          await store.dispatch(
            AppActions.setSnackBar({
              text: `Successfully set next weight for ${athlete.firstName} ${athlete.lastName}.`,
              severity: "success",
              open: true,
            })
          );
        } catch (e) {
          await store.dispatch(
            AppActions.setSnackBar({
              text: `Failed to set next weight for ${athlete.firstName} ${athlete.lastName}. Please try again.  If this problem persists contact a meet administrator.`,
              severity: "error",
              open: true,
            })
          );
        }
        setLoading(false);
      }}
      variant="outlined"
    >
      Same
    </LoadingButton>
  );
}

type NextLiftEntryGridProps = {
  athletes: AthleteOrderWithResultsAndLiftingGroup[];
};

export default function NextLiftEntryGrid({
  athletes,
}: NextLiftEntryGridProps) {
  const { meet } = useSelector((state) => {
    return {
      meet: (state as RootState).meet.activeMeet,
    };
  });

  const [filterNameValue, setFilterNameValue] = useState("");

  function onFilterChange(e: React.ChangeEvent<HTMLInputElement>) {
    setFilterNameValue(e.target.value);
  }

  const sortedAthletes = useMemo(() => {
    const aths = athletes?.map((a) => {
      const { results } = a;
      const liftCounts = LIFTS.reduce((accumulator, value) => {
        return { ...accumulator, [value]: 0 };
      }, {});
      let activeEventCount = 0;
      const activeEvent = a?.liftingGroup?.activeEvent;
      let allEventCount = 0;

      results?.forEach((r) => {
        if (r.liftSuccess === "TRUE" || r.liftSuccess === "FALSE") {
          if (r.event === activeEvent) {
            activeEventCount += 1;
          }
          if (liftCounts[r.event] !== undefined) {
            liftCounts[r.event] += 1;
          }

          allEventCount += 1;
        }
      });

      return { ...a, activeEventCount, allEventCount, liftCounts };
    });
    const name = filterNameValue.toLowerCase();

    // Calculate next weight for each athlete
    return aths
      ?.filter((a) => {
        if (name === "") {
          return true;
        }
        const firstName = a.firstName.toLowerCase();
        const lastName = a.lastName.toLowerCase();

        if (firstName.includes(name) || lastName.includes(name)) {
          return true;
        }

        return false;
      })
      ?.sort((a, b) => {
        if (a.lastName > b.lastName) {
          return 1;
        }
        if (a.lastName < b.lastName) {
          return -1;
        }
        if (a.firstName > b.firstName) {
          return 1;
        }
        if (a.firstName < b.firstName) {
          return -1;
        }
      });
  }, [athletes, filterNameValue]);

  return (
    <>
      <TextField margin="dense" label="Search" onChange={onFilterChange} />
      <DisplayGrid
        emptyComponent={
          <Typography variant="h4">
            No athletes have been entered into this meet.
          </Typography>
        }
        data={sortedAthletes}
        columns={[
          // TODO: Add back in when flight is added to meet athlete
          // {
          //   label: "Flight",
          //   dataId: "flight",
          //   width: 55,
          // },
          {
            label: "Name",
            renderer: (d) => {
              // if (windowWidth > 680) {
              return `${d.lastName}, ${d.firstName}`;
              // }
              // return `${d.lastName}, ${d.firstName[0]}.`;
            },
          },
          {
            label: "School",
            dataId: "schoolAbrev",
            width: 75,
          },
          {
            label: "Active Event",
            width: 95,
            renderer: (d) => {
              return d?.liftingGroup?.activeEvent;
            },
          },
          {
            label: "Squat Pin Height",
            dataId: "pinHeightSquat",
            width: 85,
            isNumber: true,
            renderer: (d) => {
              return (
                <ClickToEditCell
                  disabled={d.liftCounts["SQUAT"] >= meet.liftAttempts}
                  value={d.pinHeightSquat}
                  meetAthleteId={d.meet_athleteId}
                  successMessage={`Successfully updated squat pin height for ${d.firstName} ${d.lastName}.`}
                  updateRequest={(meetAthleteId, newValue) =>
                    EntryService.setSquatPinHeight(meetAthleteId, newValue)
                  }
                />
              );
            },
          },
          {
            label: "Bench Pin Height",
            dataId: "pinHeightBench",
            width: 85,
            isNumber: true,
            renderer: (d) => {
              return (
                <ClickToEditCell
                  disabled={d.liftCounts["BENCH"] >= meet.liftAttempts}
                  value={d.pinHeightBench}
                  meetAthleteId={d.meet_athleteId}
                  successMessage={`Successfully updated bench pin height for ${d.firstName} ${d.lastName}.`}
                  updateRequest={(meetAthleteId, newValue) =>
                    EntryService.setBenchPinHeight(meetAthleteId, newValue)
                  }
                />
              );
            },
          },
          {
            label: "Next Weight",
            width: 85,
            isNumber: true,
            renderer: (d) => {
              return (
                <ClickToEditCell
                  isNextWeight={true}
                  disabled={d.activeEventCount >= meet.liftAttempts}
                  value={d.nextWeight}
                  meetAthleteId={d.meet_athleteId}
                  successMessage={`Successfully set next weight for ${d.firstName} ${d.lastName}.`}
                  step={5}
                  updateRequest={(meetAthleteId, newValue) =>
                    EntryService.setNextWeight(meetAthleteId, newValue)
                  }
                />
              );
            },
          },
          {
            label: "Same Weight",
            width: 85,
            renderer: (d) => {
              return (
                <SameWeightButton
                  disabled={d.activeEventCount >= meet.liftAttempts}
                  athlete={d}
                />
              );
            },
          },
          {
            label: "Scratch",
            width: 200,
            renderer: (d) => {
              return (
                <>
                  <ScratchEventButton
                    disabled={d.activeEventCount >= meet.liftAttempts}
                    athlete={d}
                  />
                  <ScratchMeetButton
                    disabled={d.allEventCount >= meet.liftAttempts * 3}
                    athlete={d}
                  />
                </>
              );
            },
          },
        ]}
      />
    </>
  );
}

type ClickToEditCellProps = {
  isNextWeight?: boolean;
  disabled?: boolean;
  value: number;
  meetAthleteId: UUID;
  successMessage: string;
  step?: number;
  updateRequest: (meetAthleteId: UUID, newValue: number) => Promise<UUID>;
};

function ClickToEditCell({
  isNextWeight,
  disabled,
  value,
  meetAthleteId,
  successMessage,
  step = 1,
  updateRequest,
}: ClickToEditCellProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [val, setVal] = useState(value);

  useEffect(() => {
    setVal(value);
  }, [value]);

  function handleBlur() {
    setIsEditing(false);
    setVal(value);
  }

  function handleClick() {
    setIsEditing(true);
  }

  async function handleKeyUp(event: React.KeyboardEvent) {
    if (event.key === "Enter") {
      if (isNextWeight && val % 5 !== 0) {
        await store.dispatch(
          AppActions.setSnackBar({
            text: `Weight must be a multiple of 5.`,
            severity: "error",
            open: true,
          })
        );
        return;
      }
      try {
        await updateRequest(meetAthleteId, val);
        setIsEditing(false);

        await store.dispatch(
          AppActions.setSnackBar({
            text: successMessage,
            severity: "success",
            open: true,
          })
        );
      } catch (e) {
        await store.dispatch(
          AppActions.setSnackBar({
            text: `Failed to update data for this athlete.  If this problem persists contact a meet administrator.`,
            severity: "error",
            open: true,
          })
        );
      }
    }
  }

  return (
    <Box onClick={!disabled ? handleClick : null}>
      {isEditing ? (
        <TextField
          size="small"
          type="number"
          onFocus={(e) => e.target.select()}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*", step }}
          autoFocus={true}
          value={val}
          fullWidth={true}
          onBlur={handleBlur}
          onKeyUp={handleKeyUp}
          onChange={(e) => setVal(Number(e.target.value))}
        />
      ) : (
        <Typography
          variant="body1"
          sx={
            !disabled
              ? {
                  color: "primary.main",
                  textDecoration: "underline",
                  cursor: "pointer",
                }
              : {
                  color: "text.disabled",
                }
          }
        >
          {val}
        </Typography>
      )}
    </Box>
  );
}
