import { LoadingButton } from "@mui/lab";
import { Button, Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import * as React from "react";

import ModifyWeightClassRosterDialog from "./ModifyWeightClassRosterDialog";
import { UpdateMeetAthleteDialog } from "./UpdateMeetAthleteDialog";
import useAsync from "../../customHooks/useAsync";
import store from "../../data";
import AppActions from "../../data/app/actions";
import CoachService from "../../services/coach";
import MeetService from "../../services/meet";
import type { MeetAthleteWithResults, UUID, WeightClass } from "../../types";
import CenteredCircularProgress from "../common/CenteredCircularProgress";
import ConfirmationDialog from "../common/ConfirmationDialog";

type WeightClassMeetRosterGridProps = {
  isExpanded?: boolean;
  weightClasses: WeightClass[];
  meetId: UUID;
  schoolId: UUID;
  weightClass: WeightClass;
  topLevelReload: () => void;
  athleteCount: number;
  maxAthletes: number;
};
export default function WeightClassMeetRosterGrid({
  isExpanded = true,
  weightClasses,
  meetId,
  schoolId,
  weightClass,
  topLevelReload,
  athleteCount,
  maxAthletes,
}: WeightClassMeetRosterGridProps) {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const { data, isLoading, reload } = useAsync(() => {
    if (isExpanded === false) {
      return Promise.resolve([]);
    }
    return MeetService.getMeetSchoolAthletesForWeightClass(
      meetId,
      schoolId,
      weightClass.id
    );
  }, [meetId, schoolId, weightClass.id, isExpanded]);

  const [selectedAthleteIds, setSelectedAthleteIds] = React.useState([]);

  React.useEffect(() => {
    if (isDialogOpen === true) {
      setSelectedAthleteIds(data.map((athlete) => athlete.id));
    }
  }, [data, isDialogOpen]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isEditDialogOpen, setIsEditDialogOpen] = React.useState(false);
  const [selectedAthlete, setSelectedAthlete] = React.useState(null);

  function handleClickEdit(athlete: MeetAthleteWithResults) {
    setIsEditDialogOpen(true);
    setSelectedAthlete(athlete);
  }

  return (
    <>
      <Stack gap={3}>
        <Stack alignItems="end">
          <Button
            disabled={athleteCount >= maxAthletes}
            onClick={() => {
              setIsDialogOpen(true);
            }}
            color="success"
            variant="contained"
          >
            Add Athlete(s)
          </Button>
        </Stack>
        <DataGrid
          autoHeight={true}
          rows={data || []}
          hideFooter={data?.length < 100}
          hideFooterSelectedRowCount={true}
          columns={[
            {
              field: "lastName",
              headerName: "Name",
              flex: 1,
              renderCell: (params) => {
                return `${params.row.lastName}, ${params.row.firstName}`;
              },
            },
            {
              field: "bodyWeight",
              headerName: "Weight",
            },
            {
              field: "startSquat",
              headerName: "1st Squat",
            },
            {
              field: "startBench",
              headerName: "1st Bench",
            },
            {
              field: "startDeadlift",
              headerName: "1st Deadlift",
            },
            {
              field: "pinHeightSquat",
              headerName: "Squat Pin Height",
            },
            {
              field: "pinHeightBench",
              headerName: "Bench Pin Height",
            },
            {
              field: "actions",
              headerName: "",
              width: 200,
              renderCell: (params) => {
                return (
                  <Stack direction="row" gap={1}>
                    <Button
                      variant="contained"
                      onClick={() => handleClickEdit(params.row)}
                    >
                      Edit
                    </Button>
                    <RemoveButton
                      meetAthleteId={params.row.meet_athleteId}
                      reload={reload}
                      topLevelReload={topLevelReload}
                    />
                  </Stack>
                );
              },
            },
          ]}
          components={{
            NoRowsOverlay: () => {
              if (isLoading) {
                return <CenteredCircularProgress />;
              }
              return (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  No athletes added for this weight class.
                </Stack>
              );
            },
          }}
        />
      </Stack>
      <UpdateMeetAthleteDialog
        meetId={meetId}
        onClose={() => {
          setIsEditDialogOpen(false);
        }}
        open={isEditDialogOpen}
        athlete={selectedAthlete}
        weightClass={weightClass}
        onSuccess={() => {
          setIsEditDialogOpen(false);
          reload();
        }}
      />
      <ModifyWeightClassRosterDialog
        open={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
          reload();
          topLevelReload();
        }}
        meetId={meetId}
        schoolId={schoolId}
        weightClass={weightClass}
        weightClasses={weightClasses}
        initialAthleteIds={selectedAthleteIds}
        athleteCount={athleteCount}
        maxAthletes={maxAthletes}
      />
    </>
  );
}

type RemoveButtonProps = {
  meetAthleteId: UUID;
  reload: () => void;
  topLevelReload: () => void;
};

function RemoveButton({
  meetAthleteId,
  reload,
  topLevelReload,
}: RemoveButtonProps) {
  const [isDialogOpen, setDialogOpen] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  async function handleRemoveAthlete() {
    setDialogOpen(false);
    setLoading(true);
    try {
      await CoachService.removeAthleteFromMeet(meetAthleteId);

      reload();
      topLevelReload();
    } catch (e) {
      await store.dispatch(
        AppActions.setSnackBar({
          text: `Failed to remove the athlete from the meet. Please try again.  If this problem persists contact a meet administrator.`,
          severity: "error",
          open: true,
        })
      );
    }

    setLoading(false);
  }
  return (
    <>
      <LoadingButton
        loading={isLoading}
        onClick={() => setDialogOpen(true)}
        variant="contained"
        color="error"
      >
        Remove
      </LoadingButton>
      <ConfirmationDialog
        title="Confirm delete result"
        message="Are you sure you want to remove this athlete from the meet?"
        open={isDialogOpen}
        onConfirm={handleRemoveAthlete}
        onClose={() => {
          setDialogOpen(false);
        }}
      />
    </>
  );
}
