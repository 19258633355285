/* eslint-disable @typescript-eslint/no-unused-vars */
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { DataGrid } from "@mui/x-data-grid";
import * as React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";

import type { RootState } from "../../data/types";
import McService from "../../services/mc";
import type { LiftingGroupWithOrder } from "../../types";
import { sortWeightClasses } from "../../utils/sortUtils";

type LiftingGroupGridProps = {
  liftingGroup: LiftingGroupWithOrder;
};

function LiftingGroupGrid({ liftingGroup }: LiftingGroupGridProps) {
  const meet = useSelector((state) => {
    return (state as RootState).meet.activeMeet;
  });
  const [liftUpdated, setLiftUpdated] = useState(false);

  const [order, setOrder] = useState([]);

  React.useEffect(() => {
    if (
      liftingGroup.order.length > 0 &&
      order.length > 0 &&
      order[0].meet_athleteId !== liftingGroup.order[0].meet_athleteId
    ) {
      setLiftUpdated(true);
      setTimeout(() => {
        setLiftUpdated(false);
        setOrder(liftingGroup.order);
      }, 5000);
    } else {
      setOrder(liftingGroup.order);
    }
  }, [liftingGroup.order]);

  const columns = [
    ...(meet.flightCount
      ? [
          {
            field: "flight",
            headerName: "Flight",
            sortable: false,
            width: 70,
          },
        ]
      : []),
    {
      field: "fullName",
      headerName: "Full name",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 160,
      valueGetter: (params) =>
        `${params.row.firstName || ""} ${params.row.lastName || ""}`,
    },
    {
      field: "liftCount",
      headerName: "Attempts",
      sortable: false,
      width: 90,
    },
    {
      field: "nextWeight",
      headerName: "Next Weight",
      sortable: false,
      width: 115,
    },
  ];

  return (
    <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={3}>
      <Box>
        <Box>{liftingGroup.liftingGroupName}</Box>
        <span>Event: {liftingGroup.activeEvent}</span>
      </Box>
      <DataGrid
        sx={{ height: 215, bgcolor: "background.paper" }}
        getRowClassName={(params) => {
          return params.isFirstVisible && liftUpdated ? "liftUpdated-row" : "";
        }}
        rows={order}
        disableColumnMenu={true}
        hideFooter={true}
        columns={columns}
        disableSelectionOnClick={true}
        components={{
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              Event complete for this lifting group.
            </Stack>
          ),
        }}
      />
    </Grid>
  );
}
const PLATFORM = "Platform ";

export default function LiftingOrder() {
  const [orderData, setOrderData] = useState([]);
  const meet = useSelector((state) => {
    return (state as RootState).meet.activeMeet;
  });

  function getLiftingOrder() {
    McService.getAllLiftOrder(meet.id).then((data) => {
      const x = data
        .map((group) => {
          return {
            ...group,
            order: group.order.map((order) => {
              return { id: order.meet_athleteId, ...order };
            }),
          };
        })
        .sort((a, b) => {
          if (
            a.liftingGroupName.substring(0, PLATFORM.length) === PLATFORM &&
            b.liftingGroupName.substring(0, PLATFORM.length) === PLATFORM
          ) {
            const aNum = parseInt(
              a.liftingGroupName.substring(
                PLATFORM.length,
                a.liftingGroupName.length
              ),
              10
            );
            const bNum = parseInt(
              b.liftingGroupName.substring(
                PLATFORM.length,
                b.liftingGroupName.length
              ),
              10
            );
            if (aNum < bNum) {
              return -1;
            }
            if (aNum > bNum) {
              return 1;
            }
          }

          if (a.liftingGroupName > b.liftingGroupName) {
            return 1;
          }
          return 0;
        });
      setOrderData(x);
    });
  }
  React.useEffect(() => {
    getLiftingOrder();
    const timer = setInterval(() => {
      getLiftingOrder();
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container={true} spacing={2}>
        {orderData.map((group) => {
          return <LiftingGroupGrid key={group.id} liftingGroup={group} />;
        })}
      </Grid>
    </Box>
  );
}
