import { createTheme } from "@mui/material/styles";

export const baseTheme = createTheme({
  palette: {},
  typography: {
    h1: {
      fontSize: "3rem",
      fontWeight: 500,
      lineHeight: 1.5,
    },
    h2: {
      fontSize: "2.5rem",
      fontWeight: 500,
      lineHeight: 1.4,
    },
    h3: {
      fontSize: "2rem",
      fontWeight: 500,
      lineHeight: 1.2,
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: 400,
      lineHeight: 1.2,
    },
    h5: {
      fontSize: "1.25rem",
      fontWeight: 400,
      lineHeight: 1.2,
    },
    h6: {
      fontSize: "1.1rem",
      fontWeight: 400,
      lineHeight: 1.2,
    },
  },
});
