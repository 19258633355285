/* eslint-disable @typescript-eslint/no-unused-vars */

import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  MenuItem,
  Box,
  Stack,
  Typography,
  IconButton,
  DialogActions,
  Button,
} from "@mui/material";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";

import { AddUpdateMeetAthleteForm } from "./AddUpdateMeetAthlete";
import store from "../../data";
import AppActions from "../../data/app/actions";
import CoachService from "../../services/coach";
import type {
  Athlete,
  MeetAthlete,
  MutateMeetAthlete,
  UUID,
  WeightClass,
} from "../../types";

type UpdateMeetAthleteDialogProps = {
  meetId: UUID;
  onClose: () => void;
  open: boolean;
  athlete: MeetAthlete;
  weightClass: WeightClass;
  onSuccess: () => void;
};
export function UpdateMeetAthleteDialog({
  meetId,
  open,
  onClose,
  athlete,
  weightClass,
  onSuccess,
}: UpdateMeetAthleteDialogProps) {
  const formRef = React.useRef();
  const [isLoading, setIsLoading] = React.useState(false);

  async function onSubmit(data: MutateMeetAthlete) {
    try {
      setIsLoading(true);

      await CoachService.updateAthleteForMeet(meetId, data);

      await CoachService;
      onSuccess();

      await store.dispatch(
        AppActions.setSnackBar({
          text: `Successfully updated ${athlete.firstName} ${athlete.lastName}!`,
          severity: "success",
          open: true,
        })
      );
      onClose();
    } catch (error) {
      await store.dispatch(
        AppActions.setSnackBar({
          text: `Failed to update ${athlete.firstName} ${athlete.lastName}!`,
          severity: "error",
          open: true,
        })
      );
    }
    setIsLoading(false);
  }

  function handleSubmit() {
    if (formRef.current) {
      // @ts-ignore
      formRef.current?.submit();
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{athlete ? "Edit" : "Add"} Athlete</DialogTitle>

      <DialogContent
        dividers={true}
        sx={{ display: "flex", flexDirection: "column", gap: 2 }}
      >
        <AddUpdateMeetAthleteForm
          ref={formRef}
          athlete={athlete}
          onUpdateAthlete={onSubmit}
          weightClass={weightClass}
        />
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          onClick={handleSubmit}
          variant="contained"
          loading={isLoading}
          type="submit"
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
