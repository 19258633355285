/* eslint-disable @typescript-eslint/no-unused-vars */

import { Stack, TextField, Typography } from "@mui/material";
import { max } from "lodash";
import * as React from "react";
import { useForm } from "react-hook-form";

import useAsync from "../../customHooks/useAsync";
import MainService from "../../services/main";
import MeetService from "../../services/meet";
import type {
  Athlete,
  MeetAthlete,
  MutateMeetAthlete,
  UUID,
  WeightClass,
} from "../../types";
import CenteredCircularProgress from "../common/CenteredCircularProgress";

type UpdateMeetAthleteProps = {
  athlete?: MutateMeetAthlete;
  athleteId?: UUID;
  meetAthleteId?: UUID;
  onUpdateAthlete: (ath: MutateMeetAthlete) => void;
  weightClass: WeightClass;
};

export const AddUpdateMeetAthlete = React.forwardRef(
  (
    {
      athlete,
      athleteId,
      meetAthleteId,
      onUpdateAthlete,
      weightClass,
    }: UpdateMeetAthleteProps,
    ref
  ) => {
    const { data, isLoading } = useAsync(() => {
      if (athlete) {
        return Promise.resolve(athlete as MeetAthlete);
      }
      if (meetAthleteId) {
        return MeetService.getMeetAthlete(meetAthleteId, false);
      }
      return MainService.getAthlete(athleteId);
    }, [athlete, athleteId, meetAthleteId]);

    if (isLoading) {
      return <CenteredCircularProgress />;
    }
    return (
      <AddUpdateMeetAthleteForm
        ref={ref}
        athlete={data as MeetAthlete}
        onUpdateAthlete={onUpdateAthlete}
        weightClass={weightClass}
      />
    );
  }
);

type AddUpdateMeetAthleteFormProps = {
  athlete: MeetAthlete;
  onUpdateAthlete: (ath: Partial<MutateMeetAthlete>) => void;
  weightClass: WeightClass;
};

export const AddUpdateMeetAthleteForm = React.forwardRef(
  (
    { athlete, onUpdateAthlete, weightClass }: AddUpdateMeetAthleteFormProps,
    ref
  ) => {
    const formRef = React.useRef();
    React.useImperativeHandle(ref, () => ({
      next() {
        if (formRef.current) {
          // @ts-ignore
          formRef.current?.dispatchEvent(
            new Event("submit", { cancelable: true, bubbles: true })
          );
        }
      },
      submit() {
        if (formRef.current) {
          // @ts-ignore
          formRef.current?.dispatchEvent(
            new Event("submit", { cancelable: true, bubbles: true })
          );
        }
      },
    }));

    const {
      register,
      handleSubmit,
      formState: { errors },
      setValue,
      reset,
      control,
    } = useForm<MutateMeetAthlete>({
      defaultValues: React.useMemo(() => {
        return {
          bodyWeight: athlete.bodyWeight || athlete.baseBodyWeight,
          startBench: athlete.startBench || athlete.baseStartBench,
          startDeadlift: athlete.startDeadlift || athlete.baseStartDeadlift,
          startSquat: athlete.startSquat || athlete.baseStartSquat,
          pinHeightSquat: athlete.pinHeightSquat || 0,
          pinHeightBench: athlete.pinHeightBench || 0,
        };
      }, [athlete]),
    });

    function onSubmit(data: Partial<MutateMeetAthlete>) {
      onUpdateAthlete({ id: athlete.id, ...data });
    }

    return (
      <Stack
        ref={formRef}
        gap={2}
        maxWidth={200}
        alignSelf="center"
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Typography variant="h6">
          {athlete.firstName} {athlete.lastName}
        </Typography>
        <TextField
          {...register("bodyWeight", {
            required: true,
            max: {
              value: weightClass.maxWeight,
              message: `Please enter a value from 0 to ${weightClass.maxWeight}`,
            },
          })}
          error={!!errors.bodyWeight}
          helperText={errors.bodyWeight?.message}
          label="Body Weight"
          required={true}
          type="number"
          inputProps={{ inputMode: "decimal" }}
        />
        <TextField
          {...register("startSquat", { required: true })}
          error={!!errors.startSquat}
          label="1st Squat"
          required={true}
          type="number"
          fullWidth={true}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
        />
        <TextField
          {...register("startBench", { required: true })}
          error={!!errors.startBench}
          label="1st Bench"
          required={true}
          type="number"
          fullWidth={true}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
        />
        <TextField
          {...register("startDeadlift", { required: true })}
          error={!!errors.startDeadlift}
          label="1st Deadlift"
          required={true}
          type="number"
          fullWidth={true}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
        />
        <TextField
          {...register("pinHeightSquat", { required: false })}
          error={!!errors.pinHeightSquat}
          label="Squat Pin Height`"
          type="number"
          fullWidth={true}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
        />
        <TextField
          {...register("pinHeightBench", { required: false })}
          error={!!errors.pinHeightBench}
          label="Bench Pin Height"
          type="number"
          fullWidth={true}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
        />
      </Stack>
    );
  }
);
