import Service from ".";
import type { UUID } from "../types";
class EntryService extends Service {
  constructor() {
    super({ path: "/php/EntryMain.php" });
  }

  setNextWeight(meetAthleteId: UUID, nextWeight: number): Promise<UUID> {
    return this.post({
      task: "setNextWeight",
      meetAthleteId,
      nextWeight,
    });
  }

  setUseSameWeight(meetAthleteId: UUID): Promise<UUID> {
    return this.post({
      task: "setUseSameWeight",
      meetAthleteId,
    });
  }

  scratchEvent(meetAthleteId: UUID): Promise<UUID> {
    return this.post({
      task: "scratchEvent",
      meetAthleteId,
    });
  }

  scratchMeet(meetAthleteId: UUID): Promise<UUID> {
    return this.post({
      task: "scratchMeet",
      meetAthleteId,
    });
  }

  addResultWeight(
    meetAthleteId: UUID,
    weight: number,
    event: string,
    liftSuccess: string = "N/A"
  ): Promise<UUID> {
    return this.post({
      task: "addResultWeight",
      meetAthleteId,
      weight,
      event,
      liftSuccess,
    });
  }

  deleteResult(resultId: UUID): Promise<UUID> {
    return this.post({
      task: "deleteResult",
      resultId,
    });
  }

  setLiftSuccess(resultId: UUID, liftSuccess: string): Promise<UUID> {
    return this.post({
      task: "setLiftSuccess",
      resultId,
      liftSuccess,
    });
  }

  setSquatPinHeight(meetAthleteId: UUID, pinHeight: number): Promise<UUID> {
    return this.post({
      task: "setSquatPinHeight",
      meetAthleteId,
      pinHeight,
    });
  }

  setBenchPinHeight(meetAthleteId: UUID, pinHeight: number): Promise<UUID> {
    return this.post({
      task: "setBenchPinHeight",
      meetAthleteId,
      pinHeight,
    });
  }
}

export default new EntryService();
