import Home from "@mui/icons-material/Home";
import * as React from "react";

const Links = [
  {
    text: "Home",
    link: "/",
    icon: <Home />,
    children: [],
  },
  // {
  //   text: "MC Console",
  //   link: "/mc",
  //   icon: "",
  //   children: [],
  //   permissions: ["ADMIN"],
  // },
  {
    text: "Admin Console",
    link: "/admin",
    icon: "",
    children: [],
    permissions: ["ADMIN"],
  },
  {
    text: "Create Meet",
    link: "/create-meet",
    icon: "",
    children: [],
    permissions: ["ADMIN", "COACH"],
  },
  {
    text: "Coach's Console",
    link: "/coach/teamManagement",
    icon: "",
    children: [],
    permissions: ["ADMIN", "COACH"],
  },
];

export { Links };
